<div class="content-wrapper">
    <div class="content-body">
        <section id="MarketingTags">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px text-capitalize">PCMT - {{popularCourse?.course.toLowerCase()}}</h2>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add university Marketing Tag
                    </button>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Keywords</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">canonical</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">author</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">robots</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">googlebot</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">yahooSeeker</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">msnbot</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">distribution</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">google</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">document_type</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">country</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">city</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">property_title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">property_url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">property_type</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">property_description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">property_image</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">twitter_card</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">twitter_title</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">twitter_description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">twitter_url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">twitter_image</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">geo_region</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">geo_placename</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">geo_position</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">icbm</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">script</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="popularCoursesMarketingTags && popularCoursesMarketingTags.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let marketingtag of popularCoursesMarketingTags; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1 | json }}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.title}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.description}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.keywords}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.canonical}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.author}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.robots}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.googlebot}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.yahooSeeker}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.msnbot}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.distribution}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.google}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.document_type}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.country}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.city}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.property_title}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.property_url}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.property_type}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.property_description}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.property_image}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.twitter_card}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.twitter_title}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.twitter_description}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.twitter_url}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.twitter_image}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.geo_region}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.geo_placename}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.geo_position}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.icbm}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{marketingtag?.script}}</h6></td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(marketingtag)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Edit" (click)="delete(marketingtag)"></i>
                                                <!--<i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin" *ngIf="marketingtag?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', marketingtag)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Admin" *ngIf="marketingtag?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', marketingtag)"></i>-->
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="28" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="popularCoursesMarketingTags && popularCoursesMarketingTags.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let marketingtag of popularCoursesMarketingTags; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{marketingtag?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="marketingtag?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', marketingtag)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="marketingtag?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', marketingtag)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(marketingtag)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{marketingtag?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-popular-courses-marketing-tag"
            overlayClass="modal-backdrop">
        <app-add-popular-courses-marketing-tag [data]="data" (valueSubmitted)="getData($event)"></app-add-popular-courses-marketing-tag>
    </core-sidebar>
</div>
