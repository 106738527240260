<section id="university-landing">
    <app-content-header [contentHeader]="contentHeader"
                        *ngIf="user && (user['user_type'] === 'SUPER_ADMIN' || user['user_type'] === 'ADMIN')"></app-content-header>
    <ng-template #content let-modal>
        <div class="landing-modal">
            <div class="modal-header">
                <h5 class="font-weight-400">
                    <i class="fab fa-pagelines cursor-pointer mr-2"></i>
                    <span>Update Carousel Image - {{type}}</span>
                </h5>
                <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                   (click)="this.modalService.dismissAll('')"></i>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="imageLandingChangedEvent">
                    <div class="text-center col-md-12">
                        <image-cropper
                                [imageChangedEvent]="imageLandingChangedEvent"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="20 / 10"
                                [resizeToWidth]="1600"
                                format="png, jpeg, jpg"
                                (imageCropped)="imageCropped('SLIDE_LANDING', $event)"
                                (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="loadImageFailed()">
                        </image-cropper>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 1'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.one" class="img-fluid width-85-per" alt="Slide 1"
                                 (click)="image1LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_1')" #image1LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_1')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image1LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 2'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.two" class="img-fluid width-85-per" alt="Slide 2"
                                 (click)="image2LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_2')" #image2LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_2')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image2LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 3'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.three" class="img-fluid width-85-per" alt="Slide 3"
                                 (click)="image3LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_3')" #image3LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_3')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image3LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 4'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.four" class="img-fluid width-85-per" alt="Slide 4"
                                 (click)="image4LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_4')" #image4LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_4')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image4LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 5'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.five" class="img-fluid width-85-per" alt="Slide 5"
                                 (click)="image5LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_5')" #image5LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_5')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image5LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="type === 'Slide 6'">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="text-center mt-2 mb-2">
                            <img [src]="landingSliders.six" class="img-fluid width-85-per" alt="Slide 6"
                                 (click)="image6LandingUpload.click()" *ngIf="!imageLandingChangedEvent"/>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onImageChange($event, 'SLIDER_6')" #image6LandingUpload>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                        <button class="btn btn-primary btn-raised mt-1 mb-1 pull-right" type="button"
                                *ngIf="imageLandingChangedEvent" (click)="saveLandingImage('SLIDER_6')">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised mr-1 mt-1 mb-1 pull-right" type="button"
                                (click)="image6LandingUpload.click()">New Upload
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="content-wrapper">
        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <ngb-carousel [interval]="6000" [pauseOnHover]="false" *ngIf="landingSliders">
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.one" class="img-fluid cursor-pointer" alt="Slide 1"
                                     style="width: 100%; height: 100%" (click)="openLandingModal(content,'Slide 1')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.two" class="img-fluid cursor-pointer" alt="Slide 2"
                                     style="width: 100%; height: 85%" (click)="openLandingModal(content,'Slide 2')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.three" class="img-fluid cursor-pointer" alt="Slide 3"
                                     style="width: 100%; height: 85%" (click)="openLandingModal(content,'Slide 3')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.four" class="img-fluid cursor-pointer" alt="Slide 4"
                                     style="width: 100%; height: 85%" (click)="openLandingModal(content,'Slide 4')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.five" class="img-fluid cursor-pointer" alt="Slide 5"
                                     style="width: 100%; height: 85%" (click)="openLandingModal(content,'Slide 5')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="carousel-img-wrapper">
                                <img [src]="landingSliders.six" class="img-fluid cursor-pointer" alt="Slide 6"
                                     style="width: 100%; height: 85%" (click)="openLandingModal(content,'Slide 6')"/>
                            </div>
                            <div class="carousel-caption"></div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header shadow-bottom-light">
                            <h2 class="card-title">About Us</h2>
                        </div>
                        <div class="card-body pb-1">
                            <div class="row mt-2">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <img [src]="aboutUsImage" width="100%"/>
                                </div>
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                    <p [innerHtml]='aboutUsText'></p>
                                </div>
                            </div>
                            <div class="row mb--15px">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row mt-2 upper-shadow-light">
                                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                            <h4 class="card-title mt-2">Update - About Us</h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <i class="fas fa-code cursor-pointer pull-right mt-2 mb-2"
                                               title="Update about us"
                                               [ngClass]="{'text-primary': aboutUsEdit}"
                                               (click)="editOpen('ABOUT_US')"></i>
                                        </div>
                                    </div>
                                    <div class="row upper-shadow-light" *ngIf="aboutUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="container-fluid">
                                                <form class="form shadow-none" novalidate
                                                      [formGroup]="aboutLandingForm">
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="about-image">About Us - image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="aboutUsImage"
                                                                         (click)="imageAboutUsUpload.click()"
                                                                         *ngIf="!imageAboutUsChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="about-image" type="file"
                                                                           (change)="onImageChange($event, 'ABOUT_US_LANDING_PAGE')"
                                                                           #imageAboutUsUpload>
                                                                    <small *ngIf="!imageAboutUsChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageAboutUsChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageAboutUsChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('ABOUT_US_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageAboutUsChangedEvent"
                                                                            (click)="saveLandingImage('ABOUT_US_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageAboutUsUpload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <label for="write-about">About Us Info</label>
                                                            <textarea class="form-control resize-none" id="write-about"
                                                                      placeholder="Write About University"
                                                                      formControlName="about_us"
                                                                      rows="13"></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2 upper-shadow-light" *ngIf="aboutUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-success color-white pull-right mt-1 mb-1"
                                                    (click)="update('ABOUT_US')">
                                                <i class="fas fa-pen-square mr-1"></i>Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header shadow-bottom-light">
                            <h2 class="card-title">Courses</h2>
                        </div>
                        <div class="card-body">
                            <div class="row kb-search-content-info match-height mt-2">
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course1Data.image" [alt]="course1Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course1Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course1Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course2Data.image" [alt]="course2Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course2Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course2Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course3Data.image" [alt]="course3Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course3Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course3Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course4Data.image" [alt]="course4Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course4Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course4Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course5Data.image" [alt]="course5Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course5Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course5Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="kb-search-content col-sm-6 col-md-4">
                                    <div class="card text-center cursor-pointer">
                                        <img [src]="course6Data.image" [alt]="course6Data.name"/>
                                        <div class="card-body">
                                            <h4 [innerHTML]="course6Data.name"></h4>
                                            <p class="card-text mt-1" [innerHTML]="course6Data.info"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb--15px">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row mt-2 upper-shadow-light">
                                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                            <h4 class="card-title mt-2">Update - Course</h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <i class="fas fa-code cursor-pointer pull-right mt-2 mb-2"
                                               title="Update courses"
                                               [ngClass]="{'text-primary': coursesEdit}"
                                               (click)="editOpen('COURSES')"></i>
                                        </div>
                                    </div>
                                    <div class="row upper-shadow-light" *ngIf="coursesEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="container-fluid">
                                                <form class="form shadow-none" novalidate
                                                      [formGroup]="courseLandingForm">
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-1-image">Course 1 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course1Data.image"
                                                                         (click)="imageCourse1Upload.click()"
                                                                         *ngIf="!imageCourse1ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-1-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_1_LANDING_PAGE')"
                                                                           #imageCourse1Upload>
                                                                    <small *ngIf="!imageCourse1ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse1ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse1ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse1ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_1_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse1Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-1-name">Course 1 Name</label>
                                                            <input type="text" class="form-control" id="course-1-name"
                                                                   placeholder="Course 1 name"
                                                                   formControlName="course_1_name"/>
                                                            <label for="course-1-info">Course 1 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-1-info" placeholder="Course 1 info"
                                                                      formControlName="course_1_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-2-image">Course 2 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course2Data.image"
                                                                         (click)="imageCourse2Upload.click()"
                                                                         *ngIf="!imageCourse2ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-2-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_2_LANDING_PAGE')"
                                                                           #imageCourse2Upload>
                                                                    <small *ngIf="!imageCourse2ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse2ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse2ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse2ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_2_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse2Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-2-name">Course 2 Name</label>
                                                            <input type="text" class="form-control" id="course-2-name"
                                                                   placeholder="Course 2 name"
                                                                   formControlName="course_2_name"/>
                                                            <label for="course-2-info">Course 2 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-2-info" placeholder="Course 2 info"
                                                                      formControlName="course_2_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-3-image">Course 3 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course3Data.image"
                                                                         (click)="imageCourse3Upload.click()"
                                                                         *ngIf="!imageCourse3ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-3-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_3_LANDING_PAGE')"
                                                                           #imageCourse3Upload>
                                                                    <small *ngIf="!imageCourse3ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse3ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse3ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse3ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_3_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse3Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-3-name">Course 3 Name</label>
                                                            <input type="text" class="form-control" id="course-3-name"
                                                                   placeholder="Course 3 name"
                                                                   formControlName="course_3_name"/>
                                                            <label for="course-3-info">Course 3 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-3-info" placeholder="Course 3 name"
                                                                      formControlName="course_3_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-4-image">Course 4 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course4Data.image"
                                                                         (click)="imageCourse4Upload.click()"
                                                                         *ngIf="!imageCourse4ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-4-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_4_LANDING_PAGE')"
                                                                           #imageCourse4Upload>
                                                                    <small *ngIf="!imageCourse4ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse4ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse4ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse4ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_4_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse4Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-4-name">Course 4 Name</label>
                                                            <input type="text" class="form-control" id="course-4-name"
                                                                   placeholder="Course 4 name"
                                                                   formControlName="course_4_name"/>
                                                            <label for="course-4-info">Course 4 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-4-info" placeholder="Course 4 info"
                                                                      formControlName="course_4_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-5-image">Course 5 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course5Data.image"
                                                                         (click)="imageCourse5Upload.click()"
                                                                         *ngIf="!imageCourse5ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-5-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_5_LANDING_PAGE')"
                                                                           #imageCourse5Upload>
                                                                    <small *ngIf="!imageCourse5ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse5ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse5ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse5ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_5_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse5Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-5-name">Course 5 Name</label>
                                                            <input type="text" class="form-control" id="course-5-name"
                                                                   placeholder="Course 5 name"
                                                                   formControlName="course_5_name"/>
                                                            <label for="course-5-info">Course 5 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-5-info" placeholder="Course 5 info"
                                                                      formControlName="course_5_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="course-6-image">Course 6 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="course6Data.image"
                                                                         (click)="imageCourse6Upload.click()"
                                                                         *ngIf="!imageCourse6ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="course-6-image" type="file"
                                                                           (change)="onImageChange($event, 'COURSE_6_LANDING_PAGE')"
                                                                           #imageCourse6Upload>
                                                                    <small *ngIf="!imageCourse6ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageCourse6ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageCourse6ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('COURSE_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageCourse6ChangedEvent"
                                                                            (click)="saveLandingImage('COURSE_6_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageCourse6Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <label for="course-6-name">Course 6 Name</label>
                                                            <input type="text" class="form-control" id="course-6-name"
                                                                   placeholder="Course 6 name"
                                                                   formControlName="course_6_name"/>
                                                            <label for="course-6-info">Course 6 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="course-6-info" placeholder="Course 6 name"
                                                                      formControlName="course_6_info"
                                                                      [rows]="3"></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2 upper-shadow-light" *ngIf="coursesEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-success color-white pull-right mt-1 mb-1"
                                                    (click)="update('COURSE')">
                                                <i class="fas fa-pen-square mr-1"></i>Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header shadow-bottom-light">
                            <h2 class="card-title">Why Us ?</h2>
                        </div>
                        <div class="card-body mt-1">
                            <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
                                <li ngbNavItem>
                                    <a ngbNavLink>{{whyUs1Data?.name}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <img [src]="whyUs1Data.image" width="100%"/>
                                                </div>
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <p [innerHTML]="whyUs1Data.info"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>{{whyUs2Data?.name}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <img [src]="whyUs2Data.image" width="100%"/>
                                                </div>
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <p [innerHTML]="whyUs2Data.info"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>{{whyUs3Data?.name}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <img [src]="whyUs3Data.image" width="100%"/>
                                                </div>
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <p [innerHTML]="whyUs3Data.info"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>{{whyUs4Data?.name}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                    <img [src]="whyUs4Data.image" width="100%"/>
                                                </div>
                                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                    <p [innerHTML]="whyUs4Data.info"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="navFilled" class="mt-2"></div>
                            <div class="row mb--15px">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row mt-2 upper-shadow-light">
                                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                            <h4 class="card-title mt-2">Update - Why Us</h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <i class="fas fa-code cursor-pointer pull-right mt-2 mb-2"
                                               title="Update why us"
                                               [ngClass]="{'text-primary': whyUsEdit}"
                                               (click)="editOpen('WHY_US')"></i>
                                        </div>
                                    </div>
                                    <div class="row upper-shadow-light" *ngIf="whyUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="container-fluid">
                                                <form class="form shadow-none" novalidate
                                                      [formGroup]="whyUsLandingForm">
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label for="why-us-1-tab-name">Tab 1 Name</label>
                                                            <input type="text" class="form-control"
                                                                   id="why-us-1-tab-name"
                                                                   placeholder="Tab 1 Name"
                                                                   formControlName="why_us_1_tab_name"/>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="why-us-1-image">Tab 1 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="whyUs1Data.image"
                                                                         (click)="imageWhyUs1Upload.click()"
                                                                         *ngIf="!imageWhyUs1ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="why-us-1-image" type="file"
                                                                           (change)="onImageChange($event, 'WHY_US_1_LANDING_PAGE')"
                                                                           #imageWhyUs1Upload>
                                                                    <small *ngIf="!imageWhyUs1ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageWhyUs1ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageWhyUs1ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('WHY_US_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageWhyUs1ChangedEvent"
                                                                            (click)="saveLandingImage('WHY_US_1_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageWhyUs1Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <label for="why-us-1-info">Tab 1 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="why-us-1-info"
                                                                      placeholder="Tab 1 Info"
                                                                      formControlName="why_us_1_info"
                                                                      rows="13"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label for="why-us-2-tab-name">Tab 2 Name</label>
                                                            <input type="text" class="form-control"
                                                                   id="why-us-2-tab-name"
                                                                   placeholder="Tab 2 Name"
                                                                   formControlName="why_us_2_tab_name"/>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="why-us-2-image">Tab 2 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="whyUs2Data.image"
                                                                         (click)="imageWhyUs2Upload.click()"
                                                                         *ngIf="!imageWhyUs2ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="why-us-2-image" type="file"
                                                                           (change)="onImageChange($event, 'WHY_US_2_LANDING_PAGE')"
                                                                           #imageWhyUs2Upload>
                                                                    <small *ngIf="!imageWhyUs2ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageWhyUs2ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageWhyUs2ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('WHY_US_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageWhyUs2ChangedEvent"
                                                                            (click)="saveLandingImage('WHY_US_2_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageWhyUs2Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <label for="why-us-2-info">Tab 2 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="why-us-2-info"
                                                                      placeholder="Tab 2 Info"
                                                                      formControlName="why_us_2_info"
                                                                      rows="13"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label for="why-us-3-tab-name">Tab 3 Name</label>
                                                            <input type="text" class="form-control"
                                                                   id="why-us-3-tab-name"
                                                                   placeholder="Tab 3 Name"
                                                                   formControlName="why_us_3_tab_name"/>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="why-us-3-image">Tab 3 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="whyUs3Data.image"
                                                                         (click)="imageWhyUs3Upload.click()"
                                                                         *ngIf="!imageWhyUs3ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="why-us-3-image" type="file"
                                                                           (change)="onImageChange($event, 'WHY_US_3_LANDING_PAGE')"
                                                                           #imageWhyUs3Upload>
                                                                    <small *ngIf="!imageWhyUs3ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageWhyUs3ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageWhyUs3ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('WHY_US_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageWhyUs3ChangedEvent"
                                                                            (click)="saveLandingImage('WHY_US_3_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageWhyUs3Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <label for="why-us-3-info">Tab 3 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="why-us-3-info"
                                                                      placeholder="Tab 3 Info"
                                                                      formControlName="why_us_3_info"
                                                                      rows="13"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2 text-center">
                                                        <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label for="why-us-4-tab-name">Tab 4 Name</label>
                                                            <input type="text" class="form-control"
                                                                   id="why-us-4-tab-name"
                                                                   placeholder="Tab 4 Name"
                                                                   formControlName="why_us_4_tab_name"/>
                                                        </div>
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <label for="why-us-4-image">Tab 4 Image</label>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <img class="cursor-pointer width-100-per"
                                                                         [src]="whyUs4Data.image"
                                                                         (click)="imageWhyUs4Upload.click()"
                                                                         *ngIf="!imageWhyUs4ChangedEvent"/>
                                                                    <input class="form-control-file display-hidden"
                                                                           id="why-us-4-image" type="file"
                                                                           (change)="onImageChange($event, 'WHY_US_4_LANDING_PAGE')"
                                                                           #imageWhyUs4Upload>
                                                                    <small *ngIf="!imageWhyUs4ChangedEvent">Click on
                                                                        above image to choose new image.</small>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="imageWhyUs4ChangedEvent">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 height-300px">
                                                                    <image-cropper
                                                                            [imageChangedEvent]="imageWhyUs4ChangedEvent"
                                                                            [maintainAspectRatio]="true"
                                                                            [aspectRatio]="5 / 3"
                                                                            [resizeToWidth]="500"
                                                                            format="png, jpeg, jpg"
                                                                            (imageCropped)="imageCropped('WHY_US_LANDING', $event)"
                                                                            (imageLoaded)="imageLoaded()"
                                                                            (cropperReady)="cropperReady()"
                                                                            (loadImageFailed)="loadImageFailed()">
                                                                    </image-cropper>
                                                                </div>
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 upper-shadow-light">
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-danger mt-1 mb-1 pull-right"
                                                                            title="Update Image"
                                                                            rippleEffect
                                                                            *ngIf="imageWhyUs4ChangedEvent"
                                                                            (click)="saveLandingImage('WHY_US_4_LANDING')">
                                                                        <i class="fas fa-edit"></i>
                                                                    </button>
                                                                    <button type="button"
                                                                            class="btn btn-icon btn-icon rounded-circle btn-primary mt-1 mb-1 mr-1 pull-left"
                                                                            title="Upload"
                                                                            rippleEffect
                                                                            (click)="imageWhyUs3Upload.click()">
                                                                        <i class="fas fa-upload"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <label for="why-us-4-info">Tab 4 Info</label>
                                                            <textarea class="form-control resize-none"
                                                                      id="why-us-4-info"
                                                                      placeholder="Tab 4 Info"
                                                                      formControlName="why_us_4_info"
                                                                      rows="13"></textarea>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2 upper-shadow-light" *ngIf="whyUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-success color-white pull-right mt-1 mb-1"
                                                    (click)="update('WHY_US')">
                                                <i class="fas fa-pen-square mr-1"></i>Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header shadow-bottom-light">
                            <h2 class="card-title">Join Us</h2>
                        </div>
                        <div class="card-body mt-1">
                            <form class="form" novalidate [formGroup]="addStudentForm">
                                <div class="row">
                                    <div class="col-sm-6 col-12">
                                        <div class="form-group mb-2">
                                            <input type="text" class="form-control" placeholder="Name"
                                                   formControlName="name"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="form-group mb-2">
                                            <input type="email" class="form-control" placeholder="Email"
                                                   formControlName="email"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="form-group mb-2">
                                            <input type="number" class="form-control" placeholder="Contact"
                                                   formControlName="contact"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="course_id">
                                                <option value="">Choose Course</option>
                                                <option [value]="course?.id"
                                                        *ngFor="let course of courses">{{course?.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="btn btn-primary pull-right" rippleEffect
                                                (click)="submit()">Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header shadow-bottom-light">
                            <h2 class="card-title">Contact Us</h2>
                        </div>
                        <div class="card-body mt-2">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <p class="font-weight-bold mb-0">Address -</p>
                                    <p [innerHTML]="contactUsData?.address"></p>
                                    <p class="mt--10px" [innerHTML]="contactUsData?.district"></p>
                                    <p class="mt--10px" [innerHTML]="contactUsData?.state?.name"></p>
                                    <p class="mt--10px" [innerHTML]="contactUsData?.pin_code"></p>
                                    <p class="font-weight-bold mb-0">Contact -</p>
                                    <p [innerHTML]="contactUsData?.contact"></p>
                                </div>
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                    <iframe *ngIf="contactUsData.location" [src]="contactUsData.location | safePipe"
                                            width="100%" height="450"
                                            style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    <iframe *ngIf="!contactUsData.location" [src]="mapUrl | safePipe" width="100%"
                                            height="450"
                                            style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                            <div class="row mb--15px">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row mt-2 upper-shadow-light">
                                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12">
                                            <h4 class="card-title mt-2">Update - Contact Us</h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                            <i class="fas fa-code cursor-pointer pull-right mt-2 mb-2"
                                               title="Update contact us"
                                               [ngClass]="{'text-primary': contactUsEdit}"
                                               (click)="editOpen('CONTACT_US')"></i>
                                        </div>
                                    </div>
                                    <div class="row upper-shadow-light" *ngIf="contactUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="container-fluid">
                                                <form class="form shadow-none" novalidate
                                                      [formGroup]="contactLandingForm">
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                            <div class="row">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="address">Address</label>
                                                                    <textarea class="form-control resize-none"
                                                                              id="address" placeholder="Address"
                                                                              [rows]="5"
                                                                              formControlName="address"></textarea>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="district">District</label>
                                                                    <input type="text" class="form-control"
                                                                           id="district" placeholder="District"
                                                                           formControlName="district"/>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="state">State</label>
                                                                    <select class="form-control" id="state"
                                                                            formControlName="state_id">
                                                                        <option value="">Select State</option>
                                                                        <option [value]="state.id"
                                                                                *ngFor="let state of states">{{state?.name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="pin-code">Pin Code</label>
                                                                    <input type="number" maxlength="6"
                                                                           class="form-control" id="pin-code"
                                                                           placeholder="Pin Code"
                                                                           formControlName="pin_code"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                            <div class="row mt-1">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="contact-us">Contact Us</label>
                                                                    <input type="text" class="form-control"
                                                                           id="contact-us"
                                                                           placeholder="Contact Us"
                                                                           formControlName="contact_us"/>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label for="map-location">University
                                                                        Location</label>
                                                                    <textarea class="form-control resize-none"
                                                                              id="map-location"
                                                                              placeholder="Embed university location from google maps"
                                                                              rows="11"
                                                                              formControlName="location"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2 upper-shadow-light" *ngIf="contactUsEdit">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <button class="btn btn-success color-white pull-right mt-1 mb-1"
                                                    (click)="update('CONTACT_US')">
                                                <i class="fas fa-pen-square mr-1"></i>Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
