<div class="content-wrapper">
    <div class="content-body">
        <section id="counsellors">
            <ng-template #content let-modal>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'UPDATE_PASSWORD'">Update
                            Password</h4>
                        <h4 class="modal-title font-weight-bold text-capitalize"
                            *ngIf="modalType === 'ASSIGN_UNIVERSITY'">{{selectedCounsellor?.counsellor?.name.toLowerCase()}}
                            - Universities</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body" ngbAutoFocus>
                        <div class="row" *ngIf="modalType === 'LEAD_UPDATE_FOLLOW_UP_STATUS'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="leadupdateStatusForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="lead_follow_up_status">Lead Update Follow Up</label>
                                                    <select class="form-control" id="lead_follow_up_status" formControlName="lead_follow_up_status">
                                                        <option value="">Select Lead Follow Up</option>
                                                        <option value="YES">YES</option>
                                                        <option value="NO">NO</option>

                                                    </select>
                                                    <small *ngIf="submitted && updateStatusForm.get('leadfollow_up_status').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                                (click)="leadupdatefollowupStatus()" *ngIf="modalType === 'LEAD_UPDATE_FOLLOW_UP_STATUS'">
                                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div class="row" *ngIf="modalType === 'UPDATE_FOLLOW_UP_STATUS'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updateStatusForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                 <div class="form-group">
                                                        <label for="follow_up_status">Update Follow Up</label>
                                                        <select class="form-control" id="follow_up_status" formControlName="follow_up_status">
                                                            <option value="">Select Follow Up</option>
                                                            <option value="YES">YES</option>
                                                            <option value="NO">NO</option>

                                                        </select>
                                                        <small *ngIf="submitted && updateStatusForm.get('follow_up_status').hasError('required')"
                                                               class="text-danger">
                                                            Field is required.
                                                        </small>
                                                    </div>

                                                </div>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                                (click)="updatefollowupStatus()" *ngIf="modalType === 'UPDATE_FOLLOW_UP_STATUS'">
                                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>



                        <div class="row" *ngIf="modalType === 'UPDATE_PASSWORD'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updatePasswordForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="password" class="d-flex">
                                                        Password
                                                        <i (click)="inputTypePassword = 'text'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                                           class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                        <i (click)="inputTypePassword = 'password'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                                           class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                                    </label>
                                                    <input class="form-control" id="password" [type]="inputTypePassword"
                                                           formControlName="password">
                                                    <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row" *ngIf="modalType === 'ASSIGN_UNIVERSITY'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="px-3 container">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="d-flex">Select University</label>
                                            <ng-select
                                                    class="ng-select-size-md"
                                                    [items]="universities"
                                                    [hideSelected]="true"
                                                    multiple="true"
                                                    bindLabel="name"
                                                    [(ngModel)]="assignedUniversities">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="updatePassword()" *ngIf="modalType === 'UPDATE_PASSWORD'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                    </div>
                </div>
            </ng-template>




            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <h2 class="mt-5px" *ngIf="user['user_type'] === 'ADMIN'">Counsellors</h2>
                    <app-content-header [contentHeader]="contentHeader"
                                        *ngIf="user && (user['user_type'] === 'SUPER_ADMIN' || user['user_type'] === 'ADMIN')"></app-content-header>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <button class="btn btn-primary pull-right color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()" *ngIf="user && ['ADMIN'].indexOf(user['user_type']) !== -1">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Counsellor
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">

                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group" *ngIf="user && (user['user_type'] === 'SUPER_ADMIN' )">
                                    <select class="form-control" id="added_by" formControlName="added_by">
                                        <option value="">Select Admin</option>
                                        <option [value]="admin?.id"
                                                *ngFor="let admin of allAdmins">{{admin.name}}</option>
                                    </select>
                                </fieldset>
                            </div>


                           <!-- <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="username"
                                           placeholder="Username">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="email" placeholder="Email">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="contact"
                                           placeholder="Contact">
                                </fieldset>
                            </div>-->
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">FollowUps Status</h6>
                                            </th>

                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Lead FollowUps Status</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Password</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Address</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">State</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Pin</h6>
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody *ngIf="counsellors && counsellors.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let counsellor of counsellors; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class=" text-center text-info cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="updateStatusModal(content, counsellor, 'UPDATE_FOLLOW_UP_STATUS')">
                                                    {{counsellor?.follow_up_status}}</h6>
                                            </td>
                                            <td class=" text-center text-info cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="leadupdateStatusModal(content, counsellor, 'LEAD_UPDATE_FOLLOW_UP_STATUS')">
                                                    {{counsellor?.lead_follow_up_status}}</h6>
                                            </td>

                                            <td class=" text-center text-info cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="updatePasswordModal(content, counsellor?.counsellor?.id, 'UPDATE_PASSWORD')">
                                                    Update</h6>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(counsellor)"></i>
                                                <!--   <i aria-hidden="true"
                                                      class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                      title="Edit" (click)="leadedit(counsellor)"></i>-->
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin"
                                                   *ngIf="counsellor?.counsellor?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', counsellor?.user)"></i>

                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{counsellor?.user?.name}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{counsellor?.user?.username}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{counsellor?.user?.email}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{counsellor?.user?.contact}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{counsellor?.address}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{counsellor?.state?.name}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{counsellor?.pin}}</h6></td>

                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="12" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="counsellors && counsellors.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let counsellor of counsellors; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{counsellor?.counsellor?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Counsellor" *ngIf="counsellor?.counsellor?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', counsellor?.counsellor)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Counsellor" *ngIf="counsellor?.counsellor?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', counsellor?.counsellor)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(counsellor?.counsellor)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Username - {{counsellor?.counsellor?.username}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Email - {{counsellor?.counsellor?.email}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{counsellor?.counsellor?.contact}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Password - <span class="text-info"
                                                     (click)="updatePasswordModal(content, counsellor?.counsellor?.id, 'UPDATE_PASSWORD')">Update</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-counsellor"
            overlayClass="modal-backdrop">
        <app-add-counsellor [data]="data" (valueSubmitted)="getData($event)"></app-add-counsellor>
    </core-sidebar>
</div>

