import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
// @ts-ignore
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {AdminsModule} from './main/admins/admins.module';
import {SharedModule} from './shared/shared.module';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {UniversitiesModule} from './main/universities/universities.module';
import {ProgramsModule} from './main/programs/programs.module';
import {CoursesModule} from './main/courses/courses.module';
import {BranchesModule} from './main/branches/branches.module';
import {PcbMappingModule} from './main/pcb-mapping/pcb-mapping.module';
import {StagesModule} from './main/stages/stages.module';
import {ReasonsModule} from './main/reasons/reasons.module';
import {SrMappingModule} from './main/sr-mapping/sr-mapping.module';
import {StudentsModule} from './main/students/students.module';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {TextMessagesModule} from './main/text-messages/text-messages.module';
import {WhatsAppsModule} from './main/whats-apps/whats-apps.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {UniversitysettingsModule} from './main/universitysettings/universitysettings.module';
import {CourseCurriculumModule} from './main/universities/course-curriculum/course-curriculum.module';
import {MyProfileModule} from './main/my-profile/my-profile.module';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AdmissionModule} from './main/admission/admission.module';
import {CounsellorsModule} from './main/counsellors/counsellors.module';
import {DataSharing} from './shared/constants/DataSharing';
import {PopularCoursesModule} from './main/popular-courses/popular-courses.module';
import {QuillModule} from 'ngx-quill';
import {InsightsModule} from './main/insights/insights.module';
import {MainBannerSliderModule} from './main/main-banner-slider/main-banner-slider.module';
import {TopStudyLocationsModule} from './main/top-study-locations/top-study-locations.module';
import {OnAirModule} from './main/on-air/on-air.module';
import {ScribbleWallModule} from './main/scribble-wall/scribble-wall.module';
import {NotificationModule} from './main/notification/notification.module';
import {AdmissionNotificationModule} from './main/admission-notification/admission-notification.module';
import {SettingsModule} from './main/settings/settings.module';
import {PersonalizeGuidanceModule} from './main/personalize-guidance/personalize-guidance.module';
import {AskExpertProfessionalModule} from './main/ask-expert-professional/ask-expert-professional.module';
import {ConsultYourGuideModule} from './main/consult-your-guide/consult-your-guide.module';
import {ProgramModule} from './main/program/program.module';
import {NewsModule} from './main/news/news.module';
import {NewsCategoryModule} from './main/news-category/news-category.module';
import {NewsSubCategoryModule} from './main/news-subcategory/news-subcategory.module';
import {InsightsCategoryModule} from './main/insights-category/insights-category.module';
import {InsightsSubCategoryModule} from './main/insights-subcategory/insights-subcategory.module';



const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/auth/miscellaneous/error' // Error 404 - Page not found
    }
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot(),
        // NgBootstrap
        ToastrModule.forRoot(),
        // Core modules
        CoreModule.forRoot(coreConfig),
        QuillModule.forRoot(),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        SharedModule,
        // App modules
        LayoutModule,
        SampleModule,
        SnotifyModule,
        DashboardModule,
        AdminsModule,
        UniversitiesModule,
        UniversitysettingsModule,
        ProgramsModule,
        ProgramModule,
        CoursesModule,
        BranchesModule,
        PcbMappingModule,
        StagesModule,
        ReasonsModule,
        SrMappingModule,
        AdminsModule,
        AdmissionNotificationModule,
        StudentsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        TextMessagesModule,
        WhatsAppsModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        CourseCurriculumModule,
        MyProfileModule,
        AdmissionModule,
        CounsellorsModule,
        PopularCoursesModule,
        InsightsModule,
        MainBannerSliderModule,
        TopStudyLocationsModule,
        OnAirModule,
        ScribbleWallModule,
        NotificationModule,
        SettingsModule,
        PersonalizeGuidanceModule,
        AskExpertProfessionalModule,
        ConsultYourGuideModule,
        NewsModule,
        NewsCategoryModule,
        NewsSubCategoryModule,
        InsightsCategoryModule,
        InsightsSubCategoryModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        AuthGuard,
        HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        MomentService,
        DataSharing,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
})
export class AppModule {
}
