import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PopularCoursesRoutingModule} from './popular-courses-routing.module';
import {PopularCoursesComponent} from './popular-courses.component';
import {AddPopularCoursesComponent} from './add-popular-courses/add-popular-courses.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {PopularCourseInfoComponent} from './popular-course-info/popular-course-info.component';
import {QuillModule} from 'ngx-quill';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SharedModule} from '../../shared/shared.module';
import { PopularCoursesMarketingTagComponent } from './popular-courses-marketing-tag/popular-courses-marketing-tag.component';
import { AddPopularCoursesMarketingTagComponent } from './popular-courses-marketing-tag/add-popular-courses-marketing-tag/add-popular-courses-marketing-tag.component';


@NgModule({
    declarations: [PopularCoursesComponent, AddPopularCoursesComponent, PopularCourseInfoComponent, PopularCoursesMarketingTagComponent, AddPopularCoursesMarketingTagComponent],
    imports: [
        CommonModule,
        PopularCoursesRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        QuillModule,
        ImageCropperModule,
        SharedModule
    ],
    exports: [PopularCoursesComponent, AddPopularCoursesComponent, PopularCourseInfoComponent]
})

export class PopularCoursesModule {
}
