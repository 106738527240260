<div class="content-wrapper">
    <div class="content-body">
        <section id="popular-courses">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'POPULAR_COURSE_ICON'">
                            <i class="fas fa-user-circle cursor-pointer mr-2"></i>
                            <span>Update Icon</span>
                        </h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'UPDATE_URL'">
                            <i class="fas fa-link cursor-pointer mr-1"></i>
                            <span>Update Url - {{urlData?.short_name}}</span>
                        </h4>
                        <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                           (click)="this.modalService.dismissAll('')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="imageChangedIconEvent && modalType === 'POPULAR_COURSE_ICON'">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedIconEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="4 / 4"
                                        [resizeToWidth]="400"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageIconCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                            <div class="text-center display-hidden">
                                <img [src]="croppedIconImage" width="150px" height="150px"/>
                            </div>
                        </div>
                        <div class="row" *ngIf="!imageChangedIconEvent && modalType === 'POPULAR_COURSE_ICON'">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-6">
                                <div class="profile-with-cover text-center">
                                    <div class="card-img-top img-fluid bg-cover height-300 profile-background-image image-upload-area cursor-pointer mt-1 mb-1"
                                         *ngIf="!imageChangedIconEvent" (click)="fileIconUpload.click()"></div>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="fileChangeIconEvent($event)" #fileIconUpload>
                                </div>
                            </div>
                            <div class="col-sm-3" *ngIf="!imageChangedIconEvent"></div>
                        </div>
                        <div class="row" *ngIf="modalType === 'UPDATE_URL'">
                            <form class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form shadow-none"
                                  novalidate [formGroup]="updateUrlForm">
                                <div class="form-group">
                                    <label for="url">Url</label>
                                    <input class="form-control" id="url" formControlName="url"/>
                                    <small *ngIf="submitted && updateUrlForm.get('url').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-raised" type="button"
                                *ngIf="imageChangedIconEvent && modalType === 'POPULAR_COURSE_ICON'"
                                (click)="savePopularCourseIcon()">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-primary btn-raised" type="button"
                                *ngIf="modalType === 'UPDATE_URL'" (click)="updatePopularCourseUrl()">
                            <i class="fa fa-save mr-1"></i> Update
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Popular Courses</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Popular Course
                    </button>
                </div>
            </div>
            <!--<div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb&#45;&#45;8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="program_id"
                                           placeholder="Program">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="course_id"
                                           placeholder="Course">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>-->
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No.</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Program</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Short Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Url</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">About</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Duration</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Rating</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Fee</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Icon</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="popularcourses && popularcourses.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let popularcourse of popularcourses; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1" *ngIf="popularcourse['program'] === 'POST_GRADUATE'">
                                                    Post Graduate</h6>
                                                <h6 class="mt-1" *ngIf="popularcourse['program'] === 'GRADUATE'">
                                                    Graduate</h6>
                                                <h6 class="mt-1" *ngIf="popularcourse['program'] === 'DIPLOMA'">
                                                    Diploma</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.course}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.short_name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <span class="text-white text-copied mr-1 badge badge-primary cursor-pointer"
                                                      *ngIf="popularcourse && popularcourse['url']"
                                                      (click)="updateUrl(content, popularcourse)">
                                                    {{popularcourse['url']}}
                                                </span>
                                                <span class="text-white text-copied mr-1 badge badge-primary cursor-pointer"
                                                      *ngIf="!popularcourse || !popularcourse['url']"
                                                      (click)="updateUrl(content, popularcourse)">
                                                    Update
                                                </span>
                                                <!--<h6 class="mt-1 text-info cursor-pointer" (click)="updateUrl(content, popularcourse)">Update</h6>-->
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.about}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.duration}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.rating}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{popularcourse?.fee | IndianNumber}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-primary" title="Upload Icon"
                                                        (click)="openPopularCourseIconModal(content, popularcourse.id)"
                                                        *ngIf="popularcourse && !popularcourse['popular_course_icon_file_id']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <div class="btn-group" role="group"
                                                     *ngIf="popularcourse && popularcourse['popular_course_icon_file_id']">
                                                    <button type="button" class="btn btn-primary"
                                                            title="Upload Icon"
                                                            (click)="openPopularCourseIconModal(content, popularcourse.id)"
                                                            *ngIf="popularcourse && popularcourse['popular_course_icon_file_id']">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Cover"
                                                            (click)="openPopularCourseIcon(popularcourse)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="text-center width-160px">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(popularcourse)"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation  f_20 ml-5px mt-1 text-primary cursor-pointer font-weight-light pull-right"
                                                   title="View Course Info"
                                                   (click)="popularCourseInfoPage(popularcourse)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Edit" (click)="delete(popularcourse)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-ad f_20 ml-5px text-primary cursor-pointer mt-1"
                                                   title="Popular Course Marketing Tag" (click)="popularCourseMarketingTag(popularcourse)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="28" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="popularcourses && popularcourses.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let popularcourse of popularcourses; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{popularcourse?.name}}</h4>
                                </div>
                                <div class="col-6">
                                      <!--<i aria-hidden="true"
                                       class="fas fa-adn f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(popularcourse)"></i>-->
                                     <!--<i aria-hidden="true"
                                        class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                        title="Active Executive" *ngIf="popularcourse?.status === 'INACTIVE'"
                                        (click)="updateStatus('ACTIVE', popularcourse)"></i>-->
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(popularcourse)"></i>
                                    <i aria-hidden="true"
                                       class="fab fa-artstation f_20 text-primary cursor-pointer font-weight-light pull-right"
                                       title="View Course Info" (click)="popularCourseInfoPage(popularcourse)"></i>

                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Program - {{popularcourse?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Course - {{popularcourse?.name}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Branch -
                                    <div class="btn-group" role="group"
                                         *ngIf="popularcourse && popularcourse['popular_course_icon_file_id']">
                                        <!--<button type="button" class="btn btn-primary" title="Upload Cover"
                                                (click)="coverPhotoUpload.click()">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                        </button>-->
                                        <!--<button type="button" class="btn btn-primary" title="View Cover"
                                                (click)="openCourseCover(popularcourse)">
                                            <i class="fas fa-eye"></i>
                                        </button>-->
                                        <button type="button" class="btn btn-primary" title="Upload Icon"
                                                (click)="openPopularCourseIconModal(content, popularcourse.id)"
                                                *ngIf="popularcourse && !popularcourse['popular_course_icon_file_id']">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                        </button>
                                    </div>
                                    <!--<button class="btn btn-primary color-white" title="Upload Cover"
                                            (click)="coverPhotoUpload.click()"
                                            *ngIf="popularcourse && !popularcourse['popular_course_icon_file_id']">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </button>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="onFileChange($event, 'POPULAR_COURSE_COVER', popularcourse)"
                                           #coverPhotoUpload>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-popular-courses"
            overlayClass="modal-backdrop">
        <app-add-popular-courses [data]="data" (valueSubmitted)="getData($event)"></app-add-popular-courses>
    </core-sidebar>

</div>
