import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OnAirComponent} from './on-air.component';

const routes: Routes = [
    {
        path: 'on-air',
        component: OnAirComponent,
        data: {animation: 'on-air'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class OnAirRoutingModule {
}
