import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnAirRoutingModule} from './on-air-routing.module';
import {AddOnAirComponent} from './add-on-air/add-on-air.component';
import {OnAirComponent} from './on-air.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from 'ngx-image-cropper';


@NgModule({
    declarations: [AddOnAirComponent, OnAirComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        OnAirRoutingModule,
        NgbPaginationModule,
        ImageCropperModule
    ],
    exports: [AddOnAirComponent, OnAirComponent]
})

export class OnAirModule {
}
