import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UniversityInfrastructureService} from '../../../shared/services/university-infrastructure.service';
import {UniversityService} from '../../../shared/services/university.service';

@Component({
    selector: 'app-university-infrastructure',
    templateUrl: './university-infrastructure.component.html',
    styleUrls: ['./university-infrastructure.component.scss']
})
export class UniversityInfrastructureComponent implements OnInit {
    public info: any;
    public facility: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    public universityId: any;
    infrastructures: any = [];
    submitting: any = false;
    submitted: any = false;
    university: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private universityInfrastructureService: UniversityInfrastructureService,
                private universityService: UniversityService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.getUniversityDetail();
    }

    getUniversityDetail() {
        this.universityService.detail(this.universityId).subscribe(data => {
            if (data) {
                this.university = data;
                this.get();
            }
        });
    }

    get() {
        this.universityInfrastructureService.getByAdmin(this.universityId, {'page': -1}).subscribe(data => {
            if (data && data['data']) {
                this.infrastructures = (data && data['data']) ? data['data'] : data['data'];
            } else {
                this.infrastructures = [];
            }
        });
    }

    addDetail() {
        let params = {
            'facility': this.facility ? this.facility : '',
            'info': this.info ? this.info : ''
        };
        this.universityInfrastructureService.createByAdmin(this.universityId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
                this.facility = undefined;
                this.info = undefined;
                this.get();
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

    delete(id) {
        this.universityInfrastructureService.deleteByAdmin(this.universityId, id).subscribe(data => {
            this.get();
        });
    }

    goTo(tab) {
        this.router.navigateByUrl('university/' + this.universityId + '/' + tab);
    }
}
