import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalizeGuidanceRoutingModule} from './personalize-guidance-routing.module';
import {PersonalizeGuidanceComponent} from './personalize-guidance.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';

@NgModule({
    declarations: [PersonalizeGuidanceComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        PersonalizeGuidanceRoutingModule,
        NgbPaginationModule
    ],
    exports: [PersonalizeGuidanceComponent]
})

export class PersonalizeGuidanceModule {
}
