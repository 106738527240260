import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScribbleWallRoutingModule} from './scribble-wall-routing.module';
import {ScribbleWallComponent} from './scribble-wall.component';
import {AddScribbleWallComponent} from './add-scribble-wall/add-scribble-wall.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    declarations: [ScribbleWallComponent, AddScribbleWallComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        ScribbleWallRoutingModule,
        NgbPaginationModule,
        ImageCropperModule
    ],
    exports: [ScribbleWallComponent, AddScribbleWallComponent]
})

export class ScribbleWallModule {
}
