import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {UniversityService} from '../../../shared/services/university.service';
import {StateService} from '../../../shared/services/state.service';
import {PopularCourseService} from '../../../shared/services/popular-course.service';
import {CourseService} from '../../../shared/services/course.service';

@Component({
    selector: 'app-add-popular-courses',
    templateUrl: './add-popular-courses.component.html',
    styleUrls: ['./add-popular-courses.component.scss']
})
export class AddPopularCoursesComponent implements OnChanges, OnInit {
    addPopularCourseForm: FormGroup;
    id: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    states: any = [];
    universities: any = [];
    programs: any = [
        {
            'value': 'POST_GRADUATE',
            'name': 'Post Graduate'
        },
        {
            'value': 'GRADUATE',
            'name': 'Graduate'
        },
        {
            'value': 'DIPLOMA',
            'name': 'Diploma'
        }
    ];
    courses: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private universityService: UniversityService,
                private stateService: StateService,
                private popularCourseService: PopularCourseService) {
    }

    ngOnInit(): void {
        // this.getPrograms();
        this.getStates();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-popular-courses').toggleOpen();
    }

    refresh() {
        this.addPopularCourseForm = this.fb.group({
            'program': ['', (!this.id) ? Validators.required : ''],
            'course': ['', (!this.id) ? Validators.required : ''],
            'short_name': ['', (!this.id) ? Validators.required : ''],
            'about': ['', (!this.id) ? Validators.required : ''],
            'duration': ['', (!this.id) ? Validators.required : ''],
            'rating': ['', (!this.id) ? Validators.required : ''],
            'fee': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addPopularCourseForm.patchValue({
            'program': (this.data && this.data['program']) ? this.data['program'] : '',
            'course': (this.data && this.data['course']) ? this.data['course'] : '',
            'short_name': (this.data && this.data['short_name']) ? this.data['short_name'] : '',
            'about': (this.data && this.data['about']) ? this.data['about'] : '',
            'duration': (this.data && this.data['duration']) ? this.data['duration'] : '',
            'rating': (this.data && this.data['rating']) ? this.data['rating'] : '',
            'fee': (this.data && this.data['fee']) ? this.data['fee'] : '',
        });
    }

    /*getPrograms() {
        this.submitting = true;
        this.programs = [];
        this.programService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
                this.getCourses();
            } else {
                this.programs = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/

    /*getCourses() {
        this.courses = [];
        this.courseService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
            } else {
                this.courses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addPopularCourseForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'university_id': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.university_id) ? this.addPopularCourseForm.value.university_id : '',
                'program': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.program) ? this.addPopularCourseForm.value.program : '',
                'course': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.course) ? this.addPopularCourseForm.value.course : '',
                'short_name': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.short_name) ? this.addPopularCourseForm.value.short_name : '',
                'about': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.about) ? this.addPopularCourseForm.value.about : '',
                'duration': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.duration) ? this.addPopularCourseForm.value.duration : '',
                'rating': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.rating) ? this.addPopularCourseForm.value.rating : '',
                'fee': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.fee) ? this.addPopularCourseForm.value.fee : '',
            };
            this.submitting = true;
            this.popularCourseService.create(this.id, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addPopularCourseForm.reset();
                    this.addPopularCourseForm.patchValue({
                        'university_id': '',
                        'program': '',
                        'course': '',
                        'short_name': '',
                        'about': '',
                        'duration': '',
                        'rating': '',
                        'fee': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university_id && error['error']['errors'].university_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].university_id[0]);
                        return;
                    }
                    if (error['error']['errors'].program && error['error']['errors'].program.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].program[0]);
                        return;
                    }
                    if (error['error']['errors'].course && error['error']['errors'].course.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].course[0]);
                        return;
                    }
                    if (error['error']['errors'].short_name && error['error']['errors'].short_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_name[0]);
                        return;
                    }
                    if (error['error']['errors'].about && error['error']['errors'].about.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].about[0]);
                        return;
                    }
                    if (error['error']['errors'].duration && error['error']['errors'].duration.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].duration[0]);
                        return;
                    }
                    if (error['error']['errors'].rating && error['error']['errors'].rating.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].rating[0]);
                        return;
                    }
                    if (error['error']['errors'].fee && error['error']['errors'].fee.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].fee[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addPopularCourseForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'university_id': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.university_id) ? this.addPopularCourseForm.value.university_id : '',
                'program': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.program) ? this.addPopularCourseForm.value.program : '',
                'course': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.course) ? this.addPopularCourseForm.value.course : '',
                'short_name': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.short_name) ? this.addPopularCourseForm.value.short_name : '',
                'about': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.about) ? this.addPopularCourseForm.value.about : '',
                'duration': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.duration) ? this.addPopularCourseForm.value.duration : '',
                'rating': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.rating) ? this.addPopularCourseForm.value.rating : '',
                'fee': (this.addPopularCourseForm.value && this.addPopularCourseForm.value.fee) ? this.addPopularCourseForm.value.fee : '',
            };
            this.submitting = true;
            this.popularCourseService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addPopularCourseForm.reset();
                this.addPopularCourseForm.patchValue({
                    'university_id': '',
                    'program': '',
                    'course': '',
                    'short_name': '',
                    'about': '',
                    'duration': '',
                    'rating': '',
                    'fee': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].program && error['error']['errors'].program.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].program[0]);
                        return;
                    }
                    if (error['error']['errors'].course && error['error']['errors'].course.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].course[0]);
                        return;
                    }
                    if (error['error']['errors'].short_name && error['error']['errors'].short_name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_name[0]);
                        return;
                    }
                    if (error['error']['errors'].about && error['error']['errors'].about.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].about[0]);
                        return;
                    }
                    if (error['error']['errors'].duration && error['error']['errors'].duration.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].duration[0]);
                        return;
                    }
                    if (error['error']['errors'].rating && error['error']['errors'].rating.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].rating[0]);
                        return;
                    }
                    if (error['error']['errors'].fee && error['error']['errors'].fee.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].fee[0]);
                        return;
                    }
                }
            });
        }
    }
}
