import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../../shared/services/global.service';
import {ProgramService} from '../../../../shared/services/program.service';
import {CourseService} from '../../../../shared/services/course.service';
import {BranchService} from '../../../../shared/services/branch.service';
import {PcbMappingService} from '../../../../shared/services/pcb-mapping.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-add-university-pcb-mapping',
    templateUrl: './add-university-pcb-mapping.component.html',
    styleUrls: ['./add-university-pcb-mapping.component.scss']
})
export class AddUniversityPcbMappingComponent implements OnChanges {
    addpcbMappingForm: FormGroup;
    id: any;
    universityId: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    programs = [];
    courses = [];
    branches = [];

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private pcbMappingService: PcbMappingService,
                private programService: ProgramService,
                private courseService: CourseService,
                private branchService: BranchService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnChanges(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-university-pcb-mapping').toggleOpen();
    }

    refresh() {
        this.addpcbMappingForm = this.fb.group({
            'program_id': ['', Validators.required],
            'course_id': ['', Validators.required],
            'branch_id': [''],
        });
        this.getProgram();
        this.getCourses();
        this.getBranch();
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addpcbMappingForm.patchValue({
            'program_id': (this.data && this.data['program_id']) ? this.data['program_id'] : '',
            'course_id': (this.data && this.data['course_id']) ? this.data['course_id'] : '',
            'branch_id': (this.data && this.data['branch_id']) ? this.data['branch_id'] : '',
        });
    }

    getCourses(params: any = {}) {
        this.courses = [];
        this.courseService.getByAdmin(this.universityId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.courses = data['data'];
            } else {
                this.courses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getProgram(params: any = {}) {
        this.programs = [];
        this.programService.getByAdmin(this.universityId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getBranch(params: any = {}) {
        this.branches = [];
        this.branchService.getByAdmin(this.universityId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.branches = data['data'];
            } else {
                this.branches = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    submit() {
        this.submitted = true;
        // console.log('this.addpcbMappingForm', this.addpcbMappingForm.value);
        if (!this.addpcbMappingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'program_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.program_id) ? this.addpcbMappingForm.value.program_id : '',
                'course_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.course_id) ? this.addpcbMappingForm.value.course_id : '',
                'branch_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.branch_id) ? this.addpcbMappingForm.value.branch_id : '',
            };
            this.submitting = true;
            // console.log('params', params);
            this.pcbMappingService.createByAdmin(this.universityId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addpcbMappingForm.reset();
                    this.addpcbMappingForm.patchValue({
                        'program_id': '',
                        'course_id': '',
                        'branch_id': '',

                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].program && error['error']['errors'].program.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].program[0]);
                        return;
                    }
                    if (error['error']['errors'].course && error['error']['errors'].course.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].course[0]);
                        return;
                    }
                    if (error['error']['errors'].branch && error['error']['errors'].branch.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].branch[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addpcbMappingForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'program_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.program_id) ? this.addpcbMappingForm.value.program_id : '',
                'course_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.course_id) ? this.addpcbMappingForm.value.course_id : '',
                'branch_id': (this.addpcbMappingForm.value && this.addpcbMappingForm.value.branch_id) ? this.addpcbMappingForm.value.branch_id : '',
            };
            this.submitting = true;
            this.pcbMappingService.updateByAdmin(this.universityId, this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].program_id && error['error']['errors'].program_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].program_id[0]);
                        return;
                    }
                    if (error['error']['errors'].course_id && error['error']['errors'].course_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].course_id[0]);
                        return;
                    }
                    if (error['error']['errors'].branch_id && error['error']['errors'].branch_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].branch_id[0]);
                        return;
                    }
                }
            });
        }
    }
}
