import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PopularCoursesComponent} from './popular-courses.component';
import {AddPopularCoursesComponent} from './add-popular-courses/add-popular-courses.component';
import {PopularCourseInfoComponent} from './popular-course-info/popular-course-info.component';
import {PopularCoursesMarketingTagComponent} from './popular-courses-marketing-tag/popular-courses-marketing-tag.component';

const routes: Routes = [
    {
        path: 'popular-courses',
        component: PopularCoursesComponent,
        data: {animation: 'popular-courses'}
    },
    {
        path: 'popular-courses/add',
        component: AddPopularCoursesComponent,
        data: {animation: 'popular-courses/add'}
    },
    {
        path: 'popular-course-info/:id',
        component: PopularCourseInfoComponent,
        data: {animation: 'popular-courses-info'}
    },
    {
        path: 'popular-course-marketing-tag/:id',
        component: PopularCoursesMarketingTagComponent,
        data: {animation: 'popular-courses-marketing-tag'}
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PopularCoursesRoutingModule {
}
