import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {NewsService} from '../../../shared/services/news.service';
import {NewsInfoService} from '../../../shared/services/news-info.service';
import {NewsCategoryService} from '../../../shared/services/news-category.service';
import {NewsSubCategoryService} from '../../../shared/services/news-subcategory.service';


@Component({
    selector: 'app-add-news',
    templateUrl: './add-news.component.html',
    styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnChanges, OnInit {
    addNewsForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    News: any = [];
    allNews: any = [];
    newsCategory = [];
    newsSubCategory = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private newsService: NewsService,
                private newscategoryService: NewsCategoryService,
                private newssubcategoryService: NewsSubCategoryService
    ) {
    }

    ngOnInit(): void {
        this.getNewCategory();
        this.getNewSubCategory();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-news').toggleOpen();

    }

    refresh() {
        this.addNewsForm = this.fb.group({
            'category_id': ['', (!this.id) ? Validators.required : ''],
            'subcategory_id': ['', (!this.id) ? Validators.required : ''],
            'heading': ['', (!this.id) ? Validators.required : ''],
            'url': ['', (!this.id) ? Validators.required : ''],
            'meta_title': ['', (!this.id) ? Validators.required : ''],
            'meta_description': ['', (!this.id) ? Validators.required : ''],
            'canonical_tag': ['', (!this.id) ? Validators.required : '']

        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        if (this.data) {
            // this.getNews(this.data['id']);
        }

        this.addNewsForm.patchValue({
            'category_id': (this.data && this.data['category_id']) ? this.data['category_id'] : '',
            'subcategory_id': (this.data && this.data['subcategory_id']) ? this.data['subcategory_id'] : '',
            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'url': (this.data && this.data['url']) ? this.data['url'] : '',
            'meta_title': (this.data && this.data['meta_title']) ? this.data['meta_title'] : '',
            'meta_description': (this.data && this.data['meta_description']) ? this.data['meta_description'] : '',
            'canonical_tag': (this.data && this.data['canonical_tag']) ? this.data['canonical_tag'] : ''

        });
    }

    getInsight(id) {
        this.News = [];
        this.newsService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allNews = (data && data['data']) ? data['data'] : [];
            } else {
                this.allNews = [];
            }
        });
    }

    getNewCategory(params: any = {}) {
       this.newsCategory = [];
        this.newscategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {

                this.newsCategory = data['data'];
            } else {
                this.newsCategory = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getNewSubCategory(params: any = {}) {
        this.newsSubCategory = [];
        this.newssubcategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                console.log(data['data']);
               this.newsSubCategory = data['data'];
            } else {
                this.newsSubCategory = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addNewsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'category_id': (this.addNewsForm.value && this.addNewsForm.value.category_id) ? this.addNewsForm.value.category_id : '',
                'subcategory_id': (this.addNewsForm.value && this.addNewsForm.value.subcategory_id) ? this.addNewsForm.value.subcategory_id : '',
                'heading': (this.addNewsForm.value && this.addNewsForm.value.heading) ? this.addNewsForm.value.heading : '',
                'url': (this.addNewsForm.value && this.addNewsForm.value.url) ? this.addNewsForm.value.url : '',
                'meta_title': (this.addNewsForm.value && this.addNewsForm.value.meta_title) ? this.addNewsForm.value.meta_title : '',
                'meta_description': (this.addNewsForm.value && this.addNewsForm.value.meta_description) ? this.addNewsForm.value.meta_description : '',
                'canonical_tag': (this.addNewsForm.value && this.addNewsForm.value.canonical_tag) ? this.addNewsForm.value.canonical_tag : ''

            };
            this.submitting = true;
            this.newsService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNewsForm.reset();
                    this.addNewsForm.patchValue({
                        'category_id': '',
                        'subcategory_id': '',
                        'heading': '',
                        'url': '',
                        'meta_title': '',
                        'meta_description': '',
                        'canonical_tag': '',

                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].category_id && error['error']['errors'].category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].subcategory_id && error['error']['errors'].subcategory_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subcategory_id[0]);
                        return;
                    }
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_title && error['error']['errors'].meta_title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].canonical_tag && error['error']['errors'].canonical_tag.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].canonical_tag[0]);
                        return;
                    }

                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNewsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'category_id': (this.addNewsForm.value && this.addNewsForm.value.category_id) ? this.addNewsForm.value.category_id : '',
                'subcategory_id': (this.addNewsForm.value && this.addNewsForm.value.subcategory_id) ? this.addNewsForm.value.subcategory_id : '',
                'heading': (this.addNewsForm.value && this.addNewsForm.value.heading) ? this.addNewsForm.value.heading : '',
                'url': (this.addNewsForm.value && this.addNewsForm.value.url) ? this.addNewsForm.value.url : '',
                'meta_title': (this.addNewsForm.value && this.addNewsForm.value.meta_title) ? this.addNewsForm.value.meta_title : '',
                'meta_description': (this.addNewsForm.value && this.addNewsForm.value.meta_description) ? this.addNewsForm.value.meta_description : '',
                'canonical_tag': (this.addNewsForm.value && this.addNewsForm.value.canonical_tag) ? this.addNewsForm.value.canonical_tag : ''
            };
            this.submitting = true;
            this.newsService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addNewsForm.reset();
                this.addNewsForm.patchValue({
                    'category_id': '',
                    'subcategory_id': '',
                    'heading': '',
                    'url': '',
                    'meta_title': '',
                    'meta_description': '',
                    'canonical_tag': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].category_id && error['error']['errors'].category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].subcategory_id && error['error']['errors'].subcategory_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subcategory_id[0]);
                        return;
                    }
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_title && error['error']['errors'].meta_title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].canonical_tag && error['error']['errors'].canonical_tag.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].canonical_tag[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.newsService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'INSIGHT_COVER') {
                this.fileCourseCover = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
