import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ScribbleWallComponent} from './scribble-wall.component';

const routes: Routes = [
    {
        path: 'scribble-wall',
        component: ScribbleWallComponent,
        data: {animation: 'scribble-wall'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ScribbleWallRoutingModule {
}
