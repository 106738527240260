import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../shared/services/alert.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ScribbleWallService} from '../../shared/services/scribble-wall.service';

@Component({
    selector: 'app-scribble-wall',
    templateUrl: './scribble-wall.component.html',
    styleUrls: ['./scribble-wall.component.scss']
})
export class ScribbleWallComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    scribbleWalls = [];
    modalType: any;
    imageChangedIconEvent: any;
    croppedIconImageEvent: any;
    croppedIconImage: any;
    scribbleWallData: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private scribbleWallService: ScribbleWallService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-scribble-wall').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'name': ['']
        });
        this.get();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.scribbleWalls = [];
        this.scribbleWallService.get(params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.scribbleWalls = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.scribbleWalls = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    delete(id) {
        this.scribbleWallService.delete(id).subscribe(data => {
            this.get();
        });
    }

    openScribbleWallIcon(data) {
        window.open(data['scribble_wall_icon']['url'], '_blank');
    }

    openScribbleWallIconModal(content, id) {
        this.id = id;
        this.modalType = 'SCRIBBLE_WALL_ICON';
        this.modalService.open(content, {size: 'lg'});
    }

    fileChangeIconEvent(event: any): void {
        this.imageChangedIconEvent = event;
    }

    imageIconCropped(event: ImageCroppedEvent) {
        this.croppedIconImageEvent = event;
        this.croppedIconImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    saveScribbleWallIcon() {
        const params = {
            'type': 'SCRIBBLE_WALL_ICON',
            'scribble_wall_id': this.id,
            'fileType': 'BASE64'
        };
        this.scribbleWallService.fileUploadAdmin(params, this.croppedIconImage).subscribe(data => {
            if (data) {
                this.imageChangedIconEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
                this.get();
            }
        });
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.scribbleWallService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    openReviewModal(content, data) {
        this.scribbleWallData = data;
        this.modalType = 'SCRIBBLE_WALL_REVIEW';
        this.modalService.open(content, {size: 'lg'});
    }
}
