import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class UniversityContactService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_CONTACT_INFO(), data);
    }

    /*count(): Observable<any> {
        return this.getRequest(AppUrl.ALL_UNIVERSITY_CONTACT_COUNT());
    }*/

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_CONTACT_INFO(id));
    }

    create(id, data): Observable<any> {
        return this.postRequest(AppUrl.UNIVERSITY_CONTACT_INFO(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_CONTACT_INFO(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_CONTACT_INFO_STATUS(id), data);
    }


    createByAdmin(id, data): Observable<any> {
        return this.postRequest(AppUrl.UNIVERSITY_CONTACT_INFO(id), data);
    }

    detailByAdmin(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.UNIVERSITY_CONTACT_INFO(id));
    }

    updateByAdmin(id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_CONTACT_INFO(id), data);
    }

    getByAdmin(data): Observable<any> {
        return this.getRequest(AppUrl.GET_UNIVERSITY_CONTACT_INFO(), data);
    }
}
