import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class ConsultYourGuideService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.CONSULT_YOUR_GUIDE(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.CONSULT_YOUR_GUIDE(id));
    }

    followUp(id, data): Observable<any> {
        return this.putRequest(AppUrl.FOLLOW_UP_CONSULT_YOUR_GUIDE(id), data);
    }

    assignCounsellor(id, data): Observable<any> {
        return this.putRequest(AppUrl.ASSIGN_COUNSELLOR_CONSULT_YOUR_GUIDE(id), data);
    }
}
