import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../shared/services/admin.service';
import {AlertService} from '../../shared/services/alert.service';
import {CourseService} from '../../shared/services/course.service';
import {StageService} from '../../shared/services/stage.service';
import {ReasonService} from '../../shared/services/reason.service';
import {SrMappingService} from '../../shared/services/sr-mapping.service';

import {CounsellorService} from '../../shared/services/counsellor.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
    showSearch = false;
    admins = [];
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    user: any;
    allStages = [];
    allReasons = [];
    reasonFollowups = [];
    followUps: any = [];
    modalType: any;
    selectedStudent: any;
    counsellors: any = [];
    counsellorsDetails: any = [];
    assignCounsellorForm: FormGroup;
    followUpForm: FormGroup;
    pagination = {
        page: 1,
        total: 0,
        perpage: 15,
        pages: 0
    };


    dataToFilter = [];
    filteredData: any;
    matchedCourse: any | null;
    matchedItem: any;


    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                private stageService: StageService,
                private reasonService: ReasonService,
                private srMappingService: SrMappingService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private counsellorService: CounsellorService,
                private courses: CourseService,
                private adminService: AdminService,
                private alertService: AlertService) {

        this.user = StorageService.getItem('self');
        if (this.user.user_type === 'COUNSELLOR') {
            this.counsellorService.detail(this.user.userId).subscribe(data => {
                if (data) {
                    this.counsellorsDetails = data;


                }
            });


        } else {
            this.counsellorsDetails = '';


        }
    }

    ngOnInit(): void {
        this.refresh();

    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            email: [''],
            contact: [''],
            course: ['']
        });
        this.assignCounsellorForm = this.fb.group({
            'assigned_to': ['', Validators.required]
        });
        /* this.followUpForm = this.fb.group({
             'follow_up': ['', Validators.required],
             'follow_up_remarks': [''],
         });*/
        this.followUpForm = this.fb.group({
            'subject': ['', Validators.required],
            'stage_id': ['', Validators.required],
            'reason_id': ['', Validators.required],
            'type': ['', Validators.required],
            'remark': [''],
        });


        // Assuming your data is fetched from a service

        // this.getCourse();
        // You can subscribe to form value changes and perform the filtering
        this.searchForm.valueChanges.subscribe(() => {
            this.search();
        });

        this.getCourse();
        this.getCounsellors();
        this.getAllStages();
        this.getAllReasons();
    }


    getCourse(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.dataToFilter = [];
        this.courses.getCourse(params).subscribe(res => {
                if (res && res['data'] && res['data'].length > 0) {
                    this.dataToFilter = res.data;
                 //   console.log('this.dataToFilter', this.dataToFilter);
                    this.pagination = {
                        page: res.current_page,
                        total: res.total,
                        perpage: res.per_page,
                        pages: res.last_page,
                    };
                }
            },
            (error) => {
                console.error('Error fetching course:', error);
                // Handle the error appropriately
            }
        );
    }


    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getCourse({page: 1, total: null, perpage: 45, pages: null});
        }
    }

    viewCounsellorsModal(content, data) {
        this.selectedStudent = data;
        this.modalType = 'COUNSELLOR_ASSIGN';
        this.modalService.open(content, {size: 'lg'});
    }
    getAllStages() {
        this.allStages = [];
        this.stageService.get({'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.allStages = data['data'];
            } else {
                this.allStages = [];
            }
        });
    }

    getAllReasons() {
        this.allReasons = [];
        this.reasonService.get({'page': -1}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.allReasons = data['data'];
            } else {
                this.allReasons = [];
            }
        });
    }

    getReasonsFollowups(): void {
        this.followUpForm.patchValue({
            'reason_id': ''
        });
        this.reasonFollowups = [];
        const params = {
            'stages_id': (this.followUpForm && this.followUpForm.value && this.followUpForm.value.stage_id) ? this.followUpForm.value.stage_id : ''
        };

        this.srMappingService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.reasonFollowups = data['data'];

            }
        });
    }


    getStages() {
        this.counsellorService.get({'page': -1, 'user_type': 'COUNSELLOR'}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.counsellors = data['data'];
            } else {
                this.counsellors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }


    getCounsellors() {
        this.counsellorService.get({'page': -1, 'user_type': 'COUNSELLOR'}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.counsellors = data['data'];
            } else {
                this.counsellors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }
    assignCounsellor() {
        this.submitted = true;
        if (!this.assignCounsellorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'assigned_to': (this.assignCounsellorForm.value && this.assignCounsellorForm.value.assigned_to) ? this.assignCounsellorForm.value.assigned_to : ''
            };
            this.submitting = true;
            this.adminService.assignCounsellor(this.selectedStudent.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.assignCounsellorForm.reset();
                this.assignCounsellorForm.patchValue({
                    'assigned_to': ''
                });
                this.modalService.dismissAll('');
                this.getCourse();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].assigned_to.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].assigned_to[0]);
                        return;
                    }
                }
            });
        }
    }

    changeFollowUp(content, data) {
        this.selectedStudent = data;
        this.modalType = 'FOLLOW_UP';
        this.modalService.open(content, {size: 'lg'});
    }

    followUp() {
        this.submitted = true;
        if (!this.followUpForm.valid) {
            return;
        }
        if (this.submitted) {
            const params: {} = {
                stage_id: (this.followUpForm.value && this.followUpForm.value.stage_id) ? this.followUpForm.value.stage_id : '',
                reason_id: (this.followUpForm.value && this.followUpForm.value.reason_id) ? this.followUpForm.value.reason_id : '',
                type: (this.followUpForm.value && this.followUpForm.value.type) ? this.followUpForm.value.type : '',
                subject: (this.followUpForm.value && this.followUpForm.value.subject) ? this.followUpForm.value.subject : '',
                remark: (this.followUpForm.value && this.followUpForm.value.remark) ? this.followUpForm.value.remark : '',
            };

            this.adminService.followUp(this.selectedStudent.id, params).subscribe(data => {

                if (data && data.id) {
                    this.followUps = [data, ...this.followUps];
                    this.followUps.forEach(followUp => {
                        if (followUp && followUp.reason_id) {
                            let selectedReason: any;
                            this.allReasons.forEach(allReason => {
                                if (allReason.id == followUp.reason_id) {
                                    selectedReason = allReason;
                                }
                            });
                            followUp['reason'] = selectedReason;
                        }
                        if (followUp && followUp.stage_id) {
                            let selectedStage: any;
                            this.allStages.forEach(allStage => {
                                if (allStage.id == followUp.stage_id) {
                                    selectedStage = allStage;
                                }
                            });
                            followUp['stage'] = selectedStage;
                        }
                    });
                    this.followUpForm.reset();
                    this.getCourse();
                }
                this.modalService.dismissAll('');

            });
        }


    }

    viewFollowUpRemarks(content, sdata) {
        this.selectedStudent = sdata;

        this.adminService.getfollowUp(this.selectedStudent.id).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                console.log(data['data']);

                this.followUps = data['data'];

                this.modalType = 'VIEW_FOLLOW_UP_REMARKS';
                this.modalService.open(content, {
                    size: 'lg',
                    windowClass: 'custom-modal'  // Custom class for the modal
                });


            } else {
                this.followUps = [];
                this.followUps = data['data'];

                this.modalType = 'VIEW_FOLLOW_UP_REMARKS';
                this.modalService.open(content, {
                    size: 'lg',
                    windowClass: 'custom-modal'  // Custom class for the modal
                });
            }

        });


    }


    /*get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.dataToFilter = [];
        this.courses.get(params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.dataToFilter = data['data'];
                this.pagination = {
                    page: data.data.current_page,
                    total: data.data.total,
                    perpage: data.data.per_page,
                    pages: data.data.last_page,
                };
            } else {
                this.dataToFilter = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/



    onPageChange(page) {
        this.pagination.page = page;
        // this.filteredData = this.dataToFilter;

        const params = {
            'name': this.searchForm.value?.name || '',
            'email': this.searchForm.value?.email || '',
            'contact': this.searchForm.value?.contact || '',
            'course': this.searchForm.value?.course || ''
        };
        this.getCourse(params);

    }


    search() {
        const emailControl = this.searchForm.get('email');
        const contactControl = this.searchForm.get('contact');
        const courseControl = this.searchForm.get('course');

        if (!emailControl || !contactControl || !courseControl) {
            return;
        }

        const email = emailControl.value ? emailControl.value.toLowerCase() : '';
        const contact = contactControl.value ? contactControl.value.toString().toLowerCase() : '';
        const course = courseControl.value ? courseControl.value.toLowerCase() : '';

        // Your filtering logic here
        this.filteredData = this.dataToFilter.filter(item => {
            // Convert data to lowercase for case-insensitive matching
            const itemEmail = (item.email || '').toLowerCase();
            const itemContact = (item.contact || '').toString().toLowerCase();
            const itemCourse = (item.course || '').toLowerCase();

            // Check if each input value is either empty or matches the corresponding data field
            const emailMatch = email === '' || itemEmail.includes(email);
            const contactMatch = contact === '' || itemContact.includes(contact);
            const courseMatch = course === '' || itemCourse.includes(course);

            // Return true if all conditions are met
            return emailMatch && contactMatch && courseMatch;
        });

        // Display the data if there is only one match
        if (this.filteredData.length === 1) {
            this.showMatchedData(this.filteredData[0]);
        }
    }


    showMatchedData(matchedItem) {
        // Logic to display the data for the matched item
        this.matchedItem = matchedItem;
        // You can update your component properties or perform other actions here
    }


}
