import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsRoutingModule} from './news-routing.module';
import {NewsComponent} from './news.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AddNewsComponent} from './add-news/add-news.component';
import {AddNewsInfoComponent} from './add-news-info/add-news-info.component';

@NgModule({
    declarations: [NewsComponent, AddNewsComponent, AddNewsInfoComponent],
    imports: [
        CommonModule,
        NewsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        QuillModule,
        ImageCropperModule
    ],
    exports: []
})
export class NewsModule {
}
