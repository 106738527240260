import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../../shared/services/global.service';
import {ActivatedRoute} from '@angular/router';
import {BranchService} from '../../../../shared/services/branch.service';

@Component({
  selector: 'app-add-university-branch',
  templateUrl: './add-university-branch.component.html',
  styleUrls: ['./add-university-branch.component.scss']
})
export class AddUniversityBranchComponent implements OnChanges {
  addBranchForm: FormGroup;
  id: any;
  universityId: any;
  inputTypePassword: any = 'password';
  submitted: any = false;
  submitting: any = false;
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;

  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              public http: HttpClient,
              public globalService: GlobalService,
              private branchService: BranchService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnChanges(): void {
    this.universityId = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-university-branch').toggleOpen();
  }

  refresh() {
    this.addBranchForm = this.fb.group({
      'name': ['', Validators.required],
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }

  patchForm() {
    this.addBranchForm.patchValue({
      'name': (this.data && this.data['name']) ? this.data['name'] : '',
    });
  }

  submit() {
    this.submitted = true;
    if (!this.addBranchForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addBranchForm.value && this.addBranchForm.value.name) ? this.addBranchForm.value.name : '',
      };
      this.submitting = true;
      this.branchService.createByAdmin(this.universityId, params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addBranchForm.reset();
          this.addBranchForm.patchValue({
            'name': '',
          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
            this.alertService.showErrors(error['error']['errors'].name[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addBranchForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'name': (this.addBranchForm.value && this.addBranchForm.value.name) ? this.addBranchForm.value.name : '',
      };
      this.submitting = true;
      this.branchService.updateByAdmin(this.universityId, this.data['id'], params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.valueSubmitted.emit(true);
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
            this.alertService.showErrors(error['error']['errors'].name[0]);
            return;
          }
        }
      });
    }
  }
}
