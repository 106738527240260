<div class="content-wrapper">
    <div class="content-body">
        <section id="popular-courses">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="mt-5px">Settings</h2>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th>
                                                <h6 class="mt-1 mb-1 font-weight-600">Device</h6>
                                            </th>
                                            <th class="text-right">
                                                <h6 class="mt-1 mb-1 font-weight-600">Latest Version</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <h6 class="mt-1">Android</h6>
                                            </td>
                                            <td class="text-right">
                                                <input class="form-control width-300px pull-right" placeholder="Android"
                                                       [ngModel]="versions.android_version" (change)="updateAndroidVersion($event)"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h6 class="mt-1">IOS</h6>
                                            </td>
                                            <td class="text-right">
                                                <input class="form-control width-300px pull-right" placeholder="IOS"
                                                       [ngModel]="versions.ios_version" (change)="updateIOSVersion($event)"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <!--<div class="card" *ngFor="let admissionNotification of admissionNotifications; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(admissionNotification)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-trash f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                       title="Delete" (click)="delete(admissionNotification.id)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Notification - {{admissionNotification?.notification}}
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </section>
    </div>
</div>
