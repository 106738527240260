import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InsightsRoutingModule} from './insights-routing.module';
import {InsightsComponent} from './insights.component';
import {AddInsightsComponent} from './add-insights/add-insights.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import {AddInsightsInfoComponent} from './add-insights-info/add-insights-info.component';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    declarations: [InsightsComponent, AddInsightsComponent, AddInsightsInfoComponent],
    imports: [
        CommonModule,
        InsightsRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        QuillModule,
        ImageCropperModule
    ],
    exports: [InsightsComponent, AddInsightsComponent, AddInsightsInfoComponent]
})
export class InsightsModule {
}
