import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {ScribbleWallService} from '../../../shared/services/scribble-wall.service';

@Component({
    selector: 'app-add-scribble-wall',
    templateUrl: './add-scribble-wall.component.html',
    styleUrls: ['./add-scribble-wall.component.scss']
})
export class AddScribbleWallComponent implements OnChanges {
    addScribbleWallForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private scribbleWallService: ScribbleWallService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-scribble-wall').toggleOpen();
    }

    refresh() {
        this.addScribbleWallForm = this.fb.group({
            'name': ['', Validators.required],
            'review': ['', Validators.required],
            'rating': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addScribbleWallForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'review': (this.data && this.data['review']) ? this.data['review'] : '',
            'rating': (this.data && this.data['rating']) ? this.data['rating'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addScribbleWallForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.name) ? this.addScribbleWallForm.value.name : '',
                'review': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.review) ? this.addScribbleWallForm.value.review : '',
                'rating': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.rating) ? this.addScribbleWallForm.value.rating : '',
            };
            this.submitting = true;
            this.scribbleWallService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addScribbleWallForm.reset();
                    this.addScribbleWallForm.patchValue({
                        'name': '',
                        'review': '',
                        'rating': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].review && error['error']['errors'].review.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].review[0]);
                        return;
                    }
                    if (error['error']['errors'].rating && error['error']['errors'].rating.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].rating[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addScribbleWallForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.name) ? this.addScribbleWallForm.value.name : '',
                'review': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.review) ? this.addScribbleWallForm.value.review : '',
                'rating': (this.addScribbleWallForm.value && this.addScribbleWallForm.value.rating) ? this.addScribbleWallForm.value.rating : '',
            };
            this.submitting = true;
            this.scribbleWallService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].review && error['error']['errors'].review.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].review[0]);
                        return;
                    }
                    if (error['error']['errors'].rating && error['error']['errors'].rating.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].rating[0]);
                        return;
                    }
                }
            });
        }
    }
}
