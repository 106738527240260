import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UniversitiesComponent} from './universities.component';
import {AddUniversityComponent} from './add-university/add-university.component';
import {UniversityLandingComponent} from './university-landing/university-landing.component';
import {UniversityProgramsComponent} from './university-programs/university-programs.component';
import {UniversityCoursesComponent} from './university-courses/university-courses.component';
import {UniversityBranchesComponent} from './university-branches/university-branches.component';
import {UniversityPcbMappingComponent} from './university-pcb-mapping/university-pcb-mapping.component';
import {UniversityCoursesFeesComponent} from './university-courses-fees/university-courses-fees.component';
import {UniversityInfrastructureComponent} from './university-infrastructure/university-infrastructure.component';
import {UniversityAdmissionComponent} from './university-admission/university-admission.component';
import {UniversityContactComponent} from './university-contact/university-contact.component';
import {UniversityOverviewComponent} from './university-overview/university-overview.component';
import {UniverityMarketingTagComponent} from './univerity-marketing-tag/univerity-marketing-tag.component';

const routes: Routes = [
    {
        path: 'universities',
        component: UniversitiesComponent,
        data: {animation: 'universities'}
    },
    {
        path: 'university/add',
        component: AddUniversityComponent,
        data: {animation: 'university'}
    },
    /*{
        path: 'university/:id/information',
        component: UniversityInformationComponent,
        data: {animation: 'university'}
    },*/
    {
        path: 'university/:id/courses-fees',
        component: UniversityCoursesFeesComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university/:id/infrastructure',
        component: UniversityInfrastructureComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university/:id/admission',
        component: UniversityAdmissionComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university/:id/contact',
        component: UniversityContactComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-landing/:id',
        component: UniversityLandingComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-landing',
        component: UniversityLandingComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-program/:id',
        component: UniversityProgramsComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-course/:id',
        component: UniversityCoursesComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-branch/:id',
        component: UniversityBranchesComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-pcb-mapping/:id',
        component: UniversityPcbMappingComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university/:id/overview',
        component: UniversityOverviewComponent,
        data: {animation: 'university'}
    },
    {
        path: 'university-infrastructure/:id',
        component: UniversityInfrastructureComponent,
        data: {animation: 'university'}
    },
     {
         path: 'university-marketing-tag/:id',
         component: UniverityMarketingTagComponent,
         data: {animation: 'university'}
     }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UniversitiesRoutingModule {
}
