import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NewsCategoryRoutingModule} from './news-category-routing.module';
import {NewsCategoryComponent} from './news-category.component';
import {AddNewsCategoryComponent} from './add-news-category/add-news-category.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';

@NgModule({
    declarations: [NewsCategoryComponent, AddNewsCategoryComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        NewsCategoryRoutingModule,
        NgbPaginationModule,
    ],
    exports: [NewsCategoryComponent, AddNewsCategoryComponent]
})

export class NewsCategoryModule {
}
