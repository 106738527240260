import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyProfileRoutingModule} from './my-profile-routing.module';
import {MyProfileComponent} from './my-profile.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AddMyProfileComponent} from './add-my-profile/add-my-profile.component';

@NgModule({
    declarations: [MyProfileComponent, AddMyProfileComponent],
    imports: [
        CommonModule,
        MyProfileRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        ImageCropperModule
    ],
    exports: [MyProfileComponent, AddMyProfileComponent]
})

export class MyProfileModule {
}
