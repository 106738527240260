<div class="content-wrapper">
    <div class="content-body">
        <div id="user-profile">
            <section id="profile-info">
                <div class="row">
                    <div class="col-lg-9 col-12 order-1 order-lg-2">
                        <div class="form-group mb-2">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Popular Course Information - {{popularCourse?.course}}</h2>
                                </div>
                                <div class="card-body">
                                    <quill-editor class="university-info" [(ngModel)]="data"
                                                  name="quill-content"></quill-editor>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-primary pull-right" rippleEffect (click)="submit()">
                                        <i data-feather="submit" class="d-block d-md-none"></i>
                                        <span class="font-weight-bold d-none d-md-block">Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

