<div class="content-wrapper">
    <div class="content-body">
        <section id="News">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'NEWS_ICON'">News Content - {{viewContentData?.heading}}</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'NEWS_CONTENT'">News Content - {{viewContentData?.heading}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row p-1" *ngIf="modalType === 'NEWS_CONTENT'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" [innerHTML]="viewContentData['content']"></div>
                        </div>
                        <div class="row" *ngIf="imageChangedIconEvent && modalType === 'INSIGHT_ICON'">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedIconEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="3 / 4"
                                        [resizeToWidth]="400"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageIconCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                            <div class="text-center display-hidden">
                                <img [src]="croppedIconImage" width="150px" height="150px"/>
                            </div>
                        </div>
                        <div class="row" *ngIf="!imageChangedIconEvent && modalType === 'INSIGHT_ICON'">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-6">
                                <div class="profile-with-cover text-center">
                                    <div class="card-img-top img-fluid bg-cover height-300 profile-background-image image-upload-area cursor-pointer mt-1 mb-1"
                                         *ngIf="!imageChangedIconEvent" (click)="fileIconUpload.click()"></div>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="fileChangeIconEvent($event)" #fileIconUpload>
                                </div>
                            </div>
                            <div class="col-sm-3" *ngIf="!imageChangedIconEvent"></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-raised" type="button"
                                *ngIf="imageChangedIconEvent && modalType === 'INSIGHT_ICON'"
                                (click)="saveOnInsightIcon()">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger color-white pull-right" (click)="modalService.dismissAll('')">Close</button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">News</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add News
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="category"
                                           placeholder="Category">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="heading"
                                           placeholder="heading">
                                </fieldset>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Category</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Sub Category</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Heading</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>

                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Content</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="News && News.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let newses of News; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{newses['news_category']?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{newses['news_subcategory']?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{newses?.heading}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{newses?.url}}</h6></td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="newses && newses['banner']">
                                                    <button type="button" class="btn btn-primary" title="Upload Banner"
                                                            (click)="bannerUpload.click()">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Banner"
                                                            (click)="openNewsBanner(newses)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button class="btn btn-primary color-white" title="Upload Cover"
                                                        (click)="bannerUpload.click()"
                                                        *ngIf="newses && !newses['banner']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input class="form-control-file display-hidden" type="file"
                                                       (change)="onFileChange($event, 'NEWS_BANNER', newses)"
                                                       #bannerUpload>
                                            </td>
                                            <!--<td class="text-center">
                                              <div class="btn-group" role="group"
                                                    *ngIf="insight && insight['cover']">
                                                   <button type="button" class="btn btn-primary" title="Upload Cover"
                                                           (click)="coverUpload.click()">
                                                       <i class="fas fa-cloud-upload-alt"></i>
                                                   </button>
                                                   <button type="button" class="btn btn-primary" title="View Cover"
                                                           (click)="openInsightCover(insight)">
                                                       <i class="fas fa-eye"></i>
                                                   </button>
                                               </div>
                                               <button class="btn btn-primary color-white" title="Upload Cover"
                                                       (click)="coverUpload.click()"
                                                       *ngIf="insight && !insight['cover']">
                                                   <i class="fas fa-cloud-upload-alt"></i>
                                               </button>
                                               <input class="form-control-file display-hidden" type="file"
                                                      (change)="onFileChange($event, 'INSIGHT_ICON', insight)"
                                                      #coverUpload>

                                               <div class="btn-group" role="group"
                                                    *ngIf="insight">
                                                   <button type="button" class="btn btn-primary"
                                                           title="Upload Icon"
                                                           (click)="openInsightIconModal(content, insight.id)"
                                                           *ngIf="insight && insight['icon']">
                                                       <i class="fas fa-cloud-upload-alt"></i>
                                                   </button>
                                                   <button type="button" class="btn btn-primary" title="View Cover"
                                                           (click)="openInsightIcon(insight)">
                                                       <i class="fas fa-eye"></i>
                                                   </button>
                                               </div>
                                           </td>-->
                                            <td class="text-center">
                                                <h6 class="mt-1 badge badge-pill badge-primary cursor-pointer"
                                                    (click)="viewContent(content, newses)">View
                                                    Content</h6>
                                            </td>
                                            <td class="text-center" style="width: 200px">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(newses)"></i>
                                                <i class="fas fa-check-circle f_20 text-success cursor-pointer font-weight-light mt-1 mr-1 ml-1"
                                                   title="Active"
                                                   (click)="updateStatus('ACTIVE', newses)"
                                                   *ngIf="newses && newses['status'] && newses['status'] === 'IN_ACTIVE'"></i>
                                                <i class="fas fa-times-circle f_20 text-danger cursor-pointer font-weight-light mt-1 mr-1 ml-1"
                                                   title="In Active"
                                                   (click)="updateStatus('IN_ACTIVE', newses)"
                                                   *ngIf="newses && newses['status'] && newses['status'] === 'ACTIVE'"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation f_20 mt-1 text-primary cursor-pointer font-weight-light"
                                                   title="Perform Actions" (click)="NewsInfoPage(newses)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="News && News.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let newses of News; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{newses?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <!-- <i aria-hidden="true"
                                        class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                        title="In Active Executive" *ngIf="insight?.status === 'ACTIVE'"
                                        (click)="updateStatus('INACTIVE', insight)"></i>
                                     <i aria-hidden="true"
                                        class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                        title="Active Executive" *ngIf="insight?.status === 'INACTIVE'"
                                        (click)="updateStatus('ACTIVE', insight)"></i>-->
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(newses)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Category - {{newses?.category}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Heading - {{newses?.heading}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    url - {{newses?.url}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Content - {{newses?.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
  <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-news"
            overlayClass="modal-backdrop">
        <app-add-news [data]="data" (valueSubmitted)="getData($event)"></app-add-news>
    </core-sidebar>

</div>

