import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {MainBannerSliderService} from '../../../shared/services/main-banner-slider.service';

@Component({
    selector: 'app-add-main-banner-slider',
    templateUrl: './add-main-banner-slider.component.html',
    styleUrls: ['./add-main-banner-slider.component.scss']
})
export class AddMainBannerSliderComponent implements OnInit {
    addMainBannerSliderForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    mainBannerSlider: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private mainBannerSliderService: MainBannerSliderService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-main-banner-slider').toggleOpen();
    }

    refresh() {
        this.addMainBannerSliderForm = this.fb.group({
            'heading': ['', (!this.id) ? Validators.required : ''],
            'info': ['', (!this.id) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        if (this.data) {
            this.detailMainbannerSlider(this.data['id']);
        }
        this.addMainBannerSliderForm.patchValue({
            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'info': (this.data && this.data['info']) ? this.data['info'] : '',
        });
    }

    detailMainbannerSlider(id) {
        this.mainBannerSliderService.get(id).subscribe(data => {
            this.mainBannerSlider = data;
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addMainBannerSliderForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'heading': (this.addMainBannerSliderForm.value && this.addMainBannerSliderForm.value.heading) ? this.addMainBannerSliderForm.value.heading : '',
                'info': (this.addMainBannerSliderForm.value && this.addMainBannerSliderForm.value.info) ? this.addMainBannerSliderForm.value.info : '',
            };
            this.submitting = true;
            this.mainBannerSliderService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addMainBannerSliderForm.reset();
                    this.addMainBannerSliderForm.patchValue({
                        'heading': '',
                        'info': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].full_course_name && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].university_name && error['error']['errors'].info.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].info[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addMainBannerSliderForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'heading': (this.addMainBannerSliderForm.value && this.addMainBannerSliderForm.value.heading) ? this.addMainBannerSliderForm.value.heading : '',
                'info': (this.addMainBannerSliderForm.value && this.addMainBannerSliderForm.value.info) ? this.addMainBannerSliderForm.value.info : '',
            };
            this.submitting = true;
            this.mainBannerSliderService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addMainBannerSliderForm.reset();
                this.addMainBannerSliderForm.patchValue({
                    'heading': '',
                    'info': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].full_course_name && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].university_name && error['error']['errors'].info.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].info[0]);
                        return;
                    }
                }
            });
        }
    }
}
