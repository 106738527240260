import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InsightsCategoryComponent} from './insights-category.component';

const routes: Routes = [
    {
        path: 'insights-category',
        component: InsightsCategoryComponent,
        data: {animation: 'insights-category'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class InsightsCategoryRoutingModule {
}
