import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {InsightsService} from '../../../shared/services/insights.service';
import {InsightsInfoService} from '../../../shared/services/insights-info.service';
import {InsightsCategoryService} from '../../../shared/services/insights-category.service';
import {InsightsSubCategoryService} from '../../../shared/services/insights-subcategory.service ';


@Component({
    selector: 'app-add-insights',
    templateUrl: './add-insights.component.html',
    styleUrls: ['./add-insights.component.scss']
})
export class AddInsightsComponent implements OnChanges, OnInit {
    addInsightsForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    insights: any = [];
    allInsight: any = [];
    insightsCategory: any = [];
    insightsSubCategory: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private insightService: InsightsService,
                private insightscategoryService: InsightsCategoryService,
                private insightssubcategoryService: InsightsSubCategoryService

    ) {
    }

    ngOnInit(): void {
        this.getInsightsCategory();
        this.getInsightsSubCategory();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-insight').toggleOpen();
    }

    refresh() {
        this.addInsightsForm = this.fb.group({
            'category_id': ['', (!this.id) ? Validators.required : ''],
            'subcategory_id': ['', (!this.id) ? Validators.required : ''],

            'heading': ['', (!this.id) ? Validators.required : ''],
            'url': ['', (!this.id) ? Validators.required : ''],
            'short_description': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        if (this.data) {
           // this.getInsight(this.data['id']);
        }
        this.addInsightsForm.patchValue({
            'category_id': (this.data && this.data['category_id']) ? this.data['category_id'] : '',
            'subcategory_id': (this.data && this.data['subcategory_id']) ? this.data['subcategory_id'] : '',

            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'url': (this.data && this.data['url']) ? this.data['url'] : '',
            'short_description': (this.data && this.data['short_description']) ? this.data['short_description'] : '',
        });
    }

    getInsight(id) {
        this.insights = [];
        this.insightService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                console.log(data['data']);
                this.allInsight = (data && data['data']) ? data['data'] : [];
            } else {
                this.allInsight = [];
            }
        });
    }

    getInsightsCategory(params: any = {}) {
        this.insightsCategory = [];
        this.insightscategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {

                this.insightsCategory = data['data'];
            } else {
                this.insightsCategory = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getInsightsSubCategory(params: any = {}) {
        this.insightsSubCategory = [];
        this.insightssubcategoryService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                console.log(data['data']);
                this.insightsSubCategory = data['data'];
            } else {
                this.insightsSubCategory = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }




    submit() {
        this.submitted = true;
        if (!this.addInsightsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'category_id': (this.addInsightsForm.value && this.addInsightsForm.value.category_id) ? this.addInsightsForm.value.category_id : '',
                'subcategory_id': (this.addInsightsForm.value && this.addInsightsForm.value.subcategory_id) ? this.addInsightsForm.value.subcategory_id : '',

                'heading': (this.addInsightsForm.value && this.addInsightsForm.value.heading) ? this.addInsightsForm.value.heading : '',
                'url': (this.addInsightsForm.value && this.addInsightsForm.value.url) ? this.addInsightsForm.value.url : '',
                'short_description': (this.addInsightsForm.value && this.addInsightsForm.value.short_description) ? this.addInsightsForm.value.short_description : '',
            };
            this.submitting = true;
            this.insightService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addInsightsForm.reset();
                    this.addInsightsForm.patchValue({
                        'category_id': '',
                        'subcategory_id': '',

                        'heading': '',
                        'url': '',
                        'short_description': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].category_id && error['error']['errors'].category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].subcategory_id && error['error']['errors'].subcategory_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subcategory_id[0]);
                        return;
                    }


                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].short_description && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;

        if (!this.addInsightsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'category_id': (this.addInsightsForm.value && this.addInsightsForm.value.category_id) ? this.addInsightsForm.value.category_id : '',
                'subcategory_id': (this.addInsightsForm.value && this.addInsightsForm.value.subcategory_id) ? this.addInsightsForm.value.subcategory_id : '',

                'heading': (this.addInsightsForm.value && this.addInsightsForm.value.heading) ? this.addInsightsForm.value.heading : '',
                'url': (this.addInsightsForm.value && this.addInsightsForm.value.url) ? this.addInsightsForm.value.url : '',
                'short_description': (this.addInsightsForm.value && this.addInsightsForm.value.short_description) ? this.addInsightsForm.value.short_description : '',
            };
            this.submitting = true;
            this.insightService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addInsightsForm.reset();
                this.addInsightsForm.patchValue({
                    'category_id': '',
                    'subcategory_id': '',

                    'heading': '',
                    'url': '',
                    'short_description': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].category_id && error['error']['errors'].category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].subcategory_id && error['error']['errors'].subcategory_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subcategory_id[0]);
                        return;
                    }

                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].short_description && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.insightService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'INSIGHT_COVER') {
                this.fileCourseCover = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
