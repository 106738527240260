<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Popular Course Marketing Tag</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Popular Course Marketing Tag</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addPopularCourseMarketingTagForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input class="form-control" id="title" type="text" formControlName="title">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <input class="form-control" id="description" type="text" formControlName="description">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="keywords">Keywords</label>
                                        <input class="form-control" id="keywords" type="text" formControlName="keywords">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('keywords').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="canonical">Canonical</label>
                                        <input class="form-control" id="canonical" type="text" formControlName="canonical">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('canonical').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="author">Author</label>
                                        <input class="form-control" id="author" type="text" formControlName="author">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('author').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="robots">Robots</label>
                                        <input class="form-control" id="robots" type="text" formControlName="robots">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('robots').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="googlebot">Googlebot</label>
                                        <input class="form-control" id="googlebot" type="text" formControlName="googlebot">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('googlebot').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="yahooSeeker">Yahoo Seeker</label>
                                        <input class="form-control" id="yahooSeeker" type="text" formControlName="yahooSeeker">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('yahooSeeker').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="msnbot">MSNbot</label>
                                        <input class="form-control" id="msnbot" type="text" formControlName="msnbot">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('msnbot').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="distribution">Distribution</label>
                                        <input class="form-control" id="distribution" type="text" formControlName="distribution">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('distribution').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="google">Google</label>
                                        <input class="form-control" id="google" type="text" formControlName="google">
                                        <small *ngIf="submitted && addPopularCourseMarketingTagForm.get('google').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="document-type">Document-Type</label>
                                        <input class="form-control" id="document-type" type="text" formControlName="document_type">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        <input class="form-control" id="country" type="text" formControlName="country">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="city">City</label>
                                        <input class="form-control" id="city" type="text" formControlName="city">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="property_title">Property-(Title)</label>
                                        <input class="form-control" id="property_title" type="text" formControlName="property_title">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="property_url">Property-(Url)</label>
                                        <input class="form-control" id="property_url" type="text" formControlName="property_url">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="property_type">Property-(Type)</label>
                                        <input class="form-control" id="property_type" type="text" formControlName="property_type">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="property_description">Property-(Description)</label>
                                        <input class="form-control" id="property_description" type="text" formControlName="property_description">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="property_image">Property-(Image)</label>
                                        <input class="form-control" id="property_image" type="text" formControlName="property_image">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="twitter_card">Twitter-(card)</label>
                                        <input class="form-control" id="twitter_card" type="text" formControlName="twitter_card">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="twitter_title">Twitter-(title)</label>
                                        <input class="form-control" id="twitter_title" type="text" formControlName="twitter_title">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="twitter_description">Twitter-(description)</label>
                                        <input class="form-control" id="twitter_description" type="text" formControlName="twitter_description">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="twitter_url">Twitter-(Url)</label>
                                        <input class="form-control" id="twitter_url" type="text" formControlName="twitter_url">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="twitter_image">Twitter-(Image)</label>
                                        <input class="form-control" id="twitter_image" type="text" formControlName="twitter_image">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="geo_region">Geo-(Region)</label>
                                        <input class="form-control" id="geo_region" type="text" formControlName="geo_region">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="geo_placename">Geo-(Placename)</label>
                                        <input class="form-control" id="geo_placename" type="text" formControlName="geo_placename">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="geo_position">Geo-(Position)</label>
                                        <input class="form-control" id="geo_position" type="text" formControlName="geo_position">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="icbm">ICBM</label>
                                        <input class="form-control" id="icbm" type="text" formControlName="icbm">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="script">Script</label>
                                        <textarea class="form-control" id="script" rows="5" cols="5" type="text" formControlName="script"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect class="btn btn-danger" data-dismiss="modal"
                                            aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
