import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityMarketingTagService} from '../../../shared/services/university-marketing-tag.service';
import {UniversityService} from '../../../shared/services/university.service';
import {PopularCourseService} from '../../../shared/services/popular-course.service';
import {AlertService} from '../../../shared/services/alert.service';
import {DataSharing} from '../../../shared/constants/DataSharing';
import {StorageService} from '../../../shared/services/storage.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {PopularCoursesMarketingTagService} from '../../../shared/services/popular-courses-marketing-tag-service';

@Component({
  selector: 'app-popular-courses-marketing-tag',
  templateUrl: './popular-courses-marketing-tag.component.html',
  styleUrls: ['./popular-courses-marketing-tag.component.scss']
})
export class PopularCoursesMarketingTagComponent implements OnInit {

  showSearch = false;
  id: any;
  data: any;
  submitted: any = false;
  submitting: any = false;
  searchForm: FormGroup;
  updateUrlForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  popularCoursesMarketingTags = [];
  onFileChanged: any;
  modalType: any;
  imageChangedIconEvent: any;
  croppedIconImageEvent: any;
  croppedIconImage: any;
  user: any;
  urlData: any;
  popularCourseId: any;
  popularCourse:any;

  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private popularCourseMarketingTagService: PopularCoursesMarketingTagService,
              private popularCourseService: PopularCourseService,
              private alertService: AlertService,
              private dataSharing: DataSharing) {
    this.user = StorageService.getItem('self');
  }

  ngOnInit(): void {
    /*if (this.user && this.user['user_type'] !== 'SUPER_ADMIN') {
      this.router.navigateByUrl('dashboard');
    } else {
      this.refresh();
    }*/
    this.popularCourseId = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-popular-courses-marketing-tag').toggleOpen();
  }

  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'title': [''],
      'description': [''],
      'keywords': [''],
      'canonical': [''],
      'author': [''],
      'robots': [''],
      'googlebot': [''],
      'yahooSeeker': [''],
      'msnbot': [''],
      'distribution': [''],
      'google': [''],
      'document_type': [''],
      'country': [''],
      'city': [''],
      'property_title': [''],
      'property_url': [''],
      'property_type': [''],
      'property_description': [''],
      'property_image': [''],
      'twitter_card': [''],
      'twitter_title': [''],
      'twitter_description': [''],
      'twitter_url': [''],
      'twitter_image': [''],
      'geo_region': [''],
      'geo_placename': [''],
      'geo_position': [''],
      'icbm': [''],
      'script': [''],
    });
    this.get();
    this.getPopularCourseDetail();
  }

  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.popularCoursesMarketingTags = [];
    this.popularCourseMarketingTagService.get(this.popularCourseId  , params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.popularCoursesMarketingTags = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.popularCoursesMarketingTags = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  search() {
    const params = {
      'title': (this.searchForm.value && this.searchForm.value.title) ? this.searchForm.value.title : '',
      'description': (this.searchForm.value && this.searchForm.value.description) ? this.searchForm.value.description : '',
      'keywords': (this.searchForm.value && this.searchForm.value.keywords) ? this.searchForm.value.keywords : '',
      'canonical': (this.searchForm.value && this.searchForm.value.canonical) ? this.searchForm.value.canonical : '',
      'author': (this.searchForm.value && this.searchForm.value.author) ? this.searchForm.value.author : '',
      'robots': (this.searchForm.value && this.searchForm.value.robots) ? this.searchForm.value.robots : '',
      'googlebot': (this.searchForm.value && this.searchForm.value.googlebot) ? this.searchForm.value.googlebot : '',
      'yahooSeeker': (this.searchForm.value && this.searchForm.value.yahooSeeker) ? this.searchForm.value.yahooSeeker : '',
      'msnbot': (this.searchForm.value && this.searchForm.value.msnbot) ? this.searchForm.value.msnbot : '',
      'distribution': (this.searchForm.value && this.searchForm.value.distribution) ? this.searchForm.value.distribution : '',
      'google': (this.searchForm.value && this.searchForm.value.google) ? this.searchForm.value.google : '',
      'document_type': (this.searchForm.value && this.searchForm.value.document_type) ? this.searchForm.value.document_type : '',
      'country': (this.searchForm.value && this.searchForm.value.country) ? this.searchForm.value.country : '',
      'city': (this.searchForm.value && this.searchForm.value.city) ? this.searchForm.value.city : '',
      'property_title': (this.searchForm.value && this.searchForm.value.property_title) ? this.searchForm.value.property_title : '',
      'property_url': (this.searchForm.value && this.searchForm.value.property_url) ? this.searchForm.value.property_url : '',
      'property_type': (this.searchForm.value && this.searchForm.value.property_type) ? this.searchForm.value.property_type : '',
      'property_description': (this.searchForm.value && this.searchForm.value.property_description) ? this.searchForm.value.property_description : '',
      'property_image': (this.searchForm.value && this.searchForm.value.property_image) ? this.searchForm.value.property_image : '',
      'twitter_card': (this.searchForm.value && this.searchForm.value.twitter_card) ? this.searchForm.value.twitter_card : '',
      'twitter_title': (this.searchForm.value && this.searchForm.value.twitter_title) ? this.searchForm.value.twitter_title : '',
      'twitter_description': (this.searchForm.value && this.searchForm.value.twitter_description) ? this.searchForm.value.twitter_description : '',
      'twitter_url': (this.searchForm.value && this.searchForm.value.twitter_url) ? this.searchForm.value.twitter_url : '',
      'twitter_image': (this.searchForm.value && this.searchForm.value.twitter_image) ? this.searchForm.value.twitter_image : '',
      'geo_region': (this.searchForm.value && this.searchForm.value.geo_region) ? this.searchForm.value.geo_region : '',
      'geo_placename': (this.searchForm.value && this.searchForm.value.geo_placename) ? this.searchForm.value.geo_placename : '',
      'geo_position': (this.searchForm.value && this.searchForm.value.geo_position) ? this.searchForm.value.geo_position : '',
      'icbm': (this.searchForm.value && this.searchForm.value.icbm) ? this.searchForm.value.icbm : '',
      'script': (this.searchForm.value && this.searchForm.value.script) ? this.searchForm.value.script : '',
    };
    this.get(params);
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      title: (this.searchForm.value && this.searchForm.value.title) ? this.searchForm.value.title : '',
      description: (this.searchForm.value && this.searchForm.value.description) ? this.searchForm.value.description : '',
      keywords: (this.searchForm.value && this.searchForm.value.keywords) ? this.searchForm.value.keywords : '',
      canonical: (this.searchForm.value && this.searchForm.value.canonical) ? this.searchForm.value.canonical : '',
      author: (this.searchForm.value && this.searchForm.value.author) ? this.searchForm.value.author : '',
      robots: (this.searchForm.value && this.searchForm.value.robots) ? this.searchForm.value.robots : '',
      googlebot: (this.searchForm.value && this.searchForm.value.googlebot) ? this.searchForm.value.googlebot : '',
      yahooSeeker: (this.searchForm.value && this.searchForm.value.yahooSeeker) ? this.searchForm.value.yahooSeeker : '',
      msnbot: (this.searchForm.value && this.searchForm.value.msnbot) ? this.searchForm.value.msnbot : '',
      distribution: (this.searchForm.value && this.searchForm.value.distribution) ? this.searchForm.value.distribution : '',
      google: (this.searchForm.value && this.searchForm.value.google) ? this.searchForm.value.google : '',
      document_type: (this.searchForm.value && this.searchForm.value.document_type) ? this.searchForm.value.document_type : '',
      country: (this.searchForm.value && this.searchForm.value.country) ? this.searchForm.value.country : '',
      city: (this.searchForm.value && this.searchForm.value.city) ? this.searchForm.value.city : '',
      property_title: (this.searchForm.value && this.searchForm.value.property_title) ? this.searchForm.value.property_title : '',
      property_url: (this.searchForm.value && this.searchForm.value.property_url) ? this.searchForm.value.property_url : '',
      property_type: (this.searchForm.value && this.searchForm.value.property_type) ? this.searchForm.value.property_type : '',
      property_description: (this.searchForm.value && this.searchForm.value.property_description) ? this.searchForm.value.property_description : '',
      property_image: (this.searchForm.value && this.searchForm.value.property_image) ? this.searchForm.value.property_image : '',
      twitter_card: (this.searchForm.value && this.searchForm.value.twitter_card) ? this.searchForm.value.twitter_card : '',
      twitter_title: (this.searchForm.value && this.searchForm.value.twitter_title) ? this.searchForm.value.twitter_title : '',
      twitter_description: (this.searchForm.value && this.searchForm.value.twitter_description) ? this.searchForm.value.twitter_description : '',
      twitter_url: (this.searchForm.value && this.searchForm.value.twitter_url) ? this.searchForm.value.twitter_url : '',
      twitter_image: (this.searchForm.value && this.searchForm.value.twitter_image) ? this.searchForm.value.twitter_image : '',
      geo_region: (this.searchForm.value && this.searchForm.value.geo_region) ? this.searchForm.value.geo_region : '',
      geo_placename: (this.searchForm.value && this.searchForm.value.geo_placename) ? this.searchForm.value.geo_placename : '',
      geo_position: (this.searchForm.value && this.searchForm.value.geo_position) ? this.searchForm.value.geo_position : '',
      icbm: (this.searchForm.value && this.searchForm.value.icbm) ? this.searchForm.value.icbm : '',
      script: (this.searchForm.value && this.searchForm.value.script) ? this.searchForm.value.script : '',
    };
    this.get(params);
  }

  edit(data) {
    this.data = data;
    this.toggleSidebar();
  }

  getPopularCourseDetail() {
    this.popularCourseService.detail(this.popularCourseId).subscribe(data => {
      if (data) {
        this.popularCourse = data;
        console.log('this.popularCourse' ,this.popularCourse);
      }
    });
  }

  popularCourseInfoPage(data: any) {
    this.dataSharing.setUniversityId(data.id);
    this.router.navigateByUrl('popular-course-info/' + data.id);
  }

  onFileChange(e, type, data) {
    this.onFileChanged = e;
    this.popularCourseMarketingTagService.fileUploadAdmin({
      'type': type,
      'fileType': 'NORMAL',
      'popular_course_id': data.id
    }, this.onFileChanged).subscribe(data => {
      if (type === 'POPULAR_COURSE_COVER') {
        if (data && data['data']) {
          this.get();
        }
      }
      this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    }, error => {
      this.alertService.showErrors('Something went wrong, please try again');
    });
  }

  openPopularCourseIcon(data) {
    window.open(data['popular_course_file']['url'], '_blank');
  }

  openPopularCourseIconModal(content, id) {
    this.id = id;
    this.modalType = 'POPULAR_COURSE_ICON';
    this.modalService.open(content, {size: 'lg'});
  }

  fileChangeIconEvent(event: any): void {
    this.imageChangedIconEvent = event;
  }

  imageIconCropped(event: ImageCroppedEvent) {
    this.croppedIconImageEvent = event;
    this.croppedIconImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }


  delete(data) {
    this.popularCourseMarketingTagService.delete(data['id']).subscribe(data => {
      this.get();
    });
  }

  updateUrl(content, data) {
    this.urlData = data;
    this.modalType = 'UPDATE_URL';
    this.modalService.open(content, {'size': 'sm'});
  }

}
