import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NewsInfoService} from '../../../shared/services/news-info.service';

@Component({
    selector: 'app-add-news-info',
    templateUrl: './add-news-info.component.html',
    styleUrls: ['./add-news-info.component.scss']
})
export class AddNewsInfoComponent implements OnInit {
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    public insightId: any;
    submitting: any = false;
    submitted: any = false;
    contentData: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private insightInfoService: NewsInfoService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.insightId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.insightInfoService.detailByAdmin(this.insightId).subscribe(data => {
            if (data) {
                this.data = data;
                if (this.data && this.data['content']) {
                    this.contentData = this.data['content'];
                }
            } else {
                this.router.navigateByUrl('News');
            }
        }, error => {
            this.router.navigateByUrl('News');
        });
    }

    submit() {
        let params = {
            'content': this.contentData ? this.contentData : ''
        };
        this.insightInfoService.updateByAdmin(this.insightId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

}
