import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CounsellorService} from '../../shared/services/counsellor.service';
import {AlertService} from '../../shared/services/alert.service';
import {StorageService} from '../../shared/services/storage.service';
import {ConsultYourGuideService} from '../../shared/services/consult-your-guide.service';

@Component({
    selector: 'app-consult-your-guide',
    templateUrl: './consult-your-guide.component.html',
    styleUrls: ['./consult-your-guide.component.scss']
})
export class ConsultYourGuideComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 30,
        pages: null
    };
    consultYourGuides = [];
    modalType: any;
    selectedStudent: any;
    counsellors: any = [];
    assignCounsellorForm: FormGroup;
    followUpForm: FormGroup;
    user: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private consultYourGuideService: ConsultYourGuideService,
                private counsellorService: CounsellorService,
                private alertService: AlertService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'name': ['']
        });
        this.assignCounsellorForm = this.fb.group({
            'assigned_to': ['', Validators.required]
        });
        this.followUpForm = this.fb.group({
            'follow_up': ['', Validators.required],
            'follow_up_remarks': [''],
        });
        this.get();
        this.getCounsellors();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.consultYourGuides = [];
        this.consultYourGuideService.get(params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.consultYourGuides = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.consultYourGuides = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getCounsellors() {
        this.counsellorService.get({'page': -1, 'user_type': 'COUNSELLOR'}).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.counsellors = data['data'];
            } else {
                this.counsellors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    viewCounsellorsModal(content, data) {
        this.selectedStudent = data;
        this.modalType = 'COUNSELLOR_ASSIGN';
        this.modalService.open(content, {size: 'lg'});
    }

    assignCounsellor() {
        this.submitted = true;
        if (!this.assignCounsellorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'assigned_to': (this.assignCounsellorForm.value && this.assignCounsellorForm.value.assigned_to) ? this.assignCounsellorForm.value.assigned_to : ''
            };
            this.submitting = true;
            this.consultYourGuideService.assignCounsellor(this.selectedStudent.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.assignCounsellorForm.reset();
                this.assignCounsellorForm.patchValue({
                    'assigned_to': ''
                });
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].assigned_to.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].assigned_to[0]);
                        return;
                    }
                }
            });
        }
    }

    changeFollowUp(content, data) {
        this.selectedStudent = data;
        this.modalType = 'FOLLOW_UP';
        this.modalService.open(content, {size: 'lg'});
    }

    followUp() {
        this.submitted = true;
        if (!this.followUpForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'follow_up': (this.followUpForm.value && this.followUpForm.value.follow_up) ? this.followUpForm.value.follow_up : '',
                'follow_up_remarks': (this.followUpForm.value && this.followUpForm.value.follow_up_remarks) ? this.followUpForm.value.follow_up_remarks : ''
            };
            this.submitting = true;
            this.consultYourGuideService.followUp(this.selectedStudent.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.followUpForm.reset();
                this.followUpForm.patchValue({
                    'follow_up': '',
                    'follow_up_remarks': '',
                });
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].follow_up.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].follow_up[0]);
                        return;
                    }
                }
            });
        }
    }

    viewFollowUpRemarks(content, data) {
        this.selectedStudent = data;
        this.modalType = 'VIEW_FOLLOW_UP_REMARKS';
        this.modalService.open(content, {size: 'lg'});
    }
}
