import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {PopularCourseInfoService} from '../../../shared/services/popular-course-info.service';

@Component({
    selector: 'app-popular-course-info',
    templateUrl: './popular-course-info.component.html',
    styleUrls: ['./popular-course-info.component.scss']
})
export class PopularCourseInfoComponent implements OnInit {
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    public universityId: any;
    submitting: any = false;
    submitted: any = false;
    popularCourseId: any;
    popularCourse: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private popularCourseInfoService: PopularCourseInfoService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.popularCourseId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.getDetail();
    }

    getDetail() {
        this.popularCourseInfoService.detail(this.popularCourseId).subscribe(data => {
            if (data) {
                this.popularCourse = data;
                this.data = (data && data['info']) ? data['info'] : '';
            }
        });
    }

    submit() {
        let params = {
            'info': this.data ? this.data : ''
        };
        this.popularCourseInfoService.updateByAdmin(this.popularCourseId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
  }

