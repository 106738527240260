import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'fas fa-sign',
        url: 'dashboard',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY']
    },
    {
        id: 'admin',
        title: 'Admins',
        translate: 'MENU.ADMIN',
        type: 'item',
        icon: 'fa-user-shield fas',
        url: 'admins',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'universities',
        title: 'Universities',
        translate: 'MENU.UNIVERSITIES',
        type: 'item',
        icon: 'fas fa-university',
        url: 'universities',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'landing-page',
        title: 'Landing Page',
        translate: 'MENU.LANDING_PAGE',
        type: 'item',
        icon: 'fas fa-seedling',
        url: 'university-landing',
        availableTo: ['UNIVERSITY']
    },
    {
        id: 'counsellors',
        title: 'Counsellors',
        translate: 'MENU.COUNSELLORS',
        type: 'item',
        icon: 'fas fa-user-tie',
        url: 'counsellors',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'students',
        title: 'Students',
        translate: 'MENU.STUDENTS',
        type: 'item',
        icon: 'fas fa-user-graduate',
        url: 'students',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'program',
        title: 'Leads',
        translate: 'MENU.PROGRAM',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'program',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'personalize-guidance',
        title: 'Personalize Guidance',
        translate: 'MENU.PERSONALIZE_GUIDANCE',
        type: 'item',
        icon: 'fas fa-headphones-alt',
        url: 'personalize-guidance',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'ask-expert-professional',
        title: 'Ask Expert Profession',
        translate: 'MENU.ASK_EXPERT_PROFESSIONAL',
        type: 'item',
        icon: 'fas fa-user-tie',
        url: 'ask-expert-professional',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'consult-your-guide',
        title: 'Consult Your Guide',
        translate: 'MENU.CONSULT_YOUR_GUIDE',
        type: 'item',
        icon: 'fas fa-user-check',
        url: 'consult-your-guide',
        availableTo: ['SUPER_ADMIN', 'ADMIN', 'COUNSELLOR']
    },
    {
        id: 'universitysettings',
        title: 'Settings',
        translate: 'MENU.UNIVERSITYSETTINGS',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'universitysettings',
        availableTo: ['UNIVERSITY']
    },

    {
        id: 'popular-courses',
        title: 'Popular Courses',
        translate: 'MENU.POPULAR_COURSES',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'popular-courses',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'insights',
        title: 'Insights',
        translate: 'MENU.INSIGHTS',
        type: 'item',
        icon: 'fas fa-newspaper',
        url: 'insights',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'insights-category',
        title: 'Insights-Category',
        translate: 'MENU.INSIGHTS_CATEGORY',
        type: 'item',
        icon: 'fas fa-bell',
        url: 'insights-category',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'insights-subcategory',
        title: 'Insights-SubCategory',
        translate: 'MENU.INSIGHTS_SUBCATEGORY',
        type: 'item',
        icon: 'fas fa-bell',
        url: 'insights-subcategory',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'news',
        title: 'News',
        translate: 'MENU.NEWS',
        type: 'item',
        icon: 'fas fa-newspaper',
        url: 'news',
        availableTo: ['SUPER_ADMIN']
    },

    {
        id: 'news-category',
        title: 'News-Category',
        translate: 'MENU.NEWS_CATEGORY',
        type: 'item',
        icon: 'fas fa-bell',
        url: 'news-category',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'news-subcategory',
        title: 'News-SubCategory',
        translate: 'MENU.NEWS_CATEGORY',
        type: 'item',
        icon: 'fas fa-bell',
        url: 'news-subcategory',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'main-banner-slider',
        title: 'Main Banner Slider',
        translate: 'MENU.MAIN_BANNER_SLIDER',
        type: 'item',
        icon: 'fas fa-images',
        url: 'main-banner-slider',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'top-study-locations',
        title: 'Top Study Locations',
        translate: 'MENU.TOP_STUDY_LOCATIONS',
        type: 'item',
        icon: 'fas fa-compass',
        url: 'top-study-locations',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'on-air',
        title: 'On AIR',
        translate: 'MENU.ON_AIR',
        type: 'item',
        icon: 'fas fa-video',
        url: 'on-air',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'scribble-wall',
        title: 'Scribble Wall',
        translate: 'MENU.SCRIBBLE_WALL',
        type: 'item',
        icon: 'fas fa-flag-checkered',
        url: 'scribble-wall',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'notifications',
        title: 'Notifications',
        translate: 'MENU.NOTIFICATION',
        type: 'item',
        icon: 'fas fa-bell',
        url: 'notifications',
        availableTo: ['SUPER_ADMIN']
    },

    {
        id: 'admission-notifications',
        title: 'Admission',
        translate: 'MENU.NOTIFICATION',
        type: 'item',
        icon: 'fas fa-comment',
        url: 'admission-notifications',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'settings',
        title: 'Settings',
        translate: 'MENU.SETTINGS',
        type: 'item',
        icon: 'fas fa-cog',
        url: 'settings',
        availableTo: ['SUPER_ADMIN']
    }
];
