import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class NewsService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }


    create(data): Observable<any> {
        return this.postRequest(AppUrl.NEWS(), data);
    }

    detail(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.INSIGHTS(id));
    }

    /*detailBYPCBId(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.UNIVERSITY_COURSE_CURRICULUM_BY_PCB_ID(id));
    }*/

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.NEWS(id), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.NEWS(), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.NEWS_STATUS(id), data);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    fileUploadAdmin(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD_ADMIN(), params, event);
    }
}
