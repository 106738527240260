import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopularCourseService} from '../../shared/services/popular-course.service';
import {DataSharing} from '../../shared/constants/DataSharing';
import {AlertService} from '../../shared/services/alert.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-popular-courses',
    templateUrl: './popular-courses.component.html',
    styleUrls: ['./popular-courses.component.scss']
})
export class PopularCoursesComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    updateUrlForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    popularcourses = [];
    onFileChanged: any;
    modalType: any;
    imageChangedIconEvent: any;
    croppedIconImageEvent: any;
    croppedIconImage: any;
    user: any;
    urlData: any;
    popularCourseId: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private popularCourseService: PopularCourseService,
                private alertService: AlertService,
                private activatedRoute: ActivatedRoute,
                private dataSharing: DataSharing) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        if (this.user && this.user['user_type'] !== 'SUPER_ADMIN') {
            this.router.navigateByUrl('dashboard');
        } else {
            this.refresh();
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-popular-courses').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'program_id': [''],
            'course_id': [''],
            'branch_id': [''],
        });
        this.updateUrlForm = this.fb.group({
            'url': ['', Validators.required]
        });
        this.get();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.popularcourses = [];
        this.popularCourseService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.popularcourses = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.popularcourses = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'program_id': (this.searchForm.value && this.searchForm.value.program_id) ? this.searchForm.value.program_id : '',
            'course_id': (this.searchForm.value && this.searchForm.value.course_id) ? this.searchForm.value.course_id : '',
            'branch_id': (this.searchForm.value && this.searchForm.value.branch_id) ? this.searchForm.value.branch_id : '',
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            program_id: (this.searchForm && this.searchForm.value && this.searchForm.value.program_id) ? this.searchForm.value.program_id : '',
            course_id: (this.searchForm && this.searchForm.value && this.searchForm.value.course_id) ? this.searchForm.value.course_id : '',
            branch_id: (this.searchForm && this.searchForm.value && this.searchForm.value.branch_id) ? this.searchForm.value.branch_id : '',
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }


    popularCourseInfoPage(data: any) {
        this.dataSharing.setUniversityId(data.id);
        this.router.navigateByUrl('popular-course-info/' + data.id);
    }
    popularCourseMarketingTag(data: any){
        this.dataSharing.setUniversityId(data.id);
        this.router.navigateByUrl('popular-course-marketing-tag/' + data.id);
    }

    onFileChange(e, type, data) {
        this.onFileChanged = e;
        this.popularCourseService.fileUploadAdmin({
            'type': type,
            'fileType': 'NORMAL',
            'popular_course_id': data.id
        }, this.onFileChanged).subscribe(data => {
            if (type === 'POPULAR_COURSE_COVER') {
                if (data && data['data']) {
                    this.get();
                }
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openPopularCourseIcon(data) {
        window.open(data['popular_course_file']['url'], '_blank');
    }

    openPopularCourseIconModal(content, id) {
        this.id = id;
        this.modalType = 'POPULAR_COURSE_ICON';
        this.modalService.open(content, {size: 'lg'});
    }

    fileChangeIconEvent(event: any): void {
        this.imageChangedIconEvent = event;
    }

    imageIconCropped(event: ImageCroppedEvent) {
        this.croppedIconImageEvent = event;
        this.croppedIconImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    savePopularCourseIcon() {
        const params = {
            'type': 'POPULAR_COURSE_ICON',
            'popular_course_id': this.id,
            'fileType': 'BASE64'
        };
        this.popularCourseService.fileUploadAdmin(params, this.croppedIconImage).subscribe(data => {
            if (data) {
                this.imageChangedIconEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
                this.get();
            }
        });
    }

    delete(data) {
        this.popularCourseService.delete(data['id']).subscribe(data => {
            this.get();
        });
    }

    updateUrl(content, data) {
        this.urlData = data;
        this.modalType = 'UPDATE_URL';
        this.modalService.open(content, {'size': 'sm'});
    }

    updatePopularCourseUrl() {
        this.submitted = true;
        if (!this.updateUrlForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'url': (this.updateUrlForm.value && this.updateUrlForm.value.url) ? this.updateUrlForm.value.url : ''
            };
            this.submitting = true;
            this.popularCourseService.updateUrl(this.urlData.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.updateUrlForm.reset();
                this.updateUrlForm.patchValue({
                    'url': ''
                });
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                }
            });
        }
    }
}
