<div class="content-wrapper">
    <div class="content-body">
        <div id="user-profile">
            <div class="row">
                <div class="col-12">
                    <div class="card profile-header mb-2">
                        <img class="card-img-top" src="{{university?.university_cover_file?.url}}"
                             alt="{{university?.name}}"/>
                        <div class="position-relative">
                            <div class="profile-img-container d-flex align-items-center">
                                <div class="profile-img">
                                    <img src="{{university?.university_icon_file?.url}}"
                                         class="rounded img-fluid"
                                         alt="Card image"/>
                                </div>
                                <div class="profile-title ml-3">
                                    <h2 class="text-white">{{university?.name}}</h2>
                                    <p class="text-white">{{university?.city?.name}}, {{university?.state?.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="profile-header-nav">
                            <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                                <button
                                        class="btn btn-icon navbar-toggler"
                                        type="button"
                                        data-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        (click)="toggleMenu = !toggleMenu"
                                        rippleEffect
                                >
                                    <i data-feather="align-justify" class="font-medium-5"></i>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent"
                                     [ngbCollapse]="toggleMenu">
                                    <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                                        <ul class="nav nav-pills mb-0">
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('overview')">
                                                    <span class="d-none d-md-block">Information</span>
                                                    <i data-feather="rss" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold active"
                                                   (click)="goTo('courses-fees')">
                                                    <span class="d-none d-md-block">Courses & Fees</span>
                                                    <i data-feather="info" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('infrastructure')">
                                                    <span class="d-none d-md-block">Infrastructure</span>
                                                    <i data-feather="image" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('admission')">
                                                    <span class="d-none d-md-block">Admission</span>
                                                    <i data-feather="users" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('contact')">
                                                    <span class="d-none d-md-block">Contact</span>
                                                    <i data-feather="users" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <!--<button class="btn btn-primary" rippleEffect>
                                            <i data-feather="edit" class="d-block d-md-none"></i>
                                            <span class="font-weight-bold d-none d-md-block">Edit</span>
                                        </button>-->
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section id="profile-info">
                <div class="row">
                    <div class="col-lg-12 col-12 order-1 order-lg-2">
                        <div class="form-group mb-2">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Courses & Fees</h2>
                                </div>
                                <div class="card-body">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Program</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Branch</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="pcbMappings && pcbMappings.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let pcbMapping of pcbMappings; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                {{pcbMapping?.program?.name}}
                                            </td>
                                            <td class="text-center">
                                                {{pcbMapping?.course?.name}}
                                            </td>
                                            <td class="text-center">
                                                {{pcbMapping?.branch?.name}}
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation f_20 text-primary ml-5px cursor-pointer font-weight-light mt-1"
                                                   title="Perform Actions" (click)="courseCurriculum(pcbMapping)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="5" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
