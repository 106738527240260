import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConsultYourGuideComponent} from './consult-your-guide.component';

const routes: Routes = [
    {
        path: 'consult-your-guide',
        component: ConsultYourGuideComponent,
        data: {animation: 'consult-your-guide'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ConsultYourGuideRoutingModule {
}
