import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InsightsSubCategoryComponent} from './insights-subcategory.component';

const routes: Routes = [
    {
        path: 'insights-subcategory',
        component: InsightsSubCategoryComponent,
        data: {animation: 'insights-subcategory'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class InsightsSubCategoryRoutingModule {
}
