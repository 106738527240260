import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NotificationRoutingModule} from './notification-routing.module';
import {NotificationComponent} from './notification.component';
import {AddNotificationComponent} from './add-notification/add-notification.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';

@NgModule({
    declarations: [NotificationComponent, AddNotificationComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        NotificationRoutingModule,
        NgbPaginationModule,
    ],
    exports: [NotificationComponent, AddNotificationComponent]
})

export class NotificationModule {
}
