import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class NewsInfoService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.NEWS, data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.NEWS(id));
    }

    create(id, data): Observable<any> {
        return this.postRequest(AppUrl.NEWS_CONTENT(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.NEWS_CONTENT(id), data);
    }

    /*  updateStatus(id, data): Observable<any> {
          return this.putRequest(AppUrl.INSIGHTS_CONTENT_STATUS(id), data);
      }*/


    createByAdmin(id, data): Observable<any> {
        return this.postRequest(AppUrl.NEWS_CONTENT(id), data);
    }

    detailByAdmin(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.NEWS(id));
    }

    updateByAdmin(id, data): Observable<any> {
        return this.putRequest(AppUrl.NEWS_CONTENT(id), data);
    }

    getByAdmin(id,data): Observable<any> {
        return this.getRequest(AppUrl.GET_NEWS_CONTENT(id), data);
    }
}
