import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCurriculumRoutingModule } from './course-curriculum-routing.module';
import {ContentHeaderModule} from '../../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../../@core/common.module';
import {CardSnippetModule} from '../../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CourseCurriculumComponent} from './course-curriculum.component';

@NgModule({
  declarations: [CourseCurriculumComponent],
  imports: [
    CommonModule,
    CourseCurriculumRoutingModule,
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CardSnippetModule,
    NgxDatatableModule,
    CoreSidebarModule,
    NgbPaginationModule
  ],
  exports: [CourseCurriculumComponent],
})
export class CourseCurriculumModule { }
