import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PcbMappingComponent} from './pcb-mapping.component';
import {AddPcbMappingComponent} from './add-pcb-mapping/add-pcb-mapping.component';
import {CourseCurriculumComponent} from '../universities/course-curriculum/course-curriculum.component';

const routes: Routes = [
    {
        path: 'pcb-mapping',
        component: PcbMappingComponent,
        data: {animation: 'pcb-mapping'}
    },
    {
        path: 'pcb-mapping/add',
        component: AddPcbMappingComponent,
        data: {animation: 'pcb-mapping/add'}
    },
    {
        path: 'course-curriculum/:id',
        component: CourseCurriculumComponent,
        data: {animation: 'course-curriculum'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PcbMappingRoutingModule {
}
