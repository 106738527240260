import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PersonalizeGuidanceComponent} from './personalize-guidance.component';

const routes: Routes = [
    {
        path: 'personalize-guidance',
        component: PersonalizeGuidanceComponent,
        data: {animation: 'personalize-guidance'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PersonalizeGuidanceRoutingModule {
}
