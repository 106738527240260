<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Popular Courses</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Popular Courses</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addPopularCourseForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="program">Program</label>
                                        <select class="form-control" id="program" formControlName="program">
                                            <option value="">Select Program</option>
                                            <option [value]="program?.value"
                                                    *ngFor="let program of programs">{{program?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addPopularCourseForm.get('program').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="course">Course</label>
                                        <input class="form-control" id="course" formControlName="course"/>
                                        <small *ngIf="submitted && addPopularCourseForm.get('course').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="short_name">Short Name</label>
                                        <input type="text" class="form-control" id="short_name"
                                               formControlName="short_name"/>
                                        <small *ngIf="submitted && addPopularCourseForm.get('short_name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="about">About</label>
                                        <textarea type="text" class="form-control resize-none" id="about"
                                                  formControlName="about"></textarea>
                                        <small *ngIf="submitted && addPopularCourseForm.get('about').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="duration">Duration (in years)</label>
                                        <input type="number" class="form-control" id="duration"
                                               formControlName="duration"/>
                                        <small *ngIf="submitted && addPopularCourseForm.get('duration').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="rating">Rating</label>
                                        <input type="text" class="form-control" id="rating" formControlName="rating">
                                        <small *ngIf="submitted && addPopularCourseForm.get('rating').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="fee">Fee</label>
                                        <input type="number" class="form-control" id="fee" formControlName="fee"/>
                                        <small *ngIf="submitted && addPopularCourseForm.get('fee').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="row">
                                <div class="col-12">
                                    <div class="form-group p-0">
                                        <label class="font-weight p-0">Cover Photo
                                        </label>
                                        <br>
                                        <button class="btn btn-primary color-white mt-12px mb-2"
                                                (click)="coverPhotoUpload.click()">
                                            <i class="fas fa-cloud-upload-alt mr-5px ml&#45;&#45;5px"></i>Upload
                                        </button>
                                        <input class="form-control-file display-hidden" type="file"
                                               (change)="onFileChange($event, 'COURSE_COVER')" #coverPhotoUpload>
                                        <span class="text-info cursor-pointer ml-1" (click)="openCourseCover()" *ngIf="courseCoverPhoto">View</span>
                                    </div>
                                </div>
                            </div>-->
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect class="btn btn-danger" data-dismiss="modal"
                                            aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>

