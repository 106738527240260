import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StorageService} from '../../shared/services/storage.service';
import {CoreConfigService} from '../../../@core/services/config.service';
import {StateService} from '../../shared/services/state.service';
import {repeaterAnimation} from 'app/main/animations/form-repeater.animation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [repeaterAnimation]
})
export class DashboardComponent implements OnInit, AfterViewInit {
    user: any;
    states: any = [];
    data: any;
    id: any;
    public coreConfig: any;
    programs = [];
    courses = [];
    branches = [];
    universities: any = [];
    admins: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    counsellors: any = [];

    constructor(public _coreConfigService: CoreConfigService,
                public stateService: StateService,
                public modalService: NgbModal) {
        this.user = StorageService.getItem('self');
    }


    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.refresh();
    }

    refresh() {
        this.getStates();
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }
}
