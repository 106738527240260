<div class="content-wrapper">
    <div class="content-body">
        <section id="course-curriculum">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h2 class="mt-5px">Course Curriculum - {{university?.university?.name}}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card mt-3">
                                <form class="form mt-2 shadow-none" novalidate [formGroup]="addCourseCurriculumForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="font-weight mt-1">Curriculum Type
                                                            </label>
                                                            <select class="form-control" formControlName="type">
                                                                <option value="">Select Curriculum Type</option>
                                                                <option value="SEMESTER">Semester</option>
                                                                <option value="YEAR">Year</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="font-weight mt-1">Duration (In years)
                                                            </label>
                                                            <input class="form-control" type="number"
                                                                   formControlName="duration"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="font-weight mt-1">Registration Fee
                                                            </label>
                                                            <input class="form-control" type="number"
                                                                   formControlName="registration_amount"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label class="font-weight mt-1">Course Fee
                                                            </label>
                                                            <input class="form-control" type="number"
                                                                   formControlName="course_fee"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Re registration Fee
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="re_registration_amount"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Prospectus fee
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="prospectus_fee"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Service Charge
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="service_charge"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Examination Fee
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="examination_fee"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Lateral Entery Fee
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="lateral_entry_fee"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                <div class="form-group">
                                                                    <label class="font-weight mt-1">Credit Transfer Fee
                                                                    </label>
                                                                    <input class="form-control" type="number"
                                                                           formControlName="credit_transfer_fee"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="form-group p-0">
                                                            <label class="font-weight p-0">Upload Fee Structure</label>
                                                            <br>
                                                            <button class="btn btn-primary color-white mt-12px mb-1"
                                                                    (click)="fileFeeSturctureUpload.click()">
                                                                <i class="fas fa-cloud-upload-alt mr-5px ml--5px"></i>Upload
                                                            </button>
                                                            <input class="form-control-file display-hidden" type="file"
                                                                   (change)="onFileChange($event, 'UNIVERSITY_FEE_STRUCTURE')"
                                                                   #fileFeeSturctureUpload>
                                                            <span class="text-info cursor-pointer ml-1"
                                                                  (click)="openFeeStrcuture()"
                                                                  *ngIf="fileFeeStructure">View</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row upper-shadow-light">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <button class="btn btn-danger btn-raised mt-2" type="button"
                                                        (click)="back()">Cancel
                                                </button>
                                                <button class="btn btn-primary btn-raised ml-5 mt-2 pull-right"
                                                        type="button"
                                                        (click)="submit()" *ngIf="!universityCourseCurriculumData">
                                                    Submit
                                                </button>
                                                <button class="btn btn-primary btn-raised  mt-2 pull-right"
                                                        type="button"
                                                        (click)="update()" *ngIf="universityCourseCurriculumData">Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
