import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsultYourGuideRoutingModule} from './consult-your-guide-routing.module';
import {ConsultYourGuideComponent} from './consult-your-guide.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [ConsultYourGuideComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        ConsultYourGuideRoutingModule,
        NgbPaginationModule
    ]
})

export class ConsultYourGuideModule {
}
