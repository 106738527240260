<div class="content-wrapper">
    <div class="content-body">
        <section id="popular-courses">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">News Category</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Insights Category
                    </button>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No.</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Category</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Url</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Meta Title</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Meta Description</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Canonical Tag</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="insightsCategory && insightsCategory.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let insightscategorys of insightsCategory; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{insightscategorys?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{insightscategorys?.url}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{insightscategorys?.meta_title}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{insightscategorys?.meta_description}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{insightscategorys?.canonical_tag}}</h6>
                                            </td>
                                            <td class="text-center width-160px">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Edit" (click)="edit(insightscategorys)"></i>
                                                <i class="fas fa-check-circle f_20 text-success cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Active"
                                                   (click)="updateStatus('ACTIVE', insightscategorys)"
                                                   *ngIf="insightscategorys && insightscategorys['status'] && insightscategorys['status'] === 'IN_ACTIVE'"></i>
                                                <i class="fas fa-times-circle f_20 text-danger cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="In Active"
                                                   (click)="updateStatus('IN_ACTIVE', insightscategorys)"
                                                   *ngIf="insightscategorys && insightscategorys['status'] && insightscategorys['status'] === 'ACTIVE'"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete" (click)="delete(insightscategorys.id)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="3" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="insightsCategory && insightsCategory.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let insights_cat of insightsCategory; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(insights_cat)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-trash f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                       title="Delete" (click)="delete(insights_cat.id)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Category - {{insights_cat?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
   <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-insights-category"
            overlayClass="modal-backdrop">
        <app-add-insights-category [data]="data" (valueSubmitted)="getData($event)"></app-add-insights-category>
    </core-sidebar>
</div>
