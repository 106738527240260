import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmissionNotificationRoutingModule } from './admission-notification-routing.module';
import { AddAdmissionNotificationComponent } from './add-admission-notification/add-admission-notification.component';
import {AdmissionNotificationComponent} from './admission-notification.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [AdmissionNotificationComponent,AddAdmissionNotificationComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    CardSnippetModule,
    CoreSidebarModule,
    AdmissionNotificationRoutingModule,
    NgbPaginationModule,
  ],
  exports: [AdmissionNotificationComponent,AddAdmissionNotificationComponent]
})
export class AdmissionNotificationModule { }
