import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {AdmissionNotificationService} from '../../../shared/services/admission-notification.service';
import {UniversityService} from '../../../shared/services/university.service';

@Component({
    selector: 'app-add-admission-notification',
    templateUrl: './add-admission-notification.component.html',
    styleUrls: ['./add-admission-notification.component.scss']
})
export class AddAdmissionNotificationComponent implements OnChanges {
    showSearch = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    addAdmissionNotificationForm: FormGroup;
    admissionNotifications = [];
    universities = [] ;
    id: any;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private admissionNotificationService: AdmissionNotificationService,
                private universityService: UniversityService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-admission-notification').toggleOpen();
    }

    refresh() {
        this.addAdmissionNotificationForm = this.fb.group({
            'notification': ['', Validators.required],
            'university_id': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
        this.getUniversity();
    }

    patchForm() {
        this.addAdmissionNotificationForm.patchValue({
            'notification': (this.data && this.data['notification']) ? this.data['notification'] : '',
            'university_id': (this.data && this.data['university_id']) ? this.data['university_id'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addAdmissionNotificationForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'notification': (this.addAdmissionNotificationForm.value && this.addAdmissionNotificationForm.value.notification) ? this.addAdmissionNotificationForm.value.notification : '',
                'university_id': (this.addAdmissionNotificationForm.value && this.addAdmissionNotificationForm.value.university_id) ? this.addAdmissionNotificationForm.value.university_id : ''
            };
            this.submitting = true;
            this.admissionNotificationService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addAdmissionNotificationForm.reset();
                    this.addAdmissionNotificationForm.patchValue({
                        'notification': '',
                        'university_id': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].notification && error['error']['errors'].notification.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].notification[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addAdmissionNotificationForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'notification': (this.addAdmissionNotificationForm.value && this.addAdmissionNotificationForm.value.notification) ? this.addAdmissionNotificationForm.value.notification : ''
            };
            this.submitting = true;
            this.admissionNotificationService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].notification && error['error']['errors'].notification.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].notification[0]);
                        return;
                    }
                }
            });
        }
    }

    getUniversity(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.universities = [];
        this.universityService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.universities = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.universities = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

}
