import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CourseService} from '../../../shared/services/course.service';
import {UniversityService} from '../../../shared/services/university.service';
import {BranchService} from '../../../shared/services/branch.service';

@Component({
  selector: 'app-university-branches',
  templateUrl: './university-branches.component.html',
  styleUrls: ['./university-branches.component.scss']
})
export class UniversityBranchesComponent implements OnInit {
  showSearch = false;
  id: any;
  universityId: any;
  data: any;
  updatePasswordForm: FormGroup;
  inputTypePassword = 'password';
  submitted: any = false;
  submitting: any = false;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  branches = [];
  university: any;

  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private branchService: BranchService,
              private activatedRoute: ActivatedRoute,
              private universityService: UniversityService) {
  }

  ngOnInit(): void {
    this.universityId = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-university-branch').toggleOpen();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'name': [''],
    });
    this.get();
    this.getUniversityDetail();
  }

  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  getUniversityDetail() {
    this.universityService.detail(this.universityId).subscribe(data => {
      if (data) {
        this.university = data;
      }
    });
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.branches = [];
    this.branchService.getByAdmin(this.universityId, params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.branches = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.branches = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  search() {
    const params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
    };
    this.get(params);
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
    };
    this.get(params);
  }

  edit(data) {
    this.data = data;
    this.toggleSidebar();
  }

  updateStatus(status, data) {
    this.submitted = true;
    if (this.submitted) {
      const params = {
        'status': status
      };
      this.submitting = true;
      this.branchService.updateStatus(data.id, params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
        this.modalService.dismissAll('');
        this.get();
      }, error => {
        this.submitting = false;
        this.submitted = false;
        this.id = undefined;
      });
    }
  }
}
