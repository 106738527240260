import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class UniversityInfrastructureService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE, data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.ALL_UNIVERSITY_INFRASTRUCTURE_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE(id));
    }

    create(id, data): Observable<any> {
        return this.postRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE(id), data);
    }

    /*updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.BRANCHES_PASSWORD(id), data);
    }*/

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE_STATUS(id), data);
    }

    createByAdmin(id, data): Observable<any> {
        return this.postRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE(id), data);
    }

    getByAdmin(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.GET_UNIVERSITY_INFRASTRUCTURE(id), data);
    }

    updateByAdmin(universityId, id, data): Observable<any> {
        return this.putRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE_UPDATE_BY_ADMIN(universityId, id), data);
    }

    deleteByAdmin(universityId, id): Observable<any> {
        return this.deleteRequest(AppUrl.UNIVERSITY_INFRASTRUCTURE_DELETE_BY_ADMIN(universityId, id));
    }
}
