<div class="content-wrapper">
    <div class="content-body">
        <section id="main-banner-slider">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Main Banner Slider</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Slider
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="heading"
                                           placeholder="Heading">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Heading</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Info</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="mainBannerSliders && mainBannerSliders.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let mainBannerSlider of mainBannerSliders; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{mainBannerSlider?.heading}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{mainBannerSlider?.info}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="mainBannerSlider && mainBannerSlider['main_banner_slider_file_id']">
                                                    <button type="button" class="btn btn-primary" title="Upload Banner"
                                                            (click)="bannerUpload.click()">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Banner"
                                                            (click)="openMainBannerSlider(mainBannerSlider)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button class="btn btn-primary color-white" title="Upload Cover"
                                                        (click)="bannerUpload.click()"
                                                        *ngIf="mainBannerSlider && !mainBannerSlider['main_banner_slider_file_id']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input class="form-control-file display-hidden" type="file"
                                                       (change)="onFileChange($event, 'MAIN_BANNER_SLIDER', mainBannerSlider)"
                                                       #bannerUpload>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Edit" (click)="edit(mainBannerSlider)"></i>
                                                <i class="fas fa-check-circle f_20 text-success cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Active"
                                                   (click)="updateStatus('ACTIVE', mainBannerSlider)"
                                                   *ngIf="mainBannerSlider && mainBannerSlider['status'] && mainBannerSlider['status'] === 'IN_ACTIVE'"></i>
                                                <i class="fas fa-times-circle f_20 text-danger cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="In Active"
                                                   (click)="updateStatus('IN_ACTIVE', mainBannerSlider)"
                                                   *ngIf="mainBannerSlider && mainBannerSlider['status'] && mainBannerSlider['status'] === 'ACTIVE'"></i>
                                                <i class="fas fa-trash f_20 text-danger cursor-pointer mt-1"
                                                   title="Delete" (click)="delete(mainBannerSlider.id)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="5" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="mainBannerSliders && mainBannerSliders.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let mainBannerSlider of mainBannerSliders; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{mainBannerSlider?.heading}}</h4>
                                </div>
                                <div class="col-6">
                                    <!-- <i aria-hidden="true"
                                        class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                        title="In Active Executive" *ngIf="insight?.status === 'ACTIVE'"
                                        (click)="updateStatus('INACTIVE', insight)"></i>
                                     <i aria-hidden="true"
                                        class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                        title="Active Executive" *ngIf="insight?.status === 'INACTIVE'"
                                        (click)="updateStatus('ACTIVE', insight)"></i>-->
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(mainBannerSlider)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Info - {{mainBannerSlider?.info}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-main-banner-slider"
            overlayClass="modal-backdrop">
        <app-add-main-banner-slider [data]="data" (valueSubmitted)="getData($event)"></app-add-main-banner-slider>
    </core-sidebar>
</div>
