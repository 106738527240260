import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {ProgramService} from '../../../shared/services/program.service';
import {CourseService} from '../../../shared/services/course.service';
import {BranchService} from '../../../shared/services/branch.service';
import {PcbMappingService} from '../../../shared/services/pcb-mapping.service';
import {DataSharing} from '../../../shared/constants/DataSharing';
import {UniversityService} from '../../../shared/services/university.service';

@Component({
    selector: 'app-university-courses-fees',
    templateUrl: './university-courses-fees.component.html',
    styleUrls: ['./university-courses-fees.component.scss']
})
export class UniversityCoursesFeesComponent implements OnInit {
    public id: any;
    public pcbMappings: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    public universityId: any;
    programs = [];
    courses = [];
    branches = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    submitted: any = false;
    submitting: any = false;
    dataExists: any = false;
    university: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private _coreSidebarService: CoreSidebarService,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private programService: ProgramService,
                private courseService: CourseService,
                private branchService: BranchService,
                private pcbMappingService: PcbMappingService,
                private dataSharing: DataSharing,
                private universityService: UniversityService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.getUniversityDetail();
        this.getProgram();
        this.getPCBMapping();
    }

    getPCBMapping() {
        this.pcbMappingService.getByAdmin(this.universityId, {'page': -1}).subscribe(data => {
            if (data) {
                this.pcbMappings = data;
                this.dataExists = true;
            } else {
                this.dataExists = false;
            }
        });
    }

    getUniversityDetail() {
        this.universityService.detail(this.universityId).subscribe(data => {
            if (data) {
                this.university = data;
            }
        });
    }

    getProgram(params: any = {}) {
        this.programs = [];
        this.programService.getByAdmin(this.universityId, params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.programs = data['data'];
            } else {
                this.programs = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getCourses(program_id) {
        if (program_id) {
            this.pcbMappingService.getCourseByLanding(program_id, {'page': -1}).subscribe(data => {
                if (data && data['data'].length > 0) {
                    this.courses = data['data'];
                } else {
                    this.courses = [];
                }
                this.submitting = false;
            }, error => {
                this.submitting = false;
            });
        }
    }

    getBranches(course_id) {
        this.branches = [];
        this.pcbMappingService.getBranchByLanding(course_id, {'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.branches = data['data'];
            } else {
                this.branches = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    goTo(tab) {
        this.router.navigateByUrl('university/' + this.universityId + '/' + tab);
    }

    courseCurriculum(data: any) {
        this.router.navigateByUrl('course-curriculum/' + data.id);
    }
}
