import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class PopularCourseInfoService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.POPULAR_COURSE, data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.POPULAR_COURSE(id));
    }

    create(id, data): Observable<any> {
        return this.postRequest(AppUrl.POPULAR_COURSE(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.POPULAR_COURSE(id), data);
    }

    createByAdmin(id, data): Observable<any> {
        return this.postRequest(AppUrl.POPULAR_COURSE(id), data);
    }

    detailByAdmin(id): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.POPULAR_COURSE(id));
    }

    updateByAdmin(id, data): Observable<any> {
        return this.putRequest(AppUrl.POPULAR_COURSE_INFO(id), data);
    }

    getByAdmin(data): Observable<any> {
        return this.getRequest(AppUrl.GET_POPULAR_COURSE_INFO(), data);
    }
}
