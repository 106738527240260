import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NewsComponent} from './news.component';
import {AddNewsComponent} from './add-news/add-news.component';
import {AddNewsInfoComponent} from './add-news-info/add-news-info.component';

const routes: Routes = [
  {
    path: 'news',
    component: NewsComponent,
    data: {animation: 'news'}
  },
  {
     path: 'news/add',
     component: AddNewsComponent,
     data: {animation: 'news/add'}
   },
   {
     path: 'news-info/:id',
     component: AddNewsInfoComponent,
     data: {animation: 'news/info'}
   }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
