import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NewsSubCategoryRoutingModule} from './news-subcategory-routing.module';
import {NewsSubCategoryComponent} from './news-subcategory.component';
import {AddNewsSubCategoryComponent} from './add-news-subcategory/add-news-subcategory.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';

@NgModule({
    declarations: [NewsSubCategoryComponent, AddNewsSubCategoryComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        NewsSubCategoryRoutingModule,
        NgbPaginationModule,
    ],
    exports: [NewsSubCategoryComponent, AddNewsSubCategoryComponent]
})

export class NewsSubCategoryModule {
}
