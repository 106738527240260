import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotificationComponent} from '../notification/notification.component';
import {AdmissionNotificationComponent} from './admission-notification.component';

const routes: Routes = [
  {
    path: 'admission-notifications',
    component: AdmissionNotificationComponent,
    data: {animation: 'admission-notifications'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmissionNotificationRoutingModule { }
