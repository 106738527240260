import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {OnAirService} from '../../../shared/services/on-air.service';

@Component({
    selector: 'app-add-on-air',
    templateUrl: './add-on-air.component.html',
    styleUrls: ['./add-on-air.component.scss']
})
export class AddOnAirComponent implements OnChanges {
    addOnAirForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    cities = [];

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private onAirService: OnAirService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-on-air').toggleOpen();
    }

    refresh() {
        this.addOnAirForm = this.fb.group({
            'heading': ['', Validators.required],
            'frame_url': ['', Validators.required],
        });
        //this.getCities();
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addOnAirForm.patchValue({
            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'frame_url': (this.data && this.data['frame_url']) ? this.data['frame_url'] : ''
        });
    }

    /*getCities(params: any = {}) {
        this.cities = [];
        this.cityService.get({'page': -1}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.cities = data['data'];
            } else {
                this.cities = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/

    submit() {
        this.submitted = true;
        if (!this.addOnAirForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'heading': (this.addOnAirForm.value && this.addOnAirForm.value.heading) ? this.addOnAirForm.value.heading : '',
                'frame_url': (this.addOnAirForm.value && this.addOnAirForm.value.frame_url) ? this.addOnAirForm.value.frame_url : ''
            };
            this.submitting = true;
            this.onAirService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addOnAirForm.reset();
                    this.addOnAirForm.patchValue({
                        'heading': '',
                        'frame_url': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].frame_url && error['error']['errors'].frame_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].frame_url[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addOnAirForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'heading': (this.addOnAirForm.value && this.addOnAirForm.value.heading) ? this.addOnAirForm.value.heading : '',
                'frame_url': (this.addOnAirForm.value && this.addOnAirForm.value.frame_url) ? this.addOnAirForm.value.frame_url : ''
            };
            this.submitting = true;
            this.onAirService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                }
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].frame_url && error['error']['errors'].frame_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].frame_url[0]);
                        return;
                    }
                }
            });
        }
    }
}
