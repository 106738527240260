<div class="content-wrapper">
    <div class="content-body">
        <section id="popular-courses">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'ON_AIR_ICON'">
                            <i class="fas fa-user-circle cursor-pointer mr-2"></i>
                            <span>Update Icon</span>
                        </h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="videoData && modalType === 'ON_AIR_VIDEO'">
                            <i class="fas fa-user-circle cursor-pointer mr-2"></i>
                            <span>View Video - {{videoData?.heading}}</span>
                        </h4>
                        <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                           (click)="this.modalService.dismissAll('')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="imageChangedIconEvent && modalType === 'ON_AIR_ICON'">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedIconEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="3 / 4"
                                        [resizeToWidth]="400"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageIconCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                            <div class="text-center display-hidden">
                                <img [src]="croppedIconImage" width="150px" height="150px"/>
                            </div>
                        </div>
                        <div class="row" *ngIf="!imageChangedIconEvent && modalType === 'ON_AIR_ICON'">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-6">
                                <div class="profile-with-cover text-center">
                                    <div class="card-img-top img-fluid bg-cover height-300 profile-background-image image-upload-area cursor-pointer mt-1 mb-1"
                                         *ngIf="!imageChangedIconEvent" (click)="fileIconUpload.click()"></div>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="fileChangeIconEvent($event)" #fileIconUpload>
                                </div>
                            </div>
                            <div class="col-sm-3" *ngIf="!imageChangedIconEvent"></div>
                        </div>
                        <div class="row" *ngIf="videoData && modalType === 'ON_AIR_VIDEO'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <iframe width="100%" [src]="videoUrl"
                                        [title]="videoData?.heading" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen style="padding: 10px; height: 500px"></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-raised" type="button"
                                *ngIf="imageChangedIconEvent && modalType === 'ON_AIR_ICON'"
                                (click)="saveOnAirIcon()">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                        <button class="btn btn-danger btn-raised" type="button" *ngIf="modalType === 'ON_AIR_VIDEO'"
                                (click)="videoData && this.modalService.dismissAll('')">
                            Close
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Trending Videos</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Trending Video
                    </button>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No.</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Heading</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Frame Url</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Icon</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="onAirVideos && onAirVideos.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let onAirVideo of onAirVideos; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{onAirVideo?.heading}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 text-info cursor-pointer"
                                                    (click)="openOnAirViewVideoModal(content, onAirVideo)">
                                                    View
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-primary" title="Upload Icon"
                                                        (click)="openOnAirIconModal(content, onAirVideo.id)"
                                                        *ngIf="onAirVideo && !onAirVideo['on_air_file_id']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <div class="btn-group" role="group"
                                                     *ngIf="onAirVideo && onAirVideo['on_air_file_id']">
                                                    <button type="button" class="btn btn-primary"
                                                            title="Upload Icon"
                                                            (click)="openOnAirIconModal(content, onAirVideo.id)"
                                                            *ngIf="onAirVideo && onAirVideo['on_air_file_id']">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Cover"
                                                            (click)="openOnAirIcon(onAirVideo)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 ml-5px text-info cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Edit" (click)="edit(onAirVideo)"></i>
                                                <i class="fas fa-check-circle f_20 text-success cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="Active"
                                                   (click)="updateStatus('ACTIVE', onAirVideo)"
                                                   *ngIf="onAirVideo && onAirVideo['status'] && onAirVideo['status'] === 'IN_ACTIVE'"></i>
                                                <i class="fas fa-times-circle f_20 text-danger cursor-pointer font-weight-light mt-1 mr-1"
                                                   title="In Active"
                                                   (click)="updateStatus('IN_ACTIVE', onAirVideo)"
                                                   *ngIf="onAirVideo && onAirVideo['status'] && onAirVideo['status'] === 'ACTIVE'"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete" (click)="delete(onAirVideo.id)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="5" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="onAirVideos && onAirVideos.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let onAirVideo of onAirVideos; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{onAirVideo?.heading}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(onAirVideo)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-trash f_20 ml-5px text-info cursor-pointer font-weight-light mt-1"
                                       title="Delete" (click)="delete(onAirVideo.id)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Frame Url - {{onAirVideo?.frame_url}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Icon -
                                    <div class="btn-group" role="group"
                                         *ngIf="onAirVideo && onAirVideo['on_air_file_id']">
                                        <button type="button" class="btn btn-primary" title="View Cover"
                                                (click)="openOnAirIcon(onAirVideo)">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button type="button" class="btn btn-primary" title="Upload Icon"
                                                (click)="openOnAirIconModal(content, onAirVideo.id)"
                                                *ngIf="onAirVideo && onAirVideo['on_air_file_id']">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                        </button>
                                    </div>
                                    <button type="button" class="btn btn-primary" title="Upload Icon"
                                            (click)="openOnAirIconModal(content, onAirVideo.id)"
                                            *ngIf="onAirVideo && !onAirVideo['on_air_file_id']">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-on-air"
            overlayClass="modal-backdrop">
        <app-add-on-air [data]="data" (valueSubmitted)="getData($event)"></app-add-on-air>
    </core-sidebar>
</div>
