import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopStudyLocationsRoutingModule} from './top-study-locations-routing.module';
import {TopStudyLocationsComponent} from './top-study-locations.component';
import {AddTopStudyLocationComponent} from './add-top-study-location/add-top-study-location.component';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
    declarations: [TopStudyLocationsComponent, AddTopStudyLocationComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        TopStudyLocationsRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule,
        ImageCropperModule
    ],
    exports: [TopStudyLocationsComponent, AddTopStudyLocationComponent]
})

export class TopStudyLocationsModule {
}
