import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdmissionNotificationService} from '../../shared/services/admission-notification.service';
import {AlertService} from '../../shared/services/alert.service';
import {SettingService} from '../../shared/services/setting.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    versions: any = {
        'android_version': '',
        'ios_version': ''
    };

    constructor(private router: Router,
                public modalService: NgbModal,
                private settingService: SettingService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.get();
    }

    get() {
        this.submitting = true;
        this.settingService.detail().subscribe(data => {
            this.versions['android_version'] = data.android_version;
            this.versions['ios_version'] = data.ios_version;
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    updateAndroidVersion(event) {
        this.settingService.update({'android_version': event.target.value}).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

    updateIOSVersion(event) {
        this.settingService.update({'ios_version': event.target.value}).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
}
