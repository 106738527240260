<div class="content-wrapper">
    <div class="content-body">
        <div id="user-profile">
            <section id="profile-info">
                <div class="row">
                    <div class="col-lg-9 col-12 order-1 order-lg-2">
                        <div class="form-group mb-2">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Insights Information - {{data?.heading}}</h2>
                                </div>
                                <div class="card-body">
                                   <quill-editor class="university-info" [(ngModel)]="contentData"
                                                  name="quill-content"></quill-editor>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-primary pull-right" rippleEffect (click)="submit()">
                                        <i data-feather="submit" class="d-block d-md-none"></i>
                                        <span class="font-weight-bold d-none d-md-block">Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12 order-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="mb-0">Latest Photos</h5>
                                <div class="row">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h5 class="mb-2">Suggestions</h5>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h5 class="mb-1">Polls</h5>
                                <p class="card-text mb-0">Who is the best actor in Marvel Cinematic Universe?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

