import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {UniversityService} from '../../../shared/services/university.service';
import {StateService} from '../../../shared/services/state.service';
import {CityService} from '../../../shared/services/city.service';

@Component({
    selector: 'app-add-university',
    templateUrl: './add-university.component.html',
    styleUrls: ['./add-university.component.scss']
})
export class AddUniversityComponent implements OnChanges, OnInit {
    addUniversityForm: FormGroup;
    id: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    states: any = [];
    cities: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private universityService: UniversityService,
                private stateService: StateService,
                private cityService: CityService) {
    }

    ngOnInit(): void {
        this.getStates();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-university').toggleOpen();
    }

    refresh() {
        this.addUniversityForm = this.fb.group({
            'name': ['', Validators.required],
            'short_name': ['', (!this.id) ? Validators.required : ''],
            'em_rank': ['', (!this.id) ? Validators.required : ''],
            'nirf_rank': ['', (!this.id) ? Validators.required : ''],
            'rating': ['', (!this.id) ? Validators.required : ''],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'short_url': ['', (!this.id) ? Validators.required : ''],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'password': ['', (!this.data || !this.data['id']) ? Validators.required : ''],
            'short_description': ['', (!this.id) ? Validators.required : ''],
            'address': ['', (!this.id) ? Validators.required : ''],
            'state_id': [''],
            'pin': ['', (!this.id) ? Validators.required : ''],
            'city_id': [''],
            'other_city': [''],
            'url': [''],
        });
        if (this.data && this.data['id']) {
            this.getCities(this.data['state'].id, true);
        }
    }

    getStates() {
        this.stateService.get().subscribe(data => {
            if (data && data['data'].length > 0) {
                this.states = data['data'];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addUniversityForm.valid) {
            return;
        }
        if (!this.addUniversityForm.value['city_id'] && !this.addUniversityForm.value.other_city) {
            this.alertService.showErrors('Please fill City or Other city');
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addUniversityForm.value && this.addUniversityForm.value.name) ? this.addUniversityForm.value.name : '',
                'short_name': (this.addUniversityForm.value && this.addUniversityForm.value.short_name) ? this.addUniversityForm.value.short_name : '',
                'em_rank': (this.addUniversityForm.value && this.addUniversityForm.value.em_rank) ? this.addUniversityForm.value.em_rank : '',
                'nirf_rank': (this.addUniversityForm.value && this.addUniversityForm.value.nirf_rank) ? this.addUniversityForm.value.nirf_rank : '',
                'rating': (this.addUniversityForm.value && this.addUniversityForm.value.rating) ? this.addUniversityForm.value.rating : '',
                'email': (this.addUniversityForm.value && this.addUniversityForm.value.email) ? this.addUniversityForm.value.email : '',
                'short_url': (this.addUniversityForm.value && this.addUniversityForm.value.short_url) ? this.addUniversityForm.value.short_url : '',
                'contact': (this.addUniversityForm.value && this.addUniversityForm.value.contact) ? this.addUniversityForm.value.contact : '',
                'password': (this.addUniversityForm.value && this.addUniversityForm.value.password) ? this.addUniversityForm.value.password : '',
                'short_description': (this.addUniversityForm.value && this.addUniversityForm.value.short_description) ? this.addUniversityForm.value.short_description : '',
                'address': (this.addUniversityForm.value && this.addUniversityForm.value.address) ? this.addUniversityForm.value.address : '',
                'state_id': (this.addUniversityForm.value && this.addUniversityForm.value.state_id) ? this.addUniversityForm.value.state_id : '',
                'pin': (this.addUniversityForm.value && this.addUniversityForm.value.pin) ? this.addUniversityForm.value.pin : '',
                'city_id': (this.addUniversityForm.value && this.addUniversityForm.value.city_id) ? this.addUniversityForm.value.city_id : (this.addUniversityForm.value && this.addUniversityForm.value.other_city) ? this.addUniversityForm.value.other_city : '',
                'url': (this.addUniversityForm.value && this.addUniversityForm.value.url) ? this.addUniversityForm.value.url : '',
            };
            this.submitting = true;
            this.universityService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addUniversityForm.reset();
                    this.addUniversityForm.patchValue({
                        'name': '',
                        'short_name': '',
                        'em_rank': '',
                        'nirf_rank': '',
                        'rating': '',
                        'email': '',
                        'short_url': '',
                        'contact': '',
                        'password': '',
                        'short_description': '',
                        'address': '',
                        'state_id': '',
                        'pin': '',
                        'city_id': '',
                        'other_city': '',
                        'url': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_name[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].short_url && error['error']['errors'].short_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_url[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].password && error['error']['errors'].password.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].password[0]);
                        return;
                    }
                    if (error['error']['errors'].state_id && error['error']['errors'].state_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].state_id[0]);
                        return;
                    }
                    if (error['error']['errors'].pin && error['error']['errors'].pin.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].pin[0]);
                        return;
                    }
                    if (error['error']['errors'].city && error['error']['errors'].city.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].city_id[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addUniversityForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addUniversityForm.value && this.addUniversityForm.value.name) ? this.addUniversityForm.value.name : '',
                'short_name': (this.addUniversityForm.value && this.addUniversityForm.value.short_name) ? this.addUniversityForm.value.short_name : '',
                'em_rank': (this.addUniversityForm.value && this.addUniversityForm.value.em_rank) ? this.addUniversityForm.value.em_rank : '',
                'nirf_rank': (this.addUniversityForm.value && this.addUniversityForm.value.nirf_rank) ? this.addUniversityForm.value.nirf_rank : '',
                'rating': (this.addUniversityForm.value && this.addUniversityForm.value.rating) ? this.addUniversityForm.value.rating : '',
                'email': (this.addUniversityForm.value && this.addUniversityForm.value.email) ? this.addUniversityForm.value.email : '',
                'short_url': (this.addUniversityForm.value && this.addUniversityForm.value.short_url) ? this.addUniversityForm.value.short_url : '',
                'contact': (this.addUniversityForm.value && this.addUniversityForm.value.contact) ? this.addUniversityForm.value.contact : '',
                'short_description': (this.addUniversityForm.value && this.addUniversityForm.value.short_description) ? this.addUniversityForm.value.short_description : '',
                'address': (this.addUniversityForm.value && this.addUniversityForm.value.address) ? this.addUniversityForm.value.address : '',
                'state_id': (this.addUniversityForm.value && this.addUniversityForm.value.state_id) ? this.addUniversityForm.value.state_id : '',
                'pin': (this.addUniversityForm.value && this.addUniversityForm.value.pin) ? this.addUniversityForm.value.pin : '',
                'city_id': (this.addUniversityForm.value && this.addUniversityForm.value.city_id) ? this.addUniversityForm.value.city_id : (this.addUniversityForm.value && this.addUniversityForm.value.other_city) ? this.addUniversityForm.value.other_city : '',
                'url': (this.addUniversityForm.value && this.addUniversityForm.value.url) ? this.addUniversityForm.value.url : ''
            };
            this.submitting = true;
            this.universityService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addUniversityForm.reset();
                this.addUniversityForm.patchValue({
                    'name': '',
                    'short_name': '',
                    'em_rank': '',
                    'nirf_rank': '',
                    'rating': '',
                    'email': '',
                    'short_url': '',
                    'contact': '',
                    'password': '',
                    'short_description': '',
                    'address': '',
                    'state_id': '',
                    'pin': '',
                    'city_id': '',
                    'other_city': '',
                    'url': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].username && error['error']['errors'].username.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_name[0]);
                        return;
                    }
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                }
            });
        }
    }

    getCities(stateId, onRefresh) {
        this.addUniversityForm.patchValue({
            'city_id': '',
            'other_city': ''
        });
        this.cityService.get({'state_id': stateId}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.cities = data['data'];
                if (this.data && onRefresh) {
                    this.patchForm();
                }
            } else {
                this.cities = [];
            }
        });
    }

    resetCityData(cityId) {
        if (cityId) {
            this.addUniversityForm.patchValue({
                'other_city': ''
            });
        }
    }

    patchForm() {
        this.addUniversityForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'short_name': (this.data && this.data['short_name']) ? this.data['short_name'] : '',
            'em_rank': (this.data && this.data['em_rank']) ? this.data['em_rank'] : '',
            'nirf_rank': (this.data && this.data['nirf_rank']) ? this.data['nirf_rank'] : '',
            'rating': (this.data && this.data['rating']) ? this.data['rating'] : '',
            'email': (this.data && this.data['email']) ? this.data['email'] : '',
            'short_url': (this.data && this.data['short_url']) ? this.data['short_url'] : '',
            'contact': (this.data && this.data['contact']) ? this.data['contact'] : '',
            'short_description': (this.data && this.data['short_description']) ? this.data['short_description'] : '',
            'address': (this.data && this.data['address']) ? this.data['address'] : '',
            'state_id': (this.data && this.data['state'] && this.data['state'].id) ? this.data['state'].id : '',
            'pin': (this.data && this.data['pin']) ? this.data['pin'] : '',
            'city_id': (this.data && this.data['city_id']) ? this.data['city_id'] : '',
            'url': (this.data && this.data['url']) ? this.data['url'] : '',
        });
    }
}
