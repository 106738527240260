import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InsightsCategoryRoutingModule} from './insights-category-routing.module';
import {InsightsCategoryComponent} from './insights-category.component';
import {AddInsightsCategoryComponent} from './add-insights-category/add-insights-category.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [InsightsCategoryComponent, AddInsightsCategoryComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        FormsModule,
        ReactiveFormsModule,
        CardSnippetModule,
        CoreSidebarModule,
        InsightsCategoryRoutingModule,
        NgbPaginationModule,
    ],
    exports: [InsightsCategoryComponent, AddInsightsCategoryComponent]
})

export class InsightsCategoryModule {
}
