<div class="content-wrapper">
    <div class="content-body">
        <section id="universities">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'PASSWORD'">Update Password</h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'UNIVERSITY'">
                            <i class="fas fa-user-circle cursor-pointer mr-2"></i>
                            <span>Update Icon</span>
                        </h4>
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'SHORT_DESCRIPTION'">Short
                            Description - {{data?.short_name}}</h4>
                        <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                           (click)="this.modalService.dismissAll('')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="modalType === 'PASSWORD'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updatePasswordForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="password" class="d-flex">
                                                        Password
                                                        <i (click)="inputTypePassword = 'text'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                                                           class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                        <i (click)="inputTypePassword = 'password'"
                                                           [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                                                           class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                                                    </label>
                                                    <input class="form-control" id="password" [type]="inputTypePassword"
                                                           formControlName="password">
                                                    <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row" *ngIf="imageChangedIconEvent && modalType === 'UNIVERSITY'">
                            <div class="text-center col-md-12">
                                <image-cropper
                                        [imageChangedEvent]="imageChangedIconEvent"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="4 / 4"
                                        [resizeToWidth]="400"
                                        format="png, jpeg, jpg"
                                        (imageCropped)="imageIconCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()">
                                </image-cropper>
                            </div>
                            <div class="text-center display-hidden">
                                <img [src]="croppedIconImage" width="150px" height="150px"/>
                            </div>
                        </div>
                        <div class="row" *ngIf="!imageChangedIconEvent && modalType === 'UNIVERSITY'">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-6">
                                <div class="profile-with-cover text-center">
                                    <div class="card-img-top img-fluid bg-cover height-300 profile-background-image image-upload-area cursor-pointer mt-1 mb-1"
                                         *ngIf="!imageChangedIconEvent" (click)="fileIconUpload.click()"></div>
                                    <input class="form-control-file display-hidden" type="file"
                                           (change)="fileChangeIconEvent($event)" #fileIconUpload>
                                </div>
                            </div>
                            <div class="col-sm-3" *ngIf="!imageChangedIconEvent"></div>
                        </div>
                        <div class="row" *ngIf="modalType === 'SHORT_DESCRIPTION'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h6 class="p-1">{{data?.short_description}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting"
                                (click)="updatePassword()" *ngIf="modalType === 'PASSWORD'">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                        <!--<button class="btn btn-danger btn-raised mr-1" type="button"
                                (click)="fileIconUpload.click()" *ngIf="modalType === 'UNIVERSITY'">New
                            Upload
                        </button>-->
                        <button class="btn btn-primary btn-raised" type="button"
                                *ngIf="imageChangedIconEvent && modalType === 'UNIVERSITY'"
                                (click)="saveUniversityIcon()">
                            <i class="fa fa-save mr-1"></i> Save
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Universities</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fas fa-plus-circle mr-8px"></i>&nbsp;Add University
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name" placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="short_name"
                                           placeholder="Short Name">
                                </fieldset>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="email" placeholder="Email">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="number" formControlName="contact"
                                           placeholder="Contact">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list width-120-per">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Short
                                                Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">EM-Rank</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                NIRF-Rank</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">
                                                Rating</h6>
                                            </th>
                                            <th class="text-center width-135px"><h6 class="mt-1 mb-1 font-weight-bold">Url</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center" style="min-width: 200px; max-width: 200px">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Short Description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Address</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">City</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">State</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Pin</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Cover</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Icon</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Password</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="universities && universities.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let university of universities; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center min-width-250px">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="In Active Admin" *ngIf="university?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open f_20 ml-5px text-success cursor-pointer mt-1"
                                                   title="Active Admin" *ngIf="university?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', university)"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation f_20 text-primary ml-5px cursor-pointer font-weight-light mt-1"
                                                   title="University Information"
                                                   (click)="overviewPage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-shekel-sign f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Programs" (click)="programPage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-spa f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Courses" (click)="coursePage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-stumbleupon-circle f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Branches" (click)="branchPage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-code-branch f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="PCB Mapping" (click)="pcbMappingPage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-ad f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="University Marketing Tags" (click)="universityMarketingTag(university)"></i>
                                                <!--<i aria-hidden="true"
                                                   class="fa fa-snowflake-o f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Overview " (click)="overviewPage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-building f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Infrastructure" (click)="infrastructurePage(university)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-diploma f_20 text-primary ml-5px cursor-pointer mt-1"
                                                   title="Courses-Fees " (click)=" coursesAndFee(university)"></i>-->
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.short_name}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.em_rank}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.nirf_rank}}</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.rating}}</h6>
                                            </td>
                                            <td class="text-center" style="min-width: 200px; max-width: 200px"><h6 class="mt-1">{{university?.short_url}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.email}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{university?.contact}}</h6></td>
                                            <td class="text-center">
                                                <h6 class="mt-1 badge badge-pill badge-primary cursor-pointer"
                                                    (click)="shortDescriptionModal(content, university)">View
                                                    Description</h6>
                                                <!--<h6 class="mt-1"
                                                    (click)="shortDescriptionModal(content)">{{university?.short_description}}</h6>-->
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{university?.address}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{university?.city?.name}}</h6></td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{university?.state?.name}}</h6>
                                            </td>
                                            <td class="text-center"><h6
                                                    class="mt-1">{{university?.pin}}</h6></td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="university && university['university_cover_file_id']">
                                                    <button type="button" class="btn btn-primary" title="Upload Cover"
                                                            (click)="coverPhotoUpload.click()">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Cover"
                                                            (click)="openUniversityCover(university)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button class="btn btn-primary color-white" title="Upload Cover"
                                                        (click)="coverPhotoUpload.click()"
                                                        *ngIf="university && !university['university_cover_file_id']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input class="form-control-file display-hidden" type="file"
                                                       (change)="onFileChange($event, 'UNIVERSITY_COVER', university)"
                                                       #coverPhotoUpload>
                                            </td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="university && university['university_icon_file_id']">
                                                    <button type="button" class="btn btn-primary" title="Upload Icon"
                                                            (click)="openUniversityIconModal(content, university.id)">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Cover"
                                                            (click)="viewUniversityIconModal(university)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button type="button" class="btn btn-primary" title="Upload Icon"
                                                        (click)="openUniversityIconModal(content, university.id)"
                                                        *ngIf="university && !university['university_icon_file_id']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                            </td>
                                            <td class="text-center text-info cursor-pointer">
                                                <h6 class="mt-1 text-info"
                                                    (click)="updatePasswordModal(content, university.id)">Update</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="15" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="universities && universities.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let university of universities; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{university?.name}}</h4>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active University" *ngIf="university?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', university)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active University" *ngIf="university?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', university)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 ml-1 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(university)"></i>
                                    <i aria-hidden="true"
                                       class="fab fa-artstation f_20 text-primary cursor-pointer font-weight-light pull-right"
                                       title="Perform Actions" (click)="landingPage(university)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Short Name - {{university?.short_name}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Email - {{university?.email}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - {{university?.contact}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Address - {{university?.address}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    City - {{university?.city}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    State - {{university?.state?.name}}
                                </div>
                            </div>
                            <div class="row p-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Pin - {{university?.pin}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-university"
            overlayClass="modal-backdrop">
        <app-add-university [data]="data" (valueSubmitted)="getData($event)"></app-add-university>
    </core-sidebar>
</div>
