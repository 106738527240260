import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UniversitiesRoutingModule} from './universities-routing.module';
import {UniversitiesComponent} from './universities.component';
import {AddUniversityComponent} from './add-university/add-university.component';
import {ContentHeaderModule} from '../../layout/components/content-header/content-header.module';
import {TranslateModule} from '@ngx-translate/core';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbCarouselModule, NgbNavModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {UniversityLandingComponent} from './university-landing/university-landing.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {SharedModule} from '../../shared/shared.module';
import {ImageCropperModule} from 'ngx-image-cropper';
import {UniversityProgramsComponent} from './university-programs/university-programs.component';
import {UniversityCoursesComponent} from './university-courses/university-courses.component';
import {UniversityBranchesComponent} from './university-branches/university-branches.component';
import {AddUniversityProgramComponent} from './university-programs/add-university-program/add-university-program.component';
import {AddUniversityCourseComponent} from './university-courses/add-university-course/add-university-course.component';
import {AddUniversityBranchComponent} from './university-branches/add-university-branch/add-university-branch.component';
import {UniversityPcbMappingComponent} from './university-pcb-mapping/university-pcb-mapping.component';
import {AddUniversityPcbMappingComponent} from './university-pcb-mapping/add-university-pcb-mapping/add-university-pcb-mapping.component';
import {UniversityCoursesFeesComponent} from './university-courses-fees/university-courses-fees.component';
import {UniversityInfrastructureComponent} from './university-infrastructure/university-infrastructure.component';
import {UniversityAdmissionComponent} from './university-admission/university-admission.component';
import {UniversityContactComponent} from './university-contact/university-contact.component';
import {QuillModule} from 'ngx-quill';
import {UniversityOverviewComponent} from './university-overview/university-overview.component';
import {AddUniversityInfrastructureComponent} from './university-infrastructure/add-university-infrastructure/add-university-infrastructure.component';

import { AddUniversityMarketingTagComponent } from './univerity-marketing-tag/add-university-marketing-tag/add-university-marketing-tag.component';
import {UniverityMarketingTagComponent} from './univerity-marketing-tag/univerity-marketing-tag.component';

@NgModule({
    declarations: [
        UniversitiesComponent,
        AddUniversityComponent,
        UniversityLandingComponent,
        UniversityProgramsComponent,
        UniversityCoursesComponent,
        UniversityBranchesComponent,
        AddUniversityProgramComponent,
        AddUniversityCourseComponent,
        AddUniversityBranchComponent,
        UniversityPcbMappingComponent,
        AddUniversityPcbMappingComponent,
        UniversityCoursesFeesComponent,
        UniversityInfrastructureComponent,
        UniversityAdmissionComponent,
        UniversityContactComponent,
        UniversityOverviewComponent,
        AddUniversityInfrastructureComponent,
        UniverityMarketingTagComponent,
        AddUniversityMarketingTagComponent
    ],
    imports: [
        CommonModule,
        UniversitiesRoutingModule,
        ContentHeaderModule,
        TranslateModule,
        CoreCommonModule,
        CardSnippetModule,
        NgxDatatableModule,
        CoreSidebarModule,
        NgbPaginationModule,
        NgbCarouselModule,
        NgbNavModule,
        GoogleMapsModule,
        SharedModule,
        ImageCropperModule,
        QuillModule
    ],
    exports: [UniversitiesComponent, AddUniversityComponent, UniversityLandingComponent]
})
export class UniversitiesModule {
}
