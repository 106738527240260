import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {InsightsComponent} from './insights.component';
import {AddInsightsComponent} from './add-insights/add-insights.component';
import {AddInsightsInfoComponent} from './add-insights-info/add-insights-info.component';

const routes: Routes = [
  {
    path: 'insights',
    component: InsightsComponent,
    data: {animation: 'insights'}
  },
  {
    path: 'insights/add',
    component: AddInsightsComponent,
    data: {animation: 'insights/add'}
  },
  {
    path: 'insights-info/:id',
    component: AddInsightsInfoComponent,
    data: {animation: 'insights/Info'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InsightsRoutingModule { }
