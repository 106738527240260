export const locale = {
    lang: 'en',
    data: {
        MENU: {
            HOME: 'Home',
            SAMPLE: 'Sample',
            DASHBOARD: 'Dashboard',
            ADMIN: 'Admin',
            LANDING_PAGE: 'Landing Page',
            ADMINS: 'Admins',
            HEAD_COUNSELLORS: 'Head Counsellors',
            COUNSELLORS: 'Counsellors',
            UNIVERSITIES: 'Universities',
            UNIVERSITYSETTINGS: 'Settings',
            PROGRAMS: 'Programs',
            PROGRAM: 'Program',
            COURSES: 'Courses',
            BRANCHES: 'Branches',
            PCBMAPPING: 'PCB-Mapping',
            STAGES: 'Stages',
            REASONS: 'Reasons',
            SRMAPPING: 'SR-Mapping',
            STUDENTS: 'Students',
            PERSONALIZE_GUIDANCE: 'Personalize Guidance',
            ASK_EXPERT_PROFESSIONAL: 'Ask Expert Professional',
            CONSULT_YOUR_GUIDE: 'Consult Your Guide',
            MAILS: 'Mails',
            TEXT_MESSAGES: 'Text Messages',
            WHATSAPP: 'Whatsapp',
            COURSE_CURRICULUM: 'Course-Curriculum',
            POPULAR_COURSES: 'Popular-Courses',
            INSIGHTS: 'insights',
            NEWS: 'News',
            MAIN_BANNER_SLIDER: 'Main Banner Slider',
            TOP_STUDY_LOCATIONS: 'Top Study Locations',
            ON_AIR: 'On AIR',
            SCRIBBLE_WALL: 'Scribble Wall',
            NOTIFICATION: 'Notifications',
            NEWS_CATEGORY: 'News Category',
            NEWS_SUBCATEGORY: 'News Sub Category',
            INSIGHTS_CATEGORY: 'Insights Category',
            INSIGHTS_SUBCATEGORY: 'Insights Sub Category',
            ADMISSION_NOTIFICATION: 'Admission Notifications',
            SETTINGS: 'Settings'
        }
    }
};
