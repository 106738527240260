import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-university-infrastructure',
  templateUrl: './add-university-infrastructure.component.html',
  styleUrls: ['./add-university-infrastructure.component.scss']
})
export class AddUniversityInfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
