import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import * as http from 'http';

@Injectable({
    providedIn: 'root'
})
export class CourseService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.COURSES(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.ALL_COURSES_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.COURSES(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.COURSES(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES(id), data);
    }

    createByAdmin(id, data): Observable<any> {
        return this.postRequest(AppUrl.UNIVERSITY_COURSES(id), data);
    }

    getByAdmin(id, data): Observable<any> {
        return this.getRequest(AppUrl.UNIVERSITY_COURSES(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES_STATUS(id), data);
    }

    updateByAdmin(universityId, id, data): Observable<any> {
        return this.putRequest(AppUrl.COURSES_UPDATE_BY_ADMIN(universityId, id), data);
    }

    getCourse(data): Observable<any> {
        return this.getRequest(AppUrl.ALL_CONTACT(), data);
    }

}
