import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniversityService} from '../../shared/services/university.service';
import {DataSharing} from '../../shared/constants/DataSharing';
import {AlertService} from '../../shared/services/alert.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-universities',
    templateUrl: './universities.component.html',
    styleUrls: ['./universities.component.scss']
})
export class UniversitiesComponent implements OnInit {
    showSearch = false;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    universityId: any;
    universities = [];
    states = [];
    onFileChanged: any;
    imageChangedIconEvent: any;
    croppedIconImageEvent: any;
    croppedIconImage: any;
    modalType: any;
    id: any;
    user: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private universityService: UniversityService,
                private alertService: AlertService,
                private dataSharing: DataSharing) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit(): void {
        if (this.user && this.user['user_type'] !== 'SUPER_ADMIN') {
            this.router.navigateByUrl('dashboard');
        } else {
            this.refresh();
            this.dataSharing.savedUniversityId.subscribe(universityId => this.universityId = universityId);
        }
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'short_name': [''],
            'em_rank': [''],
            'email': [''],
            'address': [''],
            'state_id': [''],
            'pin': [''],
            'contact': [''],
        });
        this.get();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-university').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.universities = [];
        this.universityService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.universities = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.universities = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'short_name': (this.searchForm.value && this.searchForm.value.short_name) ? this.searchForm.value.short_name : '',
            'em_rank': (this.searchForm.value && this.searchForm.value.em_rank) ? this.searchForm.value.em_rank : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'address': (this.searchForm.value && this.searchForm.value.address) ? this.searchForm.value.address : '',
            'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : '',
            'pin': (this.searchForm.value && this.searchForm.value.pin) ? this.searchForm.value.pin : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePasswordModal(content, id) {
        this.id = id;
        this.updatePasswordForm.patchValue({
            'password': ''
        });
        this.modalType = 'PASSWORD';
        this.modalService.open(content, {'size': 'sm'});
    }

    updatePassword() {
        this.submitted = true;
        if (!this.updatePasswordForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'password': (this.updatePasswordForm.value && this.updatePasswordForm.value.password) ? this.updatePasswordForm.value.password : ''
            };
            this.submitting = true;
            this.universityService.updatePassword(this.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.updatePasswordForm.patchValue({
                    'password': '',
                });
                this.modalService.dismissAll('');
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'short_name': (this.searchForm && this.searchForm.value && this.searchForm.value.short_name) ? this.searchForm.value.short_name : '',
            'em_rank': (this.searchForm && this.searchForm.value && this.searchForm.value.em_rank) ? this.searchForm.value.em_rank : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'address': (this.searchForm.value && this.searchForm.value.address) ? this.searchForm.value.address : '',
            'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : '',
            'pin': (this.searchForm.value && this.searchForm.value.pin) ? this.searchForm.value.pin : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.universityService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

    landingPage(data: any) {
        this.router.navigateByUrl('university-landing/' + data.id);
    }

    overviewPage(data: any) {
        this.router.navigateByUrl('university/' + data.id + '/overview');
    }

    programPage(data: any) {
        this.router.navigateByUrl('university-program/' + data.id);
    }

    coursePage(data: any) {
        this.router.navigateByUrl('university-course/' + data.id);
    }

    branchPage(data: any) {
        this.router.navigateByUrl('university-branch/' + data.id);
    }

    pcbMappingPage(data: any) {
        this.router.navigateByUrl('university-pcb-mapping/' + data.id);
    }

    universityMarketingTag(data: any) {
        this.router.navigateByUrl('university-marketing-tag/' + data.id);
    }

    openUniversityCover(data) {
        window.open(data['university_cover_file']['url'], '_blank');
    }

    onFileChange(e, type, data) {
        this.onFileChanged = e;
        this.universityService.fileUploadAdmin({
            'type': type,
            'fileType': 'NORMAL',
            'university_id': data.id
        }, this.onFileChanged).subscribe(data => {
            if (type === 'UNIVERSITY_COVER') {
                if (data && data['data']) {
                    this.get();
                }
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    imageIconCropped(event: ImageCroppedEvent) {
        this.croppedIconImageEvent = event;
        this.croppedIconImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    fileChangeIconEvent(event: any): void {
        this.imageChangedIconEvent = event;
    }

    openUniversityIconModal(content, id) {
        this.id = id;
        this.modalType = 'UNIVERSITY';
        this.modalService.open(content, {size: 'lg'});
    }

    viewUniversityIconModal(data) {
        window.open(data['university_icon_file']['url'], '_blank');
    }

    saveUniversityIcon() {
        const params = {
            'type': 'UNIVERSITY_ICON',
            'university_id': this.id,
            'fileType': 'BASE64'
        };
        this.universityService.fileUploadAdmin(params, this.croppedIconImage).subscribe(data => {
            if (data) {
                this.imageChangedIconEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
                this.get();
            }
        });
    }

    shortDescriptionModal(content, data) {
        this.modalType = 'SHORT_DESCRIPTION';
        this.data = data;
        this.modalService.open(content, {'size': 'sm'});
    }
}
