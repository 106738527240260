import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainBannerSliderRoutingModule} from './main-banner-slider-routing.module';
import {MainBannerSliderComponent} from './main-banner-slider.component';
import {AddMainBannerSliderComponent} from './add-main-banner-slider/add-main-banner-slider.component';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [MainBannerSliderComponent, AddMainBannerSliderComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        MainBannerSliderRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule
    ],
    exports: [MainBannerSliderComponent, AddMainBannerSliderComponent]
})

export class MainBannerSliderModule {
}
