import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {UniversityAdmissionService} from '../../../shared/services/university-admission.service';
import {UniversityService} from '../../../shared/services/university.service';

@Component({
    selector: 'app-university-admission',
    templateUrl: './university-admission.component.html',
    styleUrls: ['./university-admission.component.scss']
})
export class UniversityAdmissionComponent implements OnInit {
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    public universityId: any;
    submitting: any = false;
    submitted: any = false;
    dataExists: any = false;
    university: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private universityAdmissionService: UniversityAdmissionService,
                private universityService: UniversityService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.getUniversityDetail();
    }

    getUniversityDetail() {
        this.universityService.detail(this.universityId).subscribe(data => {
            if (data) {
                this.university = data;
                this.getUniversityAdmissionDetail();
            }
        });
    }

    getUniversityAdmissionDetail() {
        this.universityAdmissionService.detailByAdmin(this.universityId).subscribe(data => {
            if (data && data['info']) {
                this.data = data['info'];
                this.dataExists = true;
            } else {
                this.dataExists = false;
            }
        });
    }

    submit() {
        let params = {
            'info': this.data ? this.data : ''
        };
        if (this.dataExists) {
            this.universityAdmissionService.updateByAdmin(this.universityId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        } else {
            this.universityAdmissionService.createByAdmin(this.universityId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    goTo(tab) {
        this.router.navigateByUrl('university/' + this.universityId + '/' + tab);
    }
}
