import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InsightsSubCategoryRoutingModule} from './insights-subcategory-routing.module';
import {InsightsSubCategoryComponent} from './insights-subcategory.component';
import {AddInsightsSubCategoryComponent} from './add-insights-subcategory/add-insights-subcategory.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import {CardSnippetModule} from '../../../@core/components/card-snippet/card-snippet.module';

@NgModule({
    declarations: [InsightsSubCategoryComponent, AddInsightsSubCategoryComponent],
    imports: [
        CommonModule,
        CoreCommonModule,
        CardSnippetModule,
        CoreSidebarModule,
        InsightsSubCategoryRoutingModule,
        NgbPaginationModule,
    ],
    exports: [InsightsSubCategoryComponent, AddInsightsSubCategoryComponent]
})

export class InsightsSubCategoryModule {
}
