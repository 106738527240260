import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../../shared/services/global.service';
import {ProgramService} from '../../../../shared/services/program.service';
import {ActivatedRoute} from '@angular/router';
import {UniversityMarketingTagService} from '../../../../shared/services/university-marketing-tag.service';

@Component({
    selector: 'app-add-university-marketing-tag',
    templateUrl: './add-university-marketing-tag.component.html',
    styleUrls: ['./add-university-marketing-tag.component.scss']
})
export class AddUniversityMarketingTagComponent implements OnChanges {
    addUniversityMarketingTagForm: FormGroup;
    id: any;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    universityId: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                public universityMarketingTagService: UniversityMarketingTagService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnChanges(): void {
        this.universityId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-university-marketing-tag').toggleOpen();
    }

    refresh() {
        this.addUniversityMarketingTagForm = this.fb.group({
            'title': [''],
            'description': [''],
            'keywords': [''],
            'canonical': [''],
            'author': [''],
            'robots': [''],
            'googlebot': [''],
            'yahooSeeker': [''],
            'msnbot': [''],
            'distribution': [''],
            'google': [''],
            'document_type': [''],
            'country': [''],
            'city': [''],
            'property_title': [''],
            'property_url': [''],
            'property_type': [''],
            'property_description': [''],
            'property_image': [''],
            'twitter_card': [''],
            'twitter_title': [''],
            'twitter_description': [''],
            'twitter_url': [''],
            'twitter_image': [''],
            'geo_region': [''],
            'geo_placename': [''],
            'geo_position': [''],
            'icbm': [''],
            'script': [''],

        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addUniversityMarketingTagForm.patchValue({
            'title': (this.data && this.data['title']) ? this.data['title'] : '',
            'description': (this.data && this.data['description']) ? this.data['description'] : '',
            'keywords': (this.data && this.data['keywords']) ? this.data['keywords'] : '',
            'canonical': (this.data && this.data['canonical']) ? this.data['canonical'] : '',
            'author': (this.data && this.data['author']) ? this.data['author'] : '',
            'robots': (this.data && this.data['robots']) ? this.data['robots'] : '',
            'googlebot': (this.data && this.data['googlebot']) ? this.data['googlebot'] : '',
            'yahooSeeker': (this.data && this.data['yahooSeeker']) ? this.data['yahooSeeker'] : '',
            'msnbot': (this.data && this.data['msnbot']) ? this.data['msnbot'] : '',
            'distribution': (this.data && this.data['distribution']) ? this.data['distribution'] : '',
            'google': (this.data && this.data['google']) ? this.data['google'] : '',
            'document_type': (this.data && this.data['document_type']) ? this.data['document_type'] : '',
            'country': (this.data && this.data['country']) ? this.data['country'] : '',
            'city': (this.data && this.data['city']) ? this.data['city'] : '',
            'property_title': (this.data && this.data['property_title']) ? this.data['property_title'] : '',
            'property_url': (this.data && this.data['property_url']) ? this.data['property_url'] : '',
            'property_type': (this.data && this.data['property_type']) ? this.data['property_type'] : '',
            'property_description': (this.data && this.data['property_description']) ? this.data['property_description'] : '',
            'property_image': (this.data && this.data['property_image']) ? this.data['property_image'] : '',
            'twitter_card': (this.data && this.data['twitter_card']) ? this.data['twitter_card'] : '',
            'twitter_title': (this.data && this.data['twitter_title']) ? this.data['twitter_title'] : '',
            'twitter_description': (this.data && this.data['twitter_description']) ? this.data['twitter_description'] : '',
            'twitter_url': (this.data && this.data['twitter_url']) ? this.data['twitter_url'] : '',
            'twitter_image': (this.data && this.data['twitter_image']) ? this.data['twitter_image'] : '',
            'geo_region': (this.data && this.data['geo_region']) ? this.data['geo_region'] : '',
            'geo_placename': (this.data && this.data['geo_placename']) ? this.data['geo_placename'] : '',
            'geo_position': (this.data && this.data['geo_position']) ? this.data['geo_position'] : '',
            'icbm': (this.data && this.data['icbm']) ? this.data['icbm'] : '',
            'script': (this.data && this.data['script']) ? this.data['script'] : '',
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addUniversityMarketingTagForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.title) ? this.addUniversityMarketingTagForm.value.title : '',
                'description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.description) ? this.addUniversityMarketingTagForm.value.description : '',
                'keywords': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.keywords) ? this.addUniversityMarketingTagForm.value.keywords : '',
                'canonical': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.canonical) ? this.addUniversityMarketingTagForm.value.canonical : '',
                'author': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.author) ? this.addUniversityMarketingTagForm.value.author : '',
                'robots': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.robots) ? this.addUniversityMarketingTagForm.value.robots : '',
                'googlebot': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.googlebot) ? this.addUniversityMarketingTagForm.value.googlebot : '',
                'yahooSeeker': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.yahooSeeker) ? this.addUniversityMarketingTagForm.value.yahooSeeker : '',
                'msnbot': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.msnbot) ? this.addUniversityMarketingTagForm.value.msnbot : '',
                'distribution': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.distribution) ? this.addUniversityMarketingTagForm.value.distribution : '',
                'google': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.google) ? this.addUniversityMarketingTagForm.value.google : '',
                'document_type': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.document_type) ? this.addUniversityMarketingTagForm.value.document_type : '',
                'country': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.country) ? this.addUniversityMarketingTagForm.value.country : '',
                'city': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.city) ? this.addUniversityMarketingTagForm.value.city : '',
                'property_title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_title) ? this.addUniversityMarketingTagForm.value.property_title : '',
                'property_url': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_url) ? this.addUniversityMarketingTagForm.value.property_url : '',
                'property_type': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_type) ? this.addUniversityMarketingTagForm.value.property_type : '',
                'property_description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_description) ? this.addUniversityMarketingTagForm.value.property_description : '',
                'property_image': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_image) ? this.addUniversityMarketingTagForm.value.property_image : '',
                'twitter_card': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_card) ? this.addUniversityMarketingTagForm.value.twitter_card : '',
                'twitter_title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_title) ? this.addUniversityMarketingTagForm.value.twitter_title : '',
                'twitter_description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_description) ? this.addUniversityMarketingTagForm.value.twitter_description : '',
                'twitter_url': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_url) ? this.addUniversityMarketingTagForm.value.twitter_url : '',
                'twitter_image': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_image) ? this.addUniversityMarketingTagForm.value.twitter_image : '',
                'geo_region': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_region) ? this.addUniversityMarketingTagForm.value.geo_region : '',
                'geo_placename': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_placename) ? this.addUniversityMarketingTagForm.value.geo_placename : '',
                'geo_position': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_position) ? this.addUniversityMarketingTagForm.value.geo_position : '',
                'icbm': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.icbm) ? this.addUniversityMarketingTagForm.value.icbm : '',
                'script': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.script) ? this.addUniversityMarketingTagForm.value.script : '',
            };
            this.submitting = true;
            this.universityMarketingTagService.create(this.universityId, params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addUniversityMarketingTagForm.reset();
                    this.addUniversityMarketingTagForm.patchValue({
                        'title': '',
                        'description': '',
                        'keywords': '',
                        'canonical': '',
                        'author': '',
                        'robots': '',
                        'googlebot': '',
                        'yahooSeeker': '',
                        'msnbot': '',
                        'distribution': '',
                        'google': '',
                        'document_type': '',
                        'country': '',
                        'city': '',
                        'property_title': '',
                        'property_url': '',
                        'property_type': '',
                        'property_description': '',
                        'property_image': '',
                        'twitter_card': '',
                        'twitter_title': '',
                        'twitter_description': '',
                        'twitter_url': '',
                        'twitter_image': '',
                        'geo_region': '',
                        'geo_placename': '',
                        'geo_position': '',
                        'icbm': '',
                        'script': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addUniversityMarketingTagForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.title) ? this.addUniversityMarketingTagForm.value.title : '',
                'description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.description) ? this.addUniversityMarketingTagForm.value.description : '',
                'keywords': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.keywords) ? this.addUniversityMarketingTagForm.value.keywords : '',
                'canonical': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.canonical) ? this.addUniversityMarketingTagForm.value.canonical : '',
                'author': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.author) ? this.addUniversityMarketingTagForm.value.author : '',
                'robots': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.robots) ? this.addUniversityMarketingTagForm.value.robots : '',
                'googlebot': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.googlebot) ? this.addUniversityMarketingTagForm.value.googlebot : '',
                'yahooSeeker': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.yahooSeeker) ? this.addUniversityMarketingTagForm.value.yahooSeeker : '',
                'msnbot': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.msnbot) ? this.addUniversityMarketingTagForm.value.msnbot : '',
                'distribution': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.distribution) ? this.addUniversityMarketingTagForm.value.distribution : '',
                'google': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.google) ? this.addUniversityMarketingTagForm.value.google : '',
                'document_type': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.document_type) ? this.addUniversityMarketingTagForm.value.document_type : '',
                'country': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.country) ? this.addUniversityMarketingTagForm.value.country : '',
                'city': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.city) ? this.addUniversityMarketingTagForm.value.city : '',
                'property_title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_title) ? this.addUniversityMarketingTagForm.value.property_title : '',
                'property_url': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_url) ? this.addUniversityMarketingTagForm.value.property_url : '',
                'property_type': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_type) ? this.addUniversityMarketingTagForm.value.property_type : '',
                'property_description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_description) ? this.addUniversityMarketingTagForm.value.property_description : '',
                'property_image': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.property_image) ? this.addUniversityMarketingTagForm.value.property_image : '',
                'twitter_card': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_card) ? this.addUniversityMarketingTagForm.value.twitter_card : '',
                'twitter_title': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_title) ? this.addUniversityMarketingTagForm.value.twitter_title : '',
                'twitter_description': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_description) ? this.addUniversityMarketingTagForm.value.twitter_description : '',
                'twitter_url': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_url) ? this.addUniversityMarketingTagForm.value.twitter_url : '',
                'twitter_image': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.twitter_image) ? this.addUniversityMarketingTagForm.value.twitter_image : '',
                'geo_region': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_region) ? this.addUniversityMarketingTagForm.value.geo_region : '',
                'geo_placename': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_placename) ? this.addUniversityMarketingTagForm.value.geo_placename : '',
                'geo_position': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.geo_position) ? this.addUniversityMarketingTagForm.value.geo_position : '',
                'icbm': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.icbm) ? this.addUniversityMarketingTagForm.value.icbm : '',
                'script': (this.addUniversityMarketingTagForm.value && this.addUniversityMarketingTagForm.value.script) ? this.addUniversityMarketingTagForm.value.script : '',
            };
            this.submitting = true;
            this.universityMarketingTagService.update(this.data['id'],params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }
}
