import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {NewsCategoryService} from '../../../shared/services/news-category.service';


@Component({
    selector: 'app-add-news-category',
    templateUrl: './add-news-category.component.html',
    styleUrls: ['./add-news-category.component.scss']
})
export class AddNewsCategoryComponent implements OnChanges {
    addNewscategoryForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    newsCategory: any = [];

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private newscategoryService: NewsCategoryService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-news-category').toggleOpen();
    }


    refresh() {
        this.addNewscategoryForm = this.fb.group({
            'name': ['', Validators.required],
            'url': ['', (!this.id) ? Validators.required : ''],
            'meta_title': ['', (!this.id) ? Validators.required : ''],
            'meta_description': ['', (!this.id) ? Validators.required : ''],
            'canonical_tag': ['', (!this.id) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        this.addNewscategoryForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'url': (this.data && this.data['url']) ? this.data['url'] : '',
            'meta_title': (this.data && this.data['meta_title']) ? this.data['meta_title'] : '',
            'meta_description': (this.data && this.data['meta_description']) ? this.data['meta_description'] : '',
            'canonical_tag': (this.data && this.data['canonical_tag']) ? this.data['canonical_tag'] : ''
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addNewscategoryForm.valid) {
            return;
        }

        if (this.submitted) {
            const params = {
                'name': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.name) ? this.addNewscategoryForm.value.name : '',
                'url': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.url) ? this.addNewscategoryForm.value.url : '',
                'meta_title': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.meta_title) ? this.addNewscategoryForm.value.meta_title : '',
                'meta_description': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.meta_description) ? this.addNewscategoryForm.value.meta_description : '',
                'canonical_tag': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.canonical_tag) ? this.addNewscategoryForm.value.canonical_tag : ''

            };
            this.submitting = true;
            this.newscategoryService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNewscategoryForm.reset();
                    this.addNewscategoryForm.patchValue({
                        'name': '',
                        'url': '',
                        'meta_title': '',
                        'meta_description': '',
                        'canonical_tag': ''
                    });
                }
            }, error => {
                this.submitting = false;

                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].url && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_title && error['error']['errors'].meta_title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].canonical_tag && error['error']['errors'].canonical_tag.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].canonical_tag[0]);
                        return;
                    }
                }

            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNewscategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.name) ? this.addNewscategoryForm.value.name : '',
                'url': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.url) ? this.addNewscategoryForm.value.url : '',
                'meta_title': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.meta_title) ? this.addNewscategoryForm.value.meta_title : '',
                'meta_description': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.meta_description) ? this.addNewscategoryForm.value.meta_description : '',
                'canonical_tag': (this.addNewscategoryForm.value && this.addNewscategoryForm.value.canonical_tag) ? this.addNewscategoryForm.value.canonical_tag : ''
            };
            this.submitting = true;
            this.newscategoryService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].url[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_title && error['error']['errors'].meta_title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_title[0]);
                        return;
                    }
                    if (error['error']['errors'].meta_description && error['error']['errors'].meta_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].meta_description[0]);
                        return;
                    }
                    if (error['error']['errors'].canonical_tag && error['error']['errors'].canonical_tag.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].canonical_tag[0]);
                        return;
                    }
                }
            });
        }
    }
}
