<div class="content-wrapper">
    <div class="content-body">
        <div id="user-profile">
            <div class="row">
                <div class="col-12">
                    <div class="card profile-header mb-2">
                        <img class="card-img-top" src="{{university?.university_cover_file?.url}}"
                             alt="{{university?.name}}"/>
                        <div class="position-relative">
                            <div class="profile-img-container d-flex align-items-center">
                                <div class="profile-img">
                                    <img src="{{university?.university_icon_file?.url}}"
                                         class="rounded img-fluid"
                                         alt="Card image"/>
                                </div>
                                <div class="profile-title ml-3">
                                    <h2 class="text-white">{{university?.name}}</h2>
                                    <p class="text-white">{{university?.city?.name}}, {{university?.state?.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="profile-header-nav">
                            <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                                <button
                                        class="btn btn-icon navbar-toggler"
                                        type="button"
                                        data-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        (click)="toggleMenu = !toggleMenu"
                                        rippleEffect
                                >
                                    <i data-feather="align-justify" class="font-medium-5"></i>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent"
                                     [ngbCollapse]="toggleMenu">
                                    <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                                        <ul class="nav nav-pills mb-0">
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold"
                                                   (click)="goTo('overview')">
                                                    <span class="d-none d-md-block">Overview</span>
                                                    <i data-feather="rss" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('courses-fees')">
                                                    <span class="d-none d-md-block">Courses & Fees</span>
                                                    <i data-feather="info" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('infrastructure')">
                                                    <span class="d-none d-md-block">Infrastructure</span>
                                                    <i data-feather="image" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold" (click)="goTo('admission')">
                                                    <span class="d-none d-md-block">Admission</span>
                                                    <i data-feather="users" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link font-weight-bold active" (click)="goTo('contact')">
                                                    <span class="d-none d-md-block">Contact</span>
                                                    <i data-feather="users" class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <!--<button class="btn btn-primary" rippleEffect>
                                            <i data-feather="edit" class="d-block d-md-none"></i>
                                            <span class="font-weight-bold d-none d-md-block">Edit</span>
                                        </button>-->
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section id="profile-info">
                <div class="row">
                    <div class="col-lg-12 col-12 order-1 order-lg-2">
                        <div class="form-group mb-2">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Contact</h2>
                                </div>
                                <div class="card-body">
                                    <small>Contact Info</small>
                                    <quill-editor class="university-info" [(ngModel)]="data"
                                                  name="quill-content"></quill-editor>
                                </div>
                                <div class="card-body">
                                    <small>Map</small>
                                    <textarea class="form-control resize-none" id="map" type="text" rows="3"
                                              [(ngModel)]="map"></textarea>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-primary pull-right" rippleEffect (click)="submit()">
                                        <i data-feather="submit" class="d-block d-md-none"></i>
                                        <span class="font-weight-bold d-none d-md-block">Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
