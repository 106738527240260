<div class="content-wrapper">
    <div class="content-body">
        <section id="students">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'COUNSELLOR_ASSIGN'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Assign Counsellor - {{ selectedStudent?.name }}</span>
                        </h4>
                        <h4 *ngIf="modalType === 'FOLLOW_UP'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Follow Up - {{ selectedStudent?.name }}</span>
                        </h4>
                        <h4 *ngIf="modalType === 'VIEW_FOLLOW_UP_REMARKS'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Follow Up Remarks - {{ selectedStudent?.name }}</span>
                        </h4>
                        <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                           (click)="this.modalService.dismissAll('')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="modalType === 'COUNSELLOR_ASSIGN'">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                            <form class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 form mt-2 shadow-none"
                                  novalidate [formGroup]="assignCounsellorForm">
                                <div class="form-group">
                                    <label for="assigned_to">Assign Counsellor</label>
                                    <select class="form-control" id="assigned_to" formControlName="assigned_to">
                                        <option value="">Select Counsellor</option>
                                        <option [value]="counsellor?.user?.id"
                                                *ngFor="let counsellor of counsellors">{{ counsellor?.user?.name }}
                                        </option>
                                    </select>
                                    <small *ngIf="submitted && assignCounsellorForm.get('assigned_to').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </form>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                        </div>

                        <div class="row justify-content-center" *ngIf="modalType === 'FOLLOW_UP'">
                            <form
                                    class="col-xl-6 col-lg-8 col-md-10 col-sm-12 form mt-2 shadow-none"
                                    novalidate
                                    [formGroup]="followUpForm"
                            >
                                <div class="form-group">
                                    <label for="subject">Subjects</label>
                                    <input
                                            class="form-control"
                                            type="text" id="subject"
                                            formControlName="subject"
                                            placeholder="Enter Subjects"
                                    />
                                    <small
                                            *ngIf="submitted && followUpForm.get('subject').hasError('required')"
                                            class="text-danger"
                                    >
                                        Field is required.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label for="stage_id">Stage</label>


                                    <select class="form-control" id="stage_id" formControlName="stage_id"
                                            (change)="getReasonsFollowups($event)">
                                        <option value="">Select Stage</option>
                                        <option [value]="stage?.id"
                                                *ngFor="let stage of allStages">{{ stage.name }}
                                        </option>
                                    </select>
                                    <small
                                            *ngIf="submitted && followUpForm.get('stage_id').hasError('required')"
                                            class="text-danger"
                                    >
                                        Field is required.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label for="reason_id">Reason</label>
                                    <select class="form-control" id="reason_id" formControlName="reason_id">
                                        <option value="">Select Reason</option>
                                        <option [value]="reason?.id"
                                                *ngFor="let reason of reasonFollowups">{{ reason?.reasons.name }}
                                        </option>
                                    </select>
                                    <small
                                            *ngIf="submitted && followUpForm.get('reason_id').hasError('required')"
                                            class="text-danger"
                                    >
                                        Field is required.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label for="type">Follow Up</label>
                                    <select class="form-control" id="type" formControlName="type">
                                        <option value="">Select Type</option>
                                        <option value="CALL">Call</option>
                                        <option value="WHATS_APP">Whats app</option>
                                        <option value="TEXT_MESSAGE">Text Message</option>

                                    </select>
                                    <small
                                            *ngIf="submitted && followUpForm.get('type').hasError('required')"
                                            class="text-danger"
                                    >
                                        Field is required.
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label for="remark">Remarks</label>
                                    <input id="remark"
                                           class="form-control"
                                           type="text"
                                           formControlName="remark"
                                           placeholder="Example"
                                    />
                                </div>
                            </form>
                        </div>


                        <div class="card height-equal notifications" *ngIf="modalType === 'VIEW_FOLLOW_UP_REMARKS'">
                            <div class="card1-body pt-0" style="height: 450px; overflow-y: scroll;" *ngIf="followUps && followUps.length > 0; else noFollowUpToShow">
                                <ul class="list-unstyled">
                                    <li *ngFor="let followups of followUps; let i = index">
                                        <ul class="list-unstyled">
                                            <li class="d-flex mb-4" *ngFor="let item of followups.lead_details;">
                                                <!-- Activity Dot -->
                                                <div class="activity-dot-primary"></div>

                                                <!-- Content Section in a Box -->
                                                <div class="w-100 ms-3">
                                                    <div class="card1 shadow-lg rounded-lg"
                                                         style="background: #fff; transition: all 0.3s ease; width: 100%; height: auto; max-width: 800px;">
                                                        <div class="card1-body">
                                                            <!-- Subject and Date in a Row -->
                                                            <div class="d-flex justify-content-between align-items-center mb-1">
                                                                <h4 class="text-primary font-weight-bold fs-4">
                                                                    {{ item.subject }}
                                                                </h4>
                                                                <h6 class="text-muted fs-6 text-end">
                                                                    {{ item.date_time }}
                                                                </h6>
                                                            </div>


                                                            <!-- Stage, Reason, Type, Remark in two columns -->
                                                            <div class="row">
                                                                <div class="col-12 col-md-6 mb-2">
                                    <span class="d-block mb-1" style="font-size: 1rem;">
                                        <i class="bi bi-briefcase me-2" style="color: #007bff;"></i>
                                        <strong style="color: #7367f0 !important;">Stage:</strong>
                                        <span style="color: #555;">{{ item?.stage?.name || 'N/A' }}</span>
                                    </span>

                                                                    <span class="d-block mb-1" style="font-size: 1rem;">
                                        <i class="bi bi-file-earmark-text me-2" style="color: #28a745;"></i>
                                        <strong style="color: #7367f0 !important;">Reason:</strong>
                                        <span style="color: #555;">{{ item?.reason?.name || 'N/A' }}</span>
                                    </span>
                                                                </div>

                                                                <div class="col-12 col-md-6 mb-2">
                                    <span class="d-block mb-1" style="font-size: 1rem;">
                                        <i class="bi bi-calendar-check me-2" style="color: #f39c12;"></i>
                                        <strong style="color: #7367f0 !important;">Type:</strong>
                                        <span style="color: #555;">{{ item?.type || 'N/A' }}</span>
                                    </span>

                                                                    <span class="d-block mb-1" style="font-size: 1rem;">
                                        <i class="bi bi-chat-left-text me-2" style="color: #6c757d;"></i>
                                        <strong style="color: #7367f0 !important;">Remark:</strong>
                                        <span style="color: #555;">{{ item.remark || 'N/A' }}</span>
                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Date Section -->

                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>

                            <!-- Fallback for No Data -->
                            <ng-template #noFollowUpToShow>
                                <div class="col-12 text-center mt-4">
                                    <h6 class="text-muted"><i class="bi bi-exclamation-circle"></i> No follow-ups added</h6>
                                </div>
                            </ng-template>


                        </div>

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success btn-raised" type="button"
                                *ngIf="modalType === 'COUNSELLOR_ASSIGN'"
                                (click)="assignCounsellor()">
                            Submit
                        </button>
                        <button class="btn btn-success btn-raised" type="button"
                                *ngIf="modalType === 'FOLLOW_UP'"
                                (click)="followUp()">
                            Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Students</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No.</h6>
                                            </th>
                                            <th class="text-center"
                                                *ngIf="user && (['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Counsellor</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Follow Up </h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Follow Up Remarks</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Verification Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="students && students.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let student of students; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ (pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1 }}
                                                    .</h6>
                                            </td>
                                            <td class="text-center max-width-250px"
                                                *ngIf="user && (['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <span class="badge badge-primary cursor-pointer"
                                                      (click)="viewCounsellorsModal(content, student)">
                                                    {{ student?.assigned?.name }}
                                                </span>
                                            </td>





                                            <td class="text-center max-width-250px"
                                                *ngIf="counsellorsDetails && counsellorsDetails?.counsellor_detail.follow_up_status === 'YES'">
                                                <span class="badge badge-primary cursor-pointer"
                                                      (click)="changeFollowUp(content, student)">
                                                   Add Follow Ups
                                                </span>
                                            </td>
                                            <td class="text-center max-width-250px"
                                                *ngIf="counsellorsDetails && counsellorsDetails?.counsellor_detail.follow_up_status === 'NO'">
                                               YOU HAVE NOT PERMISSION
                                            </td>

                                            <td class="text-center max-width-250px"
                                                *ngIf="user && (['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <span class="badge badge-primary cursor-pointer"
                                                      (click)="changeFollowUp(content, student)">
                                                   Add Follow Ups
                                                </span>
                                            </td>

                                            <td class="text-center">
                                                <h6 class="mt-1 cursor-pointer text-info"
                                                    *ngIf="student "
                                                    (click)="viewFollowUpRemarks(content, student)">View</h6>

                                            </td>
                                            <td class="text-center min-width-250px max-width-250px">
                                                <h6 class="mt-1">{{ student?.name }}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ student?.contact }}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ student?.course?.course }}</h6>
                                            </td>
                                            <td class="text-center min-width-250px">
                                                <h6 class="mt-1">{{ student?.created_at | date }}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ student?.username }}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ student?.email }}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="student && student['verification_status'] === 'VERIFIED'">
                                                    <span class="badge badge-success">Verified</span>
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="student && student['verification_status'] === 'NOT_VERIFIED'">
                                                    <span class="badge badge-danger">Not Verified</span>
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{ student?.status }}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="13" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="students && students.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let student of students; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-10">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{ (pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1 }}
                                        . {{ student?.name }}</h4>
                                </div>
                                <div class="col-2">
                                    <i class="fas fa-trash f_18 text-danger cursor-pointer"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - <h6 class="mt-1 cursor-pointer">{{ student?.name }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Username - <h6 class="mt-1 cursor-pointer">{{ student?.username }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Eamil - <h6 class="mt-1 cursor-pointer">{{ student?.email }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - <h6 class="mt-1 cursor-pointer">{{ student?.contact }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Course - <h6 class="mt-1 cursor-pointer">{{ student?.course?.course }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Verification Status -
                                    <h6 class="mt-1" *ngIf="student && student['verification_status'] === 'VERIFIED'">
                                        <i class="fas fa-check-circle text-success f_18"></i>
                                    </h6>
                                    <h6 class="mt-1"
                                        *ngIf="student && student['verification_status'] === 'NOT_VERIFIED'">
                                        <i class="fas fa-times-circle text-danger f_18"></i>
                                    </h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - <h6 class="mt-1">{{ student?.status }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Assigned To - <h6 class="mt-1">{{ student?.assigned?.name }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Follow Up - <h6 class="mt-1">{{ student?.follow_up }}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remark - <h6 class="mt-1">{{ student?.follow_up_remarks }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
