import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainBannerSliderComponent} from './main-banner-slider.component';

const routes: Routes = [
    {
        path: 'main-banner-slider',
        component: MainBannerSliderComponent,
        data: {animation: 'main-banner-slider'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class MainBannerSliderRoutingModule {
}
